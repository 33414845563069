interface Props {
  className?: string;
}

function HeartCircleIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Icons:_Heart_Circle"
      data-name="Icons: Heart_Circle"
      viewBox="0 0 250 250"
    >
      <path d="m214.03,125.06c-.39,48.87-38.89,88.97-89.17,88.88-50.92-.1-88.99-40.86-88.89-89.08.1-48.66,38.66-88.83,89.02-88.8,50.77.03,88.77,40.63,89.04,89Zm-89.16-22.81c-1.88-1.9-3.58-3.81-5.49-5.48-2.15-1.87-4.3-3.88-6.79-5.18-10.42-5.42-21.09-3.98-29.93,3.75-8.98,7.85-11.3,22.38-5.04,32.52,1.57,2.55,3.45,4.98,5.54,7.12,11.15,11.42,22.39,22.76,33.67,34.06,1.35,1.35,2.99,2.66,4.74,3.33,4.73,1.8,8.69.06,12.06-3.38,10.8-11.02,21.61-22.04,32.39-33.08,1.59-1.63,3.12-3.33,4.49-5.15,3.58-4.77,5.53-10.21,5.32-16.15-.39-11.08-5.48-19.43-15.75-23.94-9.68-4.25-18.88-2.81-27.07,3.92-2.81,2.31-5.32,4.99-8.13,7.66Z" />
    </svg>
  );
}

export default HeartCircleIcon;
