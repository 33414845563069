import { MouseEvent } from "react";
import { Button } from "styledComponents";
import styled, { css } from "styled-components";

interface Items {
  label: string;
  handleOnClick: () => void;
}

interface Props {
  className?: string;
  behaviour?: "dropdown" | "popup";
  label: string;
  show: boolean;
  items: Items[];
  toggleMenu: (e: MouseEvent) => void;
}

function ActionMenu({
  className,
  behaviour = "dropdown",
  label,
  show,
  items,
  toggleMenu,
}: Props) {
  return (
    <Container className={className}>
      <StyledButton onClick={toggleMenu} show={show}>
        <span>{label}</span>
        {
          <i
            className={`fa-solid fa-chevron-${
              behaviour === "dropdown" ? "down" : "up"
            }`}
          />
        }
      </StyledButton>
      <Menu show={show} behaviour={behaviour}>
        {items.map((item, i) => (
          <MenuItem
            key={i}
            show={show}
            onClick={() => item.handleOnClick()}
            behaviour={behaviour}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
}

export default ActionMenu;

const Container = styled.div`
  position: relative;
  z-index: 40;
`;

interface CollapsibleProps {
  show?: boolean;
  behaviour?: "dropdown" | "popup";
}

const StyledButton = styled(Button)<CollapsibleProps>`
  color: var(--color-black);
  background-color: white;
  width: 120px;
  height: 40px;
  padding: 0;
  border-radius: ${({ show }) => (show ? "0 0 8px 8px" : "8px")};

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 180px;
    height: 60px;
    border-radius: ${({ show }) => (show ? "0 0 12px 12px" : "12px")};
  }

  & > i {
    transform: ${({ show }) => (show ? "rotate(-180deg)" : "rotate(0deg)")};
    transition: transform 0.2s ease-out;
  }

  &:hover {
    transform: none;
  }

  & > span {
    margin-right: 8px;

    @media (min-width: 1400px) and (min-height: 800px) {
      margin-right: 12px;
    }
  }
`;

const Menu = styled.div<CollapsibleProps>`
  position: absolute;
  top: ${({ behaviour }) => (behaviour === "dropdown" ? "100%" : "auto")};
  bottom: ${({ behaviour }) => (behaviour === "dropdown" ? "auto" : "100%")};
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: ${({ behaviour }) =>
    behaviour === "dropdown" ? "0 0 8px 8px" : "8px 8px 0 0"};
  box-shadow: ${({ show }) =>
    show ? "0 0 4px 3px rgba(0, 0, 0, 0.15)" : "none"};
  height: ${({ show }) => (show ? "68px" : "0")};
  overflow: ${({ show }) => (show ? "unset" : "hidden")};

  @media (min-width: 1400px) and (min-height: 800px) {
    box-shadow: ${({ show }) =>
      show ? "0 0 6px 4.5px rgba(0, 0, 0, 0.15)" : "none"};
    height: ${({ show }) => (show ? "94px" : "0")};
  }
`;

const MenuItem = styled.div<CollapsibleProps>`
  color: ${({ show }) => (show ? "var(--color-black)" : "transparent")};
  padding: 8px;
  text-align: center;
  cursor: pointer;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px;
  }

  ${({ behaviour }) =>
    behaviour === "dropdown"
      ? css`
          &:last-of-type {
            border-radius: 0 0 8px 8px;
          }
        `
      : css`
          &:first-of-type {
            border-radius: 8px 8px 0 0;
          }
        `}

  &:hover {
    background-color: var(--color-light-grey);
  }
`;
