import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGenerateDonationPdfMutation,
  useSaveDonationMutation,
} from "store/api";
import { reset } from "store/productsForDonation";
import styled from "styled-components";
import { Button } from "styledComponents";
import { DetachedDonation, DetachedDonationProduct } from "types";
import Delete from "./common/Delete";
import { useEffect } from "react";
import NavigateBackHeader from "./common/NavigateBackHeader";

interface FormData {
  address: string;
  note: string;
  contactDetails: string;
  products: DetachedDonationProduct[];
}

function DonationForm() {
  const [generateDonationPdf, { isLoading: isGeneratingDonationPdf }] =
    useGenerateDonationPdfMutation();
  const [saveDonation, { isLoading: isSavingDonation }] =
    useSaveDonationMutation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: "products",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    prepend({
      product: "",
      articleNumber: "",
      package: "",
      bestBeforeDate: new Date(),
      condition: "",
      ParcelQuantity: 0,
      parcelType: "",
      volume: 0,
    });
  }, []);

  async function onSubmit(data: FormData) {
    const donation: DetachedDonation = {
      note: data.note,
      address: data.address,
      contactDetails: data.contactDetails,
      products: data.products,
      donationType: "detachedDonation",
    };

    try {
      Promise.all([
        await generateDonationPdf(donation).unwrap(),
        await saveDonation(donation).unwrap(),
      ]);
      dispatch(reset());
      navigate("/donation/new?status=done");
    } catch (error: any) {
      toast.error(error.data);
    }
  }

  const handleRemove = (i: number) => {
    if (fields.length > 1) {
      remove(i);
    }
  };

  function onSubmitError() {
    toast.error("Fyll i alla fält för att gå vidare!");
  }

  return (
    <div style={{ width: "100%" }}>
      <NavigateBackHeader title="Donera" />
      <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <Field
          text="Ange var godset befinner sig (adress, postnr och ort)"
          error={!!errors.address}
        >
          <CommonInput {...register("address", { required: true })} />
        </Field>
        <Field text="Kontaktuppgifter (vid frågor samt för att synka donationen)">
          <CommonInput {...register("contactDetails")} />
        </Field>
        <Field fieldType="note" text="Övriga upplysningar">
          <CommonInput inputType="note" {...register("note")} />
        </Field>
        <Table>
          <thead>
            <TableHeader>
              <th>Produkt</th>
              <th>Artikel-nr</th>
              <th>Förpackning</th>
              <th>Bäst-före datum</th>
              <th>Temp</th>
              <th>Kolliantal</th>
              <th>Kollislag</th>
              <th>Vikt (kg)</th>
              <th></th>
            </TableHeader>
          </thead>
          <tbody>
            {fields.map((item, i) => (
              <TableRow key={item.id}>
                <td>
                  <FormInput
                    {...register(`products.${i}.product`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <FormInput
                    {...register(`products.${i}.articleNumber`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <FormInput
                    {...register(`products.${i}.package`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <FormInput
                    error={!!errors.products?.[i]?.bestBeforeDate}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    {...register(`products.${i}.bestBeforeDate`, {
                      required: true,
                    })}
                    onKeyDown={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <FormSelect
                    defaultValue=""
                    {...register(`products.${i}.condition`, { required: true })}
                  >
                    <option value="" disabled>
                      Välj...
                    </option>
                    <option value="Kylt">Kylt</option>
                    <option value="Fryst">Fryst</option>
                    <option value="Torrt">Torrt</option>
                  </FormSelect>
                </td>

                <td>
                  <FormInput
                    {...register(`products.${i}.ParcelQuantity`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <FormSelect
                    defaultValue=""
                    {...register(`products.${i}.parcelType`, {
                      required: true,
                    })}
                  >
                    <option value="" disabled>
                      Välj...
                    </option>
                    <option value="Pall">Pall</option>
                    <option value="Högpall">Högpall</option>
                    <option value="Halvpall">Halvpall</option>
                    <option value="Sjöpall">Sjöpall</option>
                    <option value="Rullbur">Rullbur</option>
                    <option value="Kartong">Kartong</option>
                  </FormSelect>
                </td>
                <td>
                  <FormInput
                    {...register(`products.${i}.volume`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  {fields.length > 1 && (
                    <Remove onDelete={() => handleRemove(i)} />
                  )}
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <AddButton
          onClick={(e) => {
            e.preventDefault();
            append({
              product: "",
              articleNumber: "",
              package: "",
              bestBeforeDate: new Date(),
              condition: "",
              ParcelQuantity: 0,
              parcelType: "",
              volume: 0,
            });
          }}
        >
          Lägg till produkt
        </AddButton>
        <StyledButton disabled={isGeneratingDonationPdf || isSavingDonation}>
          Donera <span className="spinner" />
        </StyledButton>
      </Form>
    </div>
  );
}

export default DonationForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

interface InnerInputLabel {
  text?: string;
  fieldType?: string;
  inputType?: string;
}

const StyledButton = styled(Button)`
  margin-top: 24px;
  align-self: end;
`;

const Field = styled.div<InnerInputLabel & ErrorProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: ${({ fieldType }) => (fieldType === "note" ? "160px" : "56px")};
  border: ${({ error }) =>
    error ? "2px solid red" : "2px solid var(--color-dark-green)"};
  border-radius: 8px;
  padding: 20px 16px 8px 4px;
  margin-bottom: 16px;

  ::after {
    position: absolute;
    content: "${({ text }) => text}";
    top: 2px;
    left: 4px;
    color: var(--color-medium-grey);
  }
`;

const CommonInput = styled.textarea<InnerInputLabel>`
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  height: ${({ inputType }) => (inputType === "note" ? "128px" : "24px")};
`;

const Table = styled.table`
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  padding: 16px;

  .name {
    display: flex;
    align-items: center;

    input {
      width: 88px;
      margin-right: 8px;
    }
  }

  .center {
    justify-self: end;
  }
`;

const Remove = styled(Delete)`
  font-size: 18px;
  margin-top: 18px;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 24px;
  }
`;

const AddButton = styled(Button)`
  margin-top: 16px;
  background-color: white;
  border: solid var(--color-medium-grey) 1px;
`;

const Row = styled.tr`
  display: grid;
  grid-template-columns: 4fr 1.5fr 2fr 2fr 1fr 1fr 1fr 1fr 0.5fr;

  width: 100%;
  gap: 16px;
  align-items: center;
  justify-items: start;
  padding: 8px 0;
`;

const TableHeader = styled(Row)`
  padding: 8px 0 0 0;
`;

interface ErrorProps {
  error?: boolean;
}

interface FormControlProps {
  name: string;
  placeholder?: string;
}

const FormSelect = styled.select<ErrorProps & FormControlProps>`
  outline: none;
  border: none;
  height: 32px;
  margin-top: 8px;
  border-radius: 4px;
  width: ${({ name }) => (name.includes("parcelType") ? "80px" : "64px")};
  border-bottom: ${({ error }) =>
    error ? "2px solid red" : "2px solid var(--color-dark-green)"};
  cursor: pointer;

  &:focus {
    border-color: var(--color-light-green);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    border-width: 3px;
    border-radius: 8px;
    height: 48px;
    border-radius: 6px;
    width: ${({ name }) => (name.includes("parcelType") ? "120px" : "96px")};
    border-bottom: ${({ error }) =>
      error ? "3px solid red" : "3px solid var(--color-dark-green)"};
  }
`;

const FormInput = styled.input<ErrorProps & FormControlProps>`
  outline: none;
  border: none;
  padding: 24px 8px 16px;
  height: 32px;
  border-radius: 4px;
  width: ${({ name }) =>
    name.includes(".product")
      ? "256px"
      : name.includes("bestBeforeDate")
      ? "128px"
      : name.includes("articleNumber")
      ? "88px"
      : name.includes("parcelType")
      ? "80px"
      : name.includes("condition")
      ? "64px"
      : name.includes("package")
      ? "120px"
      : "64px"};
  border-bottom: ${({ error }) =>
    error ? "2px solid red" : "2px solid var(--color-dark-green)"};

  &:focus {
    border-color: var(--color-light-green);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 32px 12px 24px;
    height: 48px;
    border-radius: 6px;
    width: ${({ name }) =>
      name.includes(".product")
        ? "320px"
        : name.includes("bestBeforeDate")
        ? "192px"
        : name.includes("articleNumber")
        ? "96px"
        : name.includes("parcelType")
        ? "120px"
        : name.includes("condition")
        ? "96px"
        : name.includes("package")
        ? "176px"
        : "96px"};
    border-bottom: ${({ error }) =>
      error ? "3px solid red" : "3px solid var(--color-dark-green)"};
  }
`;

const TableRow = styled(Row)`
  border-radius: 4px;
`;
