import { Outlet } from "react-router-dom";
import styled from "styled-components";

function PageLayout() {
  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
}

export default PageLayout;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5vw;
`;
