import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "styledComponents";

function ReportConfirmPage() {
  const navigate = useNavigate();

  return (
    <ConfirmPageContainer>
      <Circle className="fa-solid fa-circle-check" />
      <Heading>Klart!</Heading>
      <Text>
        En förfrågan om utökad rapport innehållande kvalitetspåverkan och
        bakteriehalt har bekräftats.
      </Text>
      <Text> SFW återkommer så fort som möjligt!</Text>
      <ToStartPageButton onClick={() => navigate("/products")}>
        Tillbaka till startsida
      </ToStartPageButton>
    </ConfirmPageContainer>
  );
}

export default ReportConfirmPage;

const ConfirmPageContainer = styled.div`
  display: grid;
  gap: 8px;
  margin: 80px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 168px;
    gap: 16px;
  }
`;

const Heading = styled.h1`
  color: var(--color-dark-green);
  text-align: center;
  margin: 0;
`;

const Text = styled.h2`
  color: var(--color-dark-green);
  font-weight: normal;
  text-align: center;
  margin: 0;
`;

const Circle = styled.i`
  font-size: 168px;
  margin-bottom: 8px;
  justify-self: center;
  color: var(--color-dark-green);
`;

const ToStartPageButton = styled(Button)`
  justify-self: center;
  width: 12vw;
  margin-top: 24px;
`;
