interface Props {
  className?: string;
}

function AnalyticsIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Analytics">
        <path d="m10.56,10.56c0-1.98-1.6-3.57-3.57-3.57s-3.57,1.6-3.57,3.57v37.51c0,4.93,4,8.93,8.93,8.93h44.66c1.98,0,3.57-1.6,3.57-3.57s-1.6-3.57-3.57-3.57H12.35c-.98,0-1.79-.8-1.79-1.79V10.56Zm45.39,9.67c1.4-1.4,1.4-3.66,0-5.06-1.4-1.4-3.66-1.4-5.06,0l-11.76,11.77-6.41-6.41c-1.4-1.4-3.66-1.4-5.06,0l-12.5,12.5c-1.4,1.4-1.4,3.66,0,5.06s3.66,1.4,5.06,0l9.98-9.97,6.41,6.41c1.4,1.4,3.66,1.4,5.06,0l14.29-14.29h-.01Z" />
      </g>
    </svg>
  );
}

export default AnalyticsIcon;
