import styled from "styled-components";
import { Notification as NotificationType } from "types";
import { formatDate } from "utils";

interface Props {
  notification: NotificationType;
}

function Notification({ notification }: Props) {
  return (
    <Container key={notification.id}>
      <Title>{notification.title}</Title>
      <Message>{notification.message}</Message>
      <Date>{formatDate(notification.createdDate)}</Date>
    </Container>
  );
}

export default Notification;

const Container = styled.div`
  display: grid;
  gap: 8px 24px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
`;

const Title = styled.h2`
  margin: 0 0 4px;
`;

const Message = styled.div`
  grid-column: 1;
`;

const Date = styled.time`
  grid-row: 1;
  grid-column: 2;
  justify-self: end;
  color: var(--color-medium-grey);
`;
