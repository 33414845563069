interface Props {
  className?: string;
}

function MagnifyingMinusIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Icons:_Magnifying_Minus"
      data-name="Icons: Magnifying_Minus"
      viewBox="0 0 250 250"
    >
      <path d="m166.88,154.82c.55.42,1.13.78,1.6,1.25,15.63,15.64,31.26,31.29,46.88,46.95,2.11,2.12,3.67,4.57,3.27,7.72-.48,3.8-2.69,6.34-6.26,7.53-3.53,1.18-6.5-.22-8.98-2.65-5.46-5.35-10.84-10.8-16.26-16.2-10.35-10.34-20.71-20.68-31.06-31.02-.43-.43-.82-.91-1.27-1.4-20.37,15.61-42.95,19.92-67.53,13.66-17.9-4.56-32.04-14.95-42.46-29.97-21.68-31.27-16.89-74.2,11.66-100,27.45-24.81,70.61-26.13,99.36-2.04,15.83,13.27,25.02,30.19,27.12,50.78,2.09,20.45-3.05,38.92-16.08,55.39Zm-59.53,11.22c32.49-.2,58.59-25.64,58.67-58.66.07-32.32-25.37-58.63-58.55-58.73-32.41-.1-58.75,25.49-58.75,58.7,0,32.78,25.88,58.46,58.63,58.7Z" />
      <path d="m107.36,116.08c-8.43,0-16.86.04-25.28-.02-4.09-.02-7.39-1.5-9.08-5.55-2.1-5.01,1.16-10.88,6.54-11.69,1.15-.17,2.33-.27,3.49-.27,16.41-.02,32.82-.04,49.23.03,1.74,0,3.58.36,5.18,1.01,3.43,1.39,5.42,5.26,4.89,8.85-.58,3.86-3.44,6.82-7.19,7.36-1.15.17-2.33.25-3.49.26-8.09.02-16.19.01-24.28.01Z" />
    </svg>
  );
}

export default MagnifyingMinusIcon;
