interface Props {
  className?: string;
}

function HomeIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Home">
        <path d="m8.55,30.89c-.91.75-1.67,1.4-2.45,2.02-1.05.83-2.23.94-3.1.35-1.41-.95-1.55-2.86-.26-4.03,1.07-.97,2.2-1.87,3.3-2.81,7.95-6.72,15.91-13.44,23.86-20.17.82-.7,1.65-1.36,2.82-.94.45.16.88.43,1.25.74,8.1,6.85,16.18,13.7,24.27,20.56,1.01.86,2.05,1.69,3.03,2.59,1.47,1.34,1.1,3.57-.68,4.29-.69.28-1.35.17-1.93-.21-.81-.54-1.57-1.16-2.35-1.75-.23-.18-.46-.36-.82-.64v.89c0,6.06,0,12.12,0,18.18,0,2.21-.51,4.24-1.99,5.96-1.37,1.6-3.11,2.5-5.17,2.76-.87.11-1.76.14-2.65.14-9.44,0-18.88.02-28.32,0-2.04,0-3.96-.46-5.62-1.76-2.3-1.8-3.17-4.23-3.17-7.06,0-6.06,0-12.12,0-18.18,0-.25,0-.5,0-.93Zm23.47-20c-.16.13-.3.22-.42.33-5.85,4.99-11.7,9.98-17.56,14.96-.4.34-.55.67-.55,1.18.02,7.04.01,14.07.01,21.11,0,.61-.02,1.21,0,1.82.09,1.99,1.12,3.35,2.85,3.47,1.83.13,3.67.03,5.61.03,0-.31,0-.61,0-.9,0-4.82,0-9.65,0-14.47,0-.53,0-1.08.14-1.59.52-1.93,2.13-3.12,4.21-3.13,3.66-.01,7.32.09,10.98-.04,2.66-.09,4.85,1.9,4.77,4.79-.13,4.82-.03,9.65-.03,14.47v.91c1.62,0,3.11,0,4.6,0,2.61,0,3.87-1.24,3.87-3.85,0-7.57,0-15.15-.02-22.72,0-.34-.2-.77-.45-.99-4.29-3.69-8.6-7.36-12.91-11.04-1.7-1.45-3.4-2.89-5.11-4.34Zm-5.03,27.84v15.06h10.05v-15.06h-10.05Z" />
      </g>
    </svg>
  );
}

export default HomeIcon;
