import styled from "styled-components";
import { toast } from "react-toastify";
import Icon from "./Icon";

interface Props {
  searchQuery: string;
  onSearch: (value: string) => void;
  className?: string;
}

function Searchbar({ onSearch, searchQuery, className }: Props) {
  return (
    <StyledSearchBar className={className}>
      <SearchIcon name="Search" />
      <SearchInput
        placeholder="Sök produkt, kategori eller filtrera till vänster"
        onChange={(e) => onSearch(e.target.value)}
        value={searchQuery}
        onFocus={() =>
          toast.info(
            "Filtrera mellan både kylt och fryst för att se din produkt"
          )
        }
      />
      {searchQuery && <ClearIcon name="XMark" onClick={() => onSearch("")} />}
    </StyledSearchBar>
  );
}

export default Searchbar;

const StyledSearchBar = styled.div`
  flex-grow: 1;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  padding-left: 48px;
  font-size: 14px;
  height: 40px;
  border-radius: 0 8px 8px 0;
  border: none;
  border-left: 2px solid var(--color-dark-green);
  outline: none;
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  left: 16px;
  width: 20px;
  height: 20px;
`;

const ClearIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 14px;
  width: 14px;
  height: 14px;
  color: var(--color-black);
`;
