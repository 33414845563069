interface Props {
  className?: string;
}

function LeafIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Icons:_Leaf"
      data-name="Icons: Leaf"
      viewBox="0 0 250 250"
    >
      <path d="m147.44,116.32v-11.3h-1.96c-10.89,0-21.78,0-32.67,0-13.7,0-26.81,2.64-39.2,8.59-.43.21-.88.37-1.67.7.73-1.95,1.28-3.65,2-5.28,7.9-17.71,21.19-29.36,39.67-35.09,5.85-1.81,11.91-2.42,18.01-2.45,11.1-.05,22.21-.06,33.31,0,7.1.04,13.89-1.23,20.26-4.47,6.75-3.43,12.04-8.42,15.77-14.99,1.39-2.44,2.44-5.07,3.75-7.83,1.02,2.53,2.07,4.93,2.95,7.39,4.71,13.25,7,26.97,6.97,40.97-.04,18.64-3.89,36.5-12.39,53.26-4.89,9.65-10.83,18.55-18.56,26.11-11.97,11.7-25.97,19.55-42.82,21.93-13.23,1.87-25.88.22-37.84-5.7-9.09-4.5-16.54-11.06-22.49-19.31-3.84-5.33-6.56-11.21-8.58-17.44-.26-.8-.53-1.59-.79-2.39-1.57,1.5-3.17,2.83-4.54,4.37-9.11,10.26-13.62,22.4-14.31,35.99-.24,4.79-.17,9.6-.24,14.4,0,.63,0,1.27,0,2.02h-16.67c0-4.77-.09-9.5.02-14.23.23-9.56,2-18.84,5.39-27.81,3.28-8.66,8.27-16.25,14.48-23.02,12.25-13.35,27.68-20.75,45.4-23.57,3.72-.59,7.54-.77,11.32-.8,11.1-.1,22.21-.04,33.31-.04h2.1Z" />
    </svg>
  );
}

export default LeafIcon;
