import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { useTranslation } from "translate";
import { CategoryCount, ChartData } from "types";

interface Props {
  categories: CategoryCount;
}

function CategoryChart({ categories }: Props) {
  const t = useTranslation();

  const categoryData = Object.entries(categories).reduce(
    (data, [, category]) => {
      data.labels.push(t(category.name));
      data.datasets[0].data.push(category.count);
      return data;
    },
    {
      labels: [],
      datasets: [
        {
          label: "Antal mätningar per kategori",
          backgroundColor: "#2e6053",
          data: [],
        },
      ],
    } as ChartData
  );

  return (
    <Container>
      <Bar
        data={categoryData}
        options={{
          indexAxis: "y",
          scales: {
            y: {
              grid: { display: false },
              ticks: { autoSkip: false },
            },
            x: {
              beginAtZero: true,
              grid: { display: false },
              ticks: {
                autoSkip: false,
                maxTicksLimit: 10,
              },
            },
          },
        }}
      />
    </Container>
  );
}

export default CategoryChart;

const Container = styled.div`
  @media (min-width: 1400px) and (min-height: 800px) {
    width: 840px;
    height: 420px;
  }
`;
