import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "styledComponents";

function DonationConfirmPage() {
  const navigate = useNavigate();

  return (
    <ConfirmPageContainer>
      <Circle className="fa-solid fa-circle-check" />
      <Heading>Din donationsförfrågan har registrerats!</Heading>
      <Text>
        Stadsmissionen återkommer. <br />
        Tack för att du bidrar till re-distribution av livsmedel.
      </Text>
      <ToStartPageButton onClick={() => navigate("/products")}>
        Tillbaka till startsida
      </ToStartPageButton>
    </ConfirmPageContainer>
  );
}

export default DonationConfirmPage;

const ConfirmPageContainer = styled.div`
  display: grid;
  gap: 24px;
  margin: 88px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 168px;
  }
`;

const Heading = styled.h1`
  color: var(--color-dark-green);
  grid-row: 2;
  margin: 0;
`;

const Text = styled.h2`
  color: var(--color-dark-green);
  font-weight: normal;
  text-align: center;
  margin: 0;
`;

const Circle = styled.i`
  justify-self: center;
  font-size: 168px;
  color: var(--color-dark-green);
`;

const ToStartPageButton = styled(Button)`
  justify-self: center;
  width: 12vw;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 24px;
  }
`;
