import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Credentials,
  MeasurementPayload,
  Product,
  Selectable,
  Transport,
  Notifications,
  Donation,
  Report,
  Feedback,
  LoginInformation,
  DetachedDonation,
  DonationProductRow,
  Category,
  DbDonation,
} from "types";
import { getJwt } from "services/auth";

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "production"
        ? `${window.location.origin}/api`
        : process.env.REACT_APP_API_BASE_URL,
    prepareHeaders(headers) {
      const token = getJwt();
      if (token) headers.set("x-auth-token", token);
      return headers;
    },
  }),
  tagTypes: ["Transports", "Notifications", "Donations"],
  endpoints: (builder) => ({
    getProducts: builder.query<Selectable<Product>[], void>({
      query: () => "/products",
    }),
    getCategory: builder.query<Category[], void>({
      query: () => "/categories",
    }),
    saveMeasurement: builder.mutation<Transport, MeasurementPayload>({
      query: (measurement) => ({
        url: `/measurements`,
        method: "POST",
        body: measurement,
      }),
      invalidatesTags: [{ type: "Transports", id: "LIST" }],
    }),
    getDonations: builder.query<DbDonation[], void>({
      query: () => "/donations",
      providesTags: [{ type: "Donations", id: "LIST" }],
    }),
    getDonation: builder.query<
      { name: string; products: DonationProductRow[] },
      string
    >({
      query: (id) => "/donations/" + id,
    }),
    saveDonation: builder.mutation<
      Donation | DetachedDonation,
      Donation | DetachedDonation
    >({
      query: (donation) => ({
        url: `/donations`,
        method: "POST",
        body: donation,
      }),
      invalidatesTags: [{ type: "Donations", id: "LIST" }],
    }),
    getNotification: builder.query<Notifications, void>({
      query: () => "/notifications",
      providesTags: [{ type: "Notifications", id: "LIST" }],
    }),
    readNotifications: builder.mutation<Notification, void>({
      query: () => ({
        url: "/notifications",
        method: "PATCH",
      }),
      invalidatesTags: [{ type: "Notifications", id: "LIST" }],
    }),
    updateLoginInformation: builder.mutation<null, LoginInformation>({
      query: (loginInformation) => ({
        url: "/loginInformation",
        method: "PATCH",
        body: loginInformation,
      }),
    }),
    getTransports: builder.query<Transport[], void>({
      query: () => "/transports",
      providesTags: [{ type: "Transports", id: "LIST" }],
    }),
    getTransport: builder.query<Transport, string>({
      query: (id) => "/transports/" + id,
      providesTags: (transport) => [
        { type: "Transports", id: transport?.id || "LIST" },
      ],
    }),
    updateTransport: builder.mutation<Transport, Transport>({
      query: (transport) => ({
        url: "/transports/" + transport.id,
        method: "PATCH",
        body: transport,
      }),
      invalidatesTags: (transport) => [
        { type: "Transports", id: "LIST" },
        { type: "Transports", id: transport?.id || "LIST" },
      ],
    }),
    login: builder.mutation<string, Credentials>({
      query: ({ username, password }) => ({
        url: "/auth",
        method: "POST",
        body: { username, password },
      }),
    }),
    generateDonationPdf: builder.mutation<null, Donation | DetachedDonation>({
      query: (donation) => ({
        url: `/pdf/donation`,
        method: "POST",
        body: donation,
      }),
    }),
    generateReportPdf: builder.mutation<null, Report>({
      query: (report) => ({
        url: `/pdf/report`,
        method: "POST",
        body: report,
      }),
    }),
    sendFeedback: builder.mutation<null, Feedback>({
      query: (feedback) => ({
        url: `/sendMail/feedback`,
        method: "POST",
        body: feedback,
      }),
    }),
    saveLoginInformation: builder.mutation<null, LoginInformation>({
      query: (loginInformation) => ({
        url: `/loginInformation`,
        method: "POST",
        body: loginInformation,
      }),
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useGetProductsQuery,
  useSaveMeasurementMutation,
  useSaveDonationMutation,
  useGetNotificationQuery,
  useReadNotificationsMutation,
  useGetTransportQuery,
  useGetTransportsQuery,
  useUpdateTransportMutation,
  useLoginMutation,
  useGenerateDonationPdfMutation,
  useGenerateReportPdfMutation,
  useSendFeedbackMutation,
  useSaveLoginInformationMutation,
  useUpdateLoginInformationMutation,
  useGetDonationsQuery,
  useGetDonationQuery,
} = api;

export default api;
