import { NavigateBackHeader } from "components";
import { useParams } from "react-router-dom";
import { useGetDonationQuery } from "store/api";
import styled from "styled-components";
import { Level } from "types";
import { formatDate } from "utils";

function DonationTable() {
  const { donationId } = useParams();
  const { data: donation } = useGetDonationQuery(donationId!);

  const levelMap = {
    [Level.TOP]: "Ö",
    [Level.MIDDLE]: "M",
    [Level.BOTTOM]: "N",
    [Level.PACKAGE]: "N",
  };

  return (
    <>
      <NavigateBackHeader title={donation?.name} />
      <Table>
        <thead>
          <TableHeader>
            <th>Produkt</th>
            <th>Artikel-nr</th>
            <th>Förpackning</th>
            <th>Bäst-före datum</th>
            <th>Temp</th>
            <th>Kolliantal</th>
            <th>Kollislag</th>
            <th>Vikt (kg)</th>
          </TableHeader>
        </thead>
        <tbody>
          {donation?.products.map((dp) => (
            <TableRow key={dp.id}>
              <td>{dp.productName}</td>
              <td>{dp.articleNumber}</td>
              <td>{dp.packaging}</td>
              <td>
                {dp.parcelType === "PALLET"
                  ? dp.bestBeforeDate.map((bfd) => (
                      <div key={bfd.level}>
                        {levelMap[bfd.level]}: {formatDate(new Date(bfd.date))}
                      </div>
                    ))
                  : formatDate(new Date(dp.bestBeforeDate[0].date))}
              </td>
              <td>{dp.condition}</td>
              <td>{dp.parcelQuantity}</td>
              <td>
                {dp.parcelType === "PALLET"
                  ? "Pall"
                  : dp.parcelType === "PACKAGE"
                  ? "Kartong"
                  : dp.parcelType}
              </td>
              <td>{dp.volume}</td>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default DonationTable;

const Header = styled.h1`
  text-align: center;
`;

const Table = styled.table`
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  padding: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  .name {
    display: flex;
    align-items: center;

    input {
      width: 88px;
      margin-right: 8px;

      @media (min-width: 1400px) and (min-height: 800px) {
        width: 132px;
        margin-right: 12px;
      }
    }
  }

  .center {
    justify-self: end;
  }
`;

const Row = styled.tr`
  display: grid;
  grid-template-columns: 25fr 8fr 14fr 15fr 8fr 7fr 9fr 8fr 3fr;
  gap: 16px;
  align-items: center;
  justify-items: start;
  padding: 8px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 72px;
    padding: 24px 0;
  }
`;

const TableHeader = styled(Row)`
  padding: 16px 0 8px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 0 0 0;
  }
`;

const TableRow = styled(Row)`
  border-radius: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 6px;
  }
`;
