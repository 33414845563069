interface Props {
  className?: string;
}

function NotificationsIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Bell">
        <path d="m35.76,5.68v2.1c8.52,1.35,15.04,8.73,15.04,17.64v3.92c0,5.33,1.82,10.51,5.15,14.69l1.75,2.19c.68.85.81,2.01.34,2.98-.47.98-1.46,1.6-2.54,1.6H8.5c-1.08,0-2.07-.62-2.54-1.6-.47-.98-.34-2.14.34-2.98l1.75-2.19c3.33-4.17,5.15-9.35,5.15-14.69v-3.92c0-8.91,6.52-16.28,15.04-17.64v-2.1c0-2.08,1.68-3.76,3.76-3.76s3.76,1.68,3.76,3.76h0Zm-4.7,7.52c-6.74,0-12.22,5.47-12.22,12.22v3.92c0,5.63-1.63,11.11-4.66,15.81h35.64c-3.03-4.7-4.66-10.19-4.66-15.81v-3.92c0-6.74-5.47-12.22-12.22-12.22h-1.88Zm8.46,41.35c0,1.89-.79,3.91-2.2,5.32s-3.43,2.2-5.32,2.2c-2,0-3.91-.79-5.32-2.2s-2.2-3.43-2.2-5.32h15.04Z" />
      </g>
    </svg>
  );
}

export default NotificationsIcon;
