import { Outlet } from "react-router-dom";
import { Stepbar } from "components";

function StepbarLayout() {
  return (
    <>
      <Stepbar />
      <Outlet />
    </>
  );
}

export default StepbarLayout;
