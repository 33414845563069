import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { getCurrentUser } from "services/auth";
import { SidebarMenu } from "components";

function AppLayout() {
  const [showSidebar, setShowSidebar] = useState(false);
  const isSignedIn = getCurrentUser();

  if (!isSignedIn) return <Navigate to="/login" />;

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <>
      <HamburgerContainer onClick={toggleSidebar}>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
      </HamburgerContainer>
      <SidebarMenu show={showSidebar} toggle={toggleSidebar} />
      <Container showSidebar={showSidebar}>
        <Outlet />
      </Container>
    </>
  );
}

export default AppLayout;

interface ContainerProps {
  showSidebar: boolean;
}

const Container = styled.div<ContainerProps>`
  padding-left: ${({ showSidebar }) => (showSidebar ? "160px" : "0px")};
  transition: padding-left, 0.5s ease-in-out;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding-left: ${({ showSidebar }) => (showSidebar ? "336px" : "0px")};
  }
`;

const HamburgerContainer = styled.div`
  display: grid;
  gap: 4px;
  width: 24px;
  height: 16px;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

const HamburgerLine = styled.div`
  width: 30px;
  height: 5px;
  border-radius: 4px;
  background-color: var(--color-dark-green);
`;
