import { MouseEvent } from "react";
import styled from "styled-components";

interface Props {
  isFavorite: boolean;
  onFavor: (e: MouseEvent) => void;
}

function Favorite({ isFavorite, onFavor }: Props) {
  return (
    <FavoriteIcon
      onClick={onFavor}
      className={`fa-star fa-${isFavorite ? "solid" : "regular"}`}
    />
  );
}

export default Favorite;

const FavoriteIcon = styled.i`
  cursor: pointer;
  color: var(--color-dark-green);

  &:hover {
    transform: scale(1.15);
  }
`;
