import styled from "styled-components";
import { Transport } from "types";
import ProductResultCard from "./PackageResultCard";

interface Props {
  transport: Transport;
}

function PackageResultList({ transport }: Props) {
  return (
    <List>
      <Headers>
        <div style={{ justifySelf: "start" }}>Produkt</div>
        <div>Nytt BFD</div>
        <div>BFD minskning</div>
        <div>Du räddar</div>
        <div>kg CO2e</div>
        <div></div>
      </Headers>

      {transport.measurementResult.map((mr) => (
        <ProductResultCard
          key={mr.id}
          transport={transport}
          measurementResult={mr}
        />
      ))}
    </List>
  );
}

export default PackageResultList;

const List = styled.div`
  margin-bottom: 104px;
`;

const Headers = styled.div`
  display: grid;
  grid-template-columns: 2.49fr repeat(4, 1.64fr) 0.94fr;
  justify-items: center;
  padding: 8px;
  gap: 16px;
`;
