import styled from "styled-components";
import { Product, Selectable } from "types";
import Icon from "./Icon";

interface Props {
  product: Selectable<Product>;
  isSelected: boolean;
  hasError?: boolean;
  onAddProduct(product: Product): void;
}

function ProductCard({
  product,
  isSelected,
  onAddProduct,
  hasError,
}: Props): JSX.Element {
  return (
    <Container
      key={product.id}
      onClick={() => (hasError ? undefined : onAddProduct(product))}
      isSelected={isSelected}
      hasError={hasError}
    >
      <TemperatureIcon name={product.isFrozen ? "Frost" : "Droplet"} />
      <Text>
        <CategoryIcon
          isSelected={isSelected}
          hasError={hasError}
          name={product.category.name}
        />
        <StyledProduct>
          <ProductName>{product.name}</ProductName>
          <Packaging>
            Temperatur: {product.isFrozen ? "Fryst" : "Kylt"}
          </Packaging>
          <Packaging>Förpackning: {product.packaging}</Packaging>
        </StyledProduct>
      </Text>
      <AddButton
        isSelected={isSelected}
        hasError={hasError}
        className={`fa-solid fa-circle-${
          hasError ? "xmark" : isSelected ? "check" : "plus"
        }`}
      />
      {hasError && (
        <ErrorText>Tekniskt fel på produkten, kontakta SFW.</ErrorText>
      )}
    </Container>
  );
}

export default ProductCard;

interface SelectedProps {
  hasError?: boolean;
  isSelected?: boolean;
  borderLeft?: boolean;
}

const ErrorText = styled.p`
  margin: 8px 0;
`;

const StyledProduct = styled.div`
  display: grid;
  gap: 4px;
  margin: 8px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-black);
`;

const TemperatureIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  fill: var(--color-blue);
`;

const CategoryIcon = styled(Icon)<SelectedProps>`
  width: 48px;
  height: 48px;
  margin-top: 10px;
  fill: ${({ hasError, isSelected }) =>
    hasError
      ? "var(--color-dark-red)"
      : isSelected
      ? "var(--color-dark-green)"
      : "var(--color-icon-grey)"};
  transition: all 0.3s;
`;

const Container = styled.li<SelectedProps>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  border: ${({ hasError, isSelected }) =>
    `2px solid ${
      hasError
        ? "var(--color-dark-red)"
        : isSelected
        ? "var(--color-dark-green)"
        : "var(--color-light-grey)"
    }`};
  border-radius: 8px;
  list-style: none;
  cursor: ${({ hasError }) => (hasError ? "not-allowed" : "pointer")};

  &:last-of-type {
    margin-bottom: 56px;
  }

  &:active {
    @keyframes flash {
      0% {
        background-color: white;
      }

      30% {
        background-color: rgba(180, 204, 188, 0.5);
      }

      100% {
        background-color: white;
      }
    }

    animation: ${({ hasError }) => (hasError ? "none" : "0.3s ease-out flash")};
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    &:last-of-type {
      margin-bottom: 24px;
    }
  }
`;

const ProductName = styled.p`
  font-weight: bold;
`;

const Packaging = styled.p`
  font-size: 12px;
  color: var(--color-dark-grey);
`;

const AddButton = styled.i<SelectedProps>`
  transition: all 0.3s;
  font-size: 24px;
  color: ${({ hasError, isSelected }) =>
    hasError
      ? "var(--color-dark-red)"
      : isSelected
      ? "var(--color-dark-green)"
      : "var(--color-light-grey)"};
  background-color: transparent;
`;
