import jwtDecode from "jwt-decode";
import { User } from "types";

const tokenKey = "token";

export function login(jwt: string) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getCurrentUser(): User | null {
  try {
    const token = localStorage.getItem(tokenKey);
    if (token) return jwtDecode(token);
  } catch (error) {}

  return null;
}
