import React from "react";

interface Props {
  className?: string;
}

function SfwLogoIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      version="1.1"
      id="sfw_no_text_svg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 159.9 77.7"
      className={className}
    >
      <path
        id="Path_1"
        d="M35.7,17.1c0.1-1.2,1.1-2.1,2.3-2.1c1.7,0,3.1,0.8,3.1,3.4c0,6.2,7.3,10.2,14.5,10.2
	c1.9,0,5.8-0.5,7.7-0.5c2.8,0,5.6,0.2,8.3,0.7c0,0-2.7-23.5-19.1-23.5c-5.8,0-8.7,2.9-8.7,2.9s10.4,2.3,17.5,9.6c1,1,0.1,3.1-2,2
	c-6.4-3.4-17.1-8.1-26.1-8.1c-13.6,0-27.5,10.9-27.5,26.3c0,14.4,11.2,27.8,26.7,27.8c21.7,0,37.5-19.5,46.8-30.3
	C99.9,11.3,113.8,6.7,128,6.7c16,0,31.9,11.5,31.9,31.1c0,21-14.2,33-34.9,33c-2.1,0-9.6,6.9-17.1,6.9C93.2,77.6,83,60.8,83,45.9
	c0-2.6,1.2-4,3.1-3.3c2.6,1,4.2,1.5,10.2,1.5c2.2,0,6-0.6,8.7-0.6c12.2,0,19.9,7.8,19.9,15.9c0,0.9-0.2,3-2.6,3
	c-1.6,0-2.9-1.3-2.8-2.9c0,0,0,0,0-0.1c0-7-7.1-10.2-14.5-10.2c-2.2,0-6.6,0.5-8.9,0.5c-2.4,0-4.8-0.3-7.2-0.9
	c0,0,2.6,23.3,19.1,23.3c3.2,0.1,6.3-0.9,8.9-2.8c0,0-11.8-2.4-17.8-10.1c-0.5-0.6,0-2.4,1.5-1.8c6.2,2.6,17.3,8.3,26.1,8.3
	c15.5,0,28.2-11.6,28.2-28.1c0-15.7-15.1-26.1-26.8-26.1c-13,0-26.8,6.1-44.5,26.9c-9.8,11.5-25.7,32.3-51,32.3
	C15,70.8,0,57.5,0,37.4C0,21.9,12.8,6.4,35.1,6.4c3.3,0,9.6-6.4,17.5-6.4c16.8,0,24.7,18.1,24.7,31c0,3.2-1.2,3.9-3.5,3.3
	c-2.8-0.8-5.5-0.9-9.6-0.9c-2.3,0-6.9,0.4-10.2,0.4C44.8,33.8,35.7,27.8,35.7,17.1z"
      />
    </svg>
  );
}

export default SfwLogoIcon;
