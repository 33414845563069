import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetDonationsQuery } from "store/api";
import { formatDate, formatTime } from "utils";
import { Button } from "styledComponents";
import { ActionMenu, NavigateBackHeader } from "components";
import { useState } from "react";

function DonationLandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { data: donations = [] } = useGetDonationsQuery();

  const menuItems = [
    {
      label: "Avvikelse",
      handleOnClick: () => navigate("/donation/new?stage=measurement-history"),
    },
    {
      label: "Fristående",
      handleOnClick: () => navigate("/donation/new/detachedDonation"),
    },
  ];

  return (
    <Container>
      <Header>
        <div></div>
        <NavigateBackHeader title="Donationer" />
        <StyledActionMenu
          items={menuItems}
          label="Ny donation"
          show={show}
          toggleMenu={() => setShow(!show)}
        />
      </Header>
      <Table>
        <thead>
          <TableHeader>
            <th>Namn</th>
            <th>Datum</th>
            <th className="center">Klockslag</th>
            <th>Typ av donation</th>
          </TableHeader>
        </thead>
        <tbody>
          {donations.map((donation) => (
            <TableRow key={donation.id}>
              <td className="name">
                <TransportName to={`${location.pathname}/${donation.id}`}>
                  {donation.name}
                </TransportName>
              </td>
              <td>{formatDate(donation.createdDate)}</td>
              <td className="center">{formatTime(donation.createdDate)}</td>
              <td>
                {Boolean(donation.isDetached) ? "Fristående" : "Avvikelse"}
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
`;
export default DonationLandingPage;

const StyledActionMenu = styled(ActionMenu)`
  justify-self: end;

  button {
    background-color: var(--color-light-green);
    color: var(--color-dark-green);
    height: 48px;
    width: 144px;
    border-radius: ${({ show }) => (show ? "8px 8px 0 0 " : "8px")};

    @media (min-width: 1400px) and (min-height: 800px) {
      height: 72px;
      width: 216px;
    }
  }

  div {
  }
`;

const Header = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 16px;
`;

const Table = styled.table`
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  padding: 16px 32px;
  width: 100%;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  .name {
    display: flex;
    align-items: center;

    input {
      width: 88px;
      margin-right: 8px;

      @media (min-width: 1400px) and (min-height: 800px) {
        width: 132px;
        margin-right: 12px;
      }
    }
  }

  .center {
    justify-self: center;
  }
`;

const Row = styled.tr`
  display: grid;
  grid-template-columns: 4fr repeat(3, 2fr);
  gap: 120px;
  align-items: center;
  justify-items: start;
  padding: 16px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    grid-template-columns: 3fr repeat(2, 1fr) 2fr;
    gap: 24px;
    padding: 24px 0;
  }
`;

const TransportName = styled(Link)`
  color: var(--color-dark-green);
  margin-right: 8px;
  font-weight: bold;
  width: 88px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-right: 12px;
    width: 132px;
  }
`;

const TableHeader = styled(Row)`
  padding: 8px 0 0 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 0 0 0;
  }
`;

const TableRow = styled(Row)`
  border-radius: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 6px;
  }

  &:hover {
    background-color: var(--color-light-grey);
  }
`;
