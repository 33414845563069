import styled from "styled-components";
import { Icon, NavigateBackHeader, Notifications } from "components";
import {
  useGetNotificationQuery,
  useReadNotificationsMutation,
} from "store/api";

function NotificationsPage() {
  const { data: notifications } = useGetNotificationQuery();
  const [markAllNotificationsAsRead] = useReadNotificationsMutation();

  const userHasNoUnreadNotifications = notifications?.unread.length === 0;

  return (
    <Container>
      <NavigateBackHeader title="Notiser" />
      <UnreadHeader>
        <h3>Olästa</h3>
        {userHasNoUnreadNotifications ? (
          <p>Du har inga olästa notiser.</p>
        ) : (
          <div>
            Markera alla notiser som lästa
            <CheckIcon
              name="Check"
              onClick={() => markAllNotificationsAsRead()}
            />
          </div>
        )}
      </UnreadHeader>

      <Notifications notifications={notifications?.unread} />
      <Hr />
      <Notifications notifications={notifications?.read} />
    </Container>
  );
}

export default NotificationsPage;

const Container = styled.div`
  width: 100%;
  color: var(--color-black);
`;

const Header = styled.h1`
  text-align: center;
`;

const CheckIcon = styled(Icon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: scale(1);
  fill: var(--color-dark-green);

  &:hover {
    transform: scale(1.2);
  }
`;

const UnreadHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  p {
    grid-row: 2;
  }

  div {
    grid-column: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-dark-green);
    font-size: 12px;
  }
`;

const Hr = styled.hr`
  margin: 24px 0 32px;
`;
