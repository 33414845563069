import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product, Selectable } from "types";
import { RootState } from "store";

const initialState: Selectable<Product>[] = [];

const slice = createSlice({
  name: "selectedProducts",
  initialState,
  reducers: {
    selectProduct(selectedProducts, action: PayloadAction<Product>) {
      selectedProducts.push(action.payload);
    },
    unselectProduct(selectedProducts, action: PayloadAction<Product>) {
      const index = selectedProducts.findIndex(
        (p) => p.id === action.payload.id
      );

      if (index >= 0) selectedProducts.splice(index, 1);
      else throw new Error("Product not found, index is " + index);
    },
    toggleIsPallet(
      selectedProducts,
      action: PayloadAction<Selectable<Product>>
    ) {
      const product = selectedProducts.find((p) => p.id === action.payload.id);

      if (product) product.isPallet = !product.isPallet;
      else
        throw new Error(
          "Product with id " + action.payload.id + " was not found"
        );
    },
    resetSelectedProducts() {
      return [];
    },
  },
});

// Selectors
export const getSelectedProducts = (state: RootState) => state.selectedProducts;

export const isProductSelected = (state: RootState) => (product: Product) =>
  !!state.selectedProducts.find((p) => p.id === product.id);

export const {
  selectProduct,
  unselectProduct,
  toggleIsPallet,
  resetSelectedProducts,
} = slice.actions;

export default slice.reducer;
