interface Props {
  className?: string;
}

function PlusIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Miscellaneous"
      data-name="Icons: Miscellaneous"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Plus">
        <path d="m32,62.43c16.81,0,30.43-13.62,30.43-30.43S48.81,1.57,32,1.57,1.57,15.19,1.57,32s13.62,30.43,30.43,30.43Zm-2.85-19.97v-7.61h-7.61c-1.58,0-2.85-1.27-2.85-2.85s1.27-2.85,2.85-2.85h7.61v-7.61c0-1.58,1.27-2.85,2.85-2.85s2.85,1.27,2.85,2.85v7.61h7.61c1.58,0,2.85,1.27,2.85,2.85s-1.27,2.85-2.85,2.85h-7.61v7.61c0,1.58-1.27,2.85-2.85,2.85s-2.85-1.27-2.85-2.85Z" />
      </g>
    </svg>
  );
}

export default PlusIcon;
