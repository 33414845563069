import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { ReactNode } from "react";
import { format } from "date-fns";
import svSE from "date-fns/locale/sv";
import {
  Download,
  PackageType,
  MeasurementData,
  MeasurementProductData,
} from "types";

interface Props {
  download: Download;
}

const borderRadius = (radius: number) => ({
  borderTopLeftRadius: radius,
  borderTopRightRadius: radius,
  borderBottomRightRadius: radius,
  borderBottomLeftRadius: radius,
});

const styles = StyleSheet.create({
  page: {
    padding: "24 16 184 16",
  },
  inputCard: {
    display: "flex",
    flexDirection: "row",
    width: "88vw",
    height: "8vh",
    backgroundColor: "#a1c6ea40",
    justifyContent: "space-around",
    ...borderRadius(8),
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 16,
  },
  labelInputCard: {
    width: "16vh",
    height: "16px",
    marginLeft: "24px",
    fontSize: 10,
  },

  inputField: {
    position: "relative",
    width: "26vw",
    height: "6vh",
    backgroundColor: "white",
    border: "2px solid #26413c",
    ...borderRadius(8),
    textAlign: "center",
    padding: 8,
    justifyContent: "center",
  },

  labelInputField: { position: "absolute", top: 2, left: 4, fontSize: 8 },

  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    break: "auto",
  },
  resultCard: {
    position: "relative",
    margin: "0 8 8 8",
    width: "100%",
    border: "1px solid #787878",
    ...borderRadius(8),
    marginBottom: 24,
  },
  importantInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#2b2b2b",
  },
  banner: {
    backgroundColor: "#2e6053",
    color: "white",
    padding: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  productName: {
    color: "white",
    marginBottom: 4,
  },
  productPackaging: {
    color: "white",
    fontSize: 10,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2 8 2 8",
  },
  infoPalletContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "2 0 2 0",
  },
  bfdReductionContainer: {
    display: "flex",
    flexDirection: "column",
    height: 40,
    width: 88,
    textAlign: "center",
    paddingTop: 14,
  },
  bfdReductionContainerPallet: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: 88,
    height: 10,
  },
  importantInfoPallet: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2 8 2 8",
    width: "100%",
  },
  inputValues: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 8,
    paddingTop: 4,
    height: 56,
    backgroundColor: "#ddd",
    zIndex: "-1",
    borderTop: "1px solid #787878",
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  specificInputValue: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  palletInputTemperatures: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sansSerif: { fontFamily: "Helvetica" },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  palletLevelText: {
    color: "#2b2b2b",
    textAlign: "center",
  },
  footnote: {
    position: "absolute",
    bottom: 32,
    left: 8,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 27,
    width: "100vw",
    backgroundColor: "#2e6053",
    padding: "0 24 0 24",
  },
  footerText: {
    color: "white",
    fontSize: 10,
  },
  button: {
    position: "relative",
    width: "10vw",
    height: "3vh",
    border: "1px solid #2e6053",
    ...borderRadius(6),
    backgroundColor: "#bbddd0",
    textAlign: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 8,
  },
});

interface TextProps {
  style?: any;
  fontSize?: number;
  fontFamily?: string;
  children?: ReactNode;
}

function Bold({ children }: { children: ReactNode }) {
  return <Text style={{ fontFamily: "Helvetica-Bold" }}>{children}</Text>;
}

function StyledText({ fontSize = 16, style, children }: TextProps) {
  const classes = [{ fontSize }, styles.sansSerif];

  if (style) classes.push(style);

  return <Text style={classes}>{children}</Text>;
}

function StyledTextBold({ fontSize = 16, style, children }: TextProps) {
  const classes = [{ fontSize }, styles.sansSerif];

  if (style) classes.push(style);

  return (
    <Text style={classes}>
      <Bold>{children}</Bold>
    </Text>
  );
}

interface HeaderTextProps {
  position: "left" | "right";
  children?: ReactNode;
}

function HeaderText({ position, children }: HeaderTextProps) {
  return (
    <Text
      style={{ position: "absolute", top: 8, [position]: 32, fontSize: 10 }}
    >
      {children}
    </Text>
  );
}

function DownloadPdf({ download }: Props) {
  const { transport } = download;

  function formatDate(date: Date, formatString = "d MMM yyyy"): string {
    return format(new Date(date), formatString, {
      locale: svSE,
    }).replace(".", "");
  }

  interface Translations {
    [key: string]: string;
  }

  function formatDayText(dayCount: number) {
    return `${dayCount} ${dayCount === 1 ? "dag" : "dagar"}`;
  }

  function useTranslation() {
    return function translate(key: string) {
      return translations[key];
    };
  }

  const translations: Translations = {
    TOP: "Övre",
    MIDDLE: "Mitt",
    BOTTOM: "Nedre",
    all: "Alla",
    chilled: "Kylda",
    frozen: "Frysta",
    Poultry: "Fågel",
    Pork: "Fläsk",
    Beef: "Nöt",
    Mutton: "Lamm",
    Deer: "Vilt",
  };

  const t = useTranslation();

  let hasDeviantFrozenProducts;

  function checkFrozenProduct(
    data: Omit<MeasurementData, "productId" | "volumeInKg">,
    product: MeasurementProductData
  ) {
    if (product.isFrozen && data.input.temperature >= -2)
      hasDeviantFrozenProducts = true;

    //TODO kolla varför den returnerar true och false
    return product.isFrozen && data.input.temperature >= -2 ? true : false;
  }

  const hasPallets = transport.type === PackageType.PALLET;

  let breakPage;

  const isSavedBfd = download.transport.isSavedBfd;

  return (
    <Document>
      <Page wrap size="A4" style={[styles.page]}>
        {/* GENERELL INFO */}
        <HeaderText position="left">Resultat</HeaderText>
        <HeaderText position="right">{formatDate(new Date())}</HeaderText>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            marginBottom: 40,
          }}
        >
          <Image style={{ width: "20vw", height: "8vh" }} src={"/logo.png"} />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 16 4 16",
          }}
        >
          <StyledText fontSize={8} style={{ width: 144 }}>
            Produkt
          </StyledText>
          {hasPallets && (
            <StyledText style={{ width: 48, textAlign: "center" }} fontSize={8}>
              Lav
            </StyledText>
          )}
          <StyledText style={{ width: 88, textAlign: "center" }} fontSize={8}>
            Nytt BFD
          </StyledText>
          <StyledText style={{ width: 88, textAlign: "center" }} fontSize={8}>
            BFD minskning
          </StyledText>
          <StyledText style={{ width: 88, textAlign: "center" }} fontSize={8}>
            Du räddar
          </StyledText>
          <StyledText style={{ width: 88, textAlign: "center" }} fontSize={8}>
            kg CO2e
          </StyledText>
        </View>
        <View style={styles.cardContainer}>
          {download.transport.measurementResult.map((mr, i) => {
            const isFourthCard = (i + 1) % 5 === 0;

            if (isFourthCard) {
              breakPage = true;
            } else breakPage = false;

            return (
              <View
                break={breakPage}
                key={mr.id}
                style={{
                  position: "relative",
                  margin: "0 8 8 8",
                  width: "100%",
                  border: "1px solid #787878",
                  ...borderRadius(8),
                  marginBottom: 8,
                }}
              >
                {/* ENSKILDA */}
                {transport.type === PackageType.PACKAGE && (
                  <View style={styles.infoContainer}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#2b2b2b",
                        height: 40,
                        width: 144,
                      }}
                    >
                      <StyledText fontSize={10} style={{ marginBottom: 6 }}>
                        <Bold>{mr.product?.name} </Bold>
                      </StyledText>
                      <StyledText fontSize={8} style={{ marginBottom: 6 }}>
                        Temperatur: {mr.product?.isFrozen ? "Fryst" : "Kylt"}{" "}
                      </StyledText>
                      <StyledText fontSize={8} style={{ marginBottom: 6 }}>
                        Förpackning: {mr.product?.packaging}
                      </StyledText>
                    </View>
                    <View style={styles.bfdReductionContainer}>
                      <StyledText fontSize={8}>
                        {checkFrozenProduct(mr.data[0], mr.product!)
                          ? "***"
                          : isSavedBfd
                          ? formatDate(mr.data[0].calculation.newSavedBfd)
                          : formatDate(
                              mr.data[0].calculation.newBfdAfterDeviation
                            )}
                      </StyledText>
                    </View>
                    <View style={styles.bfdReductionContainer}>
                      <StyledText fontSize={8}>
                        {checkFrozenProduct(mr.data[0], mr.product!)
                          ? " ***"
                          : formatDayText(
                              isSavedBfd
                                ? mr.data[0].calculation.dayDiff -
                                    mr.data[0].calculation.savedDayDiff
                                : mr.data[0].calculation.dayDiff
                            )}
                      </StyledText>
                    </View>
                    <View style={styles.bfdReductionContainer}>
                      <StyledText fontSize={8}>
                        {checkFrozenProduct(mr.data[0], mr.product!)
                          ? "***"
                          : isSavedBfd
                          ? formatDayText(
                              mr.data[0].calculation.savedRescuedDays
                            )
                          : formatDayText(mr.data[0].calculation.rescuedDays)}
                      </StyledText>
                    </View>
                    <View style={styles.bfdReductionContainer}>
                      <StyledText fontSize={8}>
                        {mr.generalCalculation.co2EmissionInKg} kg
                      </StyledText>
                    </View>
                  </View>
                )}
                {/* // SLUT ENKSILDA  */}
                {/* //PALL  */}
                {transport.type === PackageType.PALLET && (
                  <View style={styles.infoPalletContainer}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#2b2b2b",
                        paddingLeft: 8,
                        height: 48,
                        width: 144,
                      }}
                    >
                      <StyledText fontSize={10} style={{ marginBottom: 4 }}>
                        <Bold>{mr.product?.name} </Bold>
                      </StyledText>
                      <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                        Temperatur: {mr.product?.isFrozen ? "Fryst" : "Kylt"}
                      </StyledText>
                      <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                        Förpackning: {mr.product?.packaging}
                      </StyledText>
                    </View>
                    <View>
                      {mr.data.map((md) => (
                        <View key={md.level} style={styles.importantInfoPallet}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              width: 48,
                            }}
                          >
                            <StyledText fontSize={8}>{t(md.level)}</StyledText>
                          </View>
                          <View style={styles.bfdReductionContainerPallet}>
                            <StyledText fontSize={8}>
                              {checkFrozenProduct(md, mr.product!)
                                ? "***"
                                : isSavedBfd
                                ? formatDate(md.calculation.newSavedBfd)
                                : formatDate(
                                    md.calculation.newBfdAfterDeviation
                                  )}
                            </StyledText>
                          </View>
                          <View style={styles.bfdReductionContainerPallet}>
                            <StyledText fontSize={8}>
                              {checkFrozenProduct(md, mr.product!)
                                ? "-----"
                                : formatDayText(
                                    isSavedBfd
                                      ? md.calculation.dayDiff -
                                          md.calculation.savedDayDiff
                                      : md.calculation.dayDiff
                                  )}
                            </StyledText>
                          </View>
                          <View style={styles.bfdReductionContainerPallet}>
                            <StyledText fontSize={8}>
                              {checkFrozenProduct(md, mr.product!)
                                ? "***"
                                : isSavedBfd
                                ? formatDayText(md.calculation.savedRescuedDays)
                                : formatDayText(md.calculation.rescuedDays)}
                            </StyledText>
                          </View>
                          <View style={styles.bfdReductionContainerPallet}>
                            <StyledText fontSize={8}>
                              {Math.round(
                                mr.generalCalculation.co2EmissionInKg / 3
                              )}
                              kg
                            </StyledText>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                )}
                <View style={styles.inputValues}>
                  <View style={styles.specificInputValue}>
                    <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                      Avvikelsens längd:
                    </StyledText>
                    <StyledTextBold fontSize={8}>
                      {download.transport.deviation.duration} h
                    </StyledTextBold>
                  </View>
                  <View style={styles.specificInputValue}>
                    <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                      Datum för avvikelsen:
                    </StyledText>
                    <StyledTextBold fontSize={8}>
                      {formatDate(download.transport.deviation.date)}
                    </StyledTextBold>
                  </View>
                  <View style={styles.specificInputValue}>
                    <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                      BFD enligt förpackning:
                    </StyledText>
                    <StyledTextBold fontSize={8}>
                      {formatDate(mr.data[0].input.bestBeforeDate)}
                    </StyledTextBold>
                  </View>
                  <View style={styles.specificInputValue}>
                    <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                      Volym:
                    </StyledText>
                    <StyledTextBold fontSize={8}>
                      {mr.volumeInKg} kg
                    </StyledTextBold>
                  </View>
                  <View>
                    {transport.type === PackageType.PACKAGE ? (
                      <View style={styles.specificInputValue}>
                        <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                          Uppmätt produkttemperatur (°C):
                        </StyledText>
                        <StyledTextBold fontSize={8}>
                          {mr.data[0].input.temperature} °C
                        </StyledTextBold>
                      </View>
                    ) : (
                      <View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                            Uppmätt produkttemperatur (°C):
                          </StyledText>
                          <StyledTextBold
                            fontSize={8}
                            style={{ marginLeft: 4 }}
                          >
                            {mr.data[0].input.temperature} °C
                          </StyledTextBold>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                            Uppmätt produkttemperatur (°C):
                          </StyledText>
                          <StyledTextBold
                            fontSize={8}
                            style={{ marginLeft: 4 }}
                          >
                            {mr.data[1].input.temperature} °C
                          </StyledTextBold>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <StyledText fontSize={8} style={{ marginBottom: 4 }}>
                            Uppmätt produkttemperatur (°C):
                          </StyledText>
                          <StyledTextBold
                            fontSize={8}
                            style={{ marginLeft: 4 }}
                          >
                            {mr.data[2].input.temperature} °C
                          </StyledTextBold>
                        </View>
                      </View>
                    )}
                  </View>
                </View>
                {/* SLUT BANNER */}
              </View>
            );
            //SLUT PALL
          })}
        </View>

        {hasDeviantFrozenProducts && (
          <View
            fixed
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              textAlign: "center",
              alignContent: "center",
              bottom: 48,
              left: 6,
            }}
          >
            <StyledText fontSize={8} style={{ color: "grey" }}>
              *** Vid -2°C påbörjas den mikrobiella tillväxten. Denna avvikelse
              kräver därmed en närmare bedömning.
            </StyledText>
            <StyledText fontSize={8} style={{ color: "grey", margin: 12 }}>
              Vänligen kontakta oss på SFW via hello@sustainablefoodwaste.se
            </StyledText>
          </View>
        )}
        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            textAlign: "center",
            alignContent: "center",
            bottom: 104,
            left: 16,
            marginLeft: 48,
            padding: "0 16 0 16",
          }}
        >
          <StyledText fontSize={10} style={{ color: "grey" }}>
            Kvarvarande hållbarhetstid och bäst före-datum har beräknats i SFW
            Appen. Alla mikrobiologiska beräkningsmodeller i SFW Appen är
            vetenskapligt validerade för det livsmedel som ska utvärderas.
          </StyledText>
          <StyledText fontSize={10} style={{ color: "grey", marginLeft: 60 }}>
            <Bold>
              För att läsa mer om beräkningsmodellerna tryck på knappen eller
              länken nedan.
            </Bold>
          </StyledText>
          <StyledText
            fontSize={10}
            style={{
              color: "#2e6053",
              textAlign: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            https://www.sustainablefoodwaste.se/berakningsmodeller/
          </StyledText>
          <View style={styles.button}>
            <StyledText
              fontSize={10}
              style={{
                color: "#2e6053",
              }}
            >
              <a href="https://www.sustainablefoodwaste.se/berakningsmodeller/">
                <Bold>Tryck här</Bold>
              </a>
            </StyledText>
          </View>
        </View>
        {hasPallets && (
          <View style={styles.footnote}>
            <StyledText fontSize={8} style={{ color: "#26413c" }}>
              <Bold>* Uppmätt produkttemperatur (°C)</Bold> står i formatet Övre
              lav / Mellersta lav / Nedre lav
            </StyledText>
          </View>
        )}
        <View fixed style={styles.footer}>
          <Text style={styles.footerText}>Sustainable Food Waste SFW AB</Text>
          <Text style={styles.footerText}>hello@sustainablefoodwaste.se</Text>
          <Text style={styles.footerText}>www.sustainablefoodwaste.se</Text>
        </View>
      </Page>
    </Document>
  );
}

export default DownloadPdf;
