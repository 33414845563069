import { Routes, Route, Navigate } from "react-router-dom";
import {
  MeasurementPage,
  ResultsPage,
  HistoryTablePage,
  LoginPage,
  ProductSelectionPage,
  AppLayout,
  PageLayout,
  StepbarLayout,
  HistoryPage,
  AnalyticsPage,
  DonationPage,
  NotificationsPage,
  DonationTable,
  ReportConfirmPage,
} from "pages";
import { DetachedDonationForm, DonationForm, FeedbackForm } from "components";

function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<PageLayout />}>
          <Route element={<StepbarLayout />}>
            <Route path="/products" element={<ProductSelectionPage />} />
            <Route path="/measurement" element={<MeasurementPage />} />
            <Route path="/result/:transportId" element={<ResultsPage />} />
            <Route path="*" element={<Navigate to="/products" />} />
          </Route>
        </Route>
        <Route element={<PageLayout />}>
          <Route path="/feedback" element={<FeedbackForm />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/history/:transportId" element={<HistoryPage />} />
          <Route path="/history" element={<HistoryTablePage />} />
          <Route
            path="/donation/new/detachedDonation"
            element={<DetachedDonationForm />}
          />
          <Route
            path="/donation/new/:transportId?"
            element={<DonationPage />}
          />
          <Route
            path="/donation/new/:transportId/form"
            element={<DonationForm />}
          />
          <Route path="/donation" element={<DonationPage />} />
          <Route path="/donation/:donationId" element={<DonationTable />} />
          <Route path="/analytics" element={<AnalyticsPage />} />
          <Route path="/ReportConfirmPage" element={<ReportConfirmPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

export default App;
