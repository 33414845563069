import { MouseEvent } from "react";

interface Props {
  className?: string;
  onClick?: (e: MouseEvent<SVGSVGElement>) => void;
}

function XMarkIcon({ className, onClick }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Miscellaneous"
      data-name="Icons: Miscellaneous"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
      onClick={onClick}
    >
      <g id="Xmark">
        <path d="m59.14,13.01c2.25-2.25,2.25-5.91,0-8.16-2.25-2.25-5.91-2.25-8.16,0l-18.98,18.99L13.01,4.86c-2.25-2.25-5.91-2.25-8.16,0-2.25,2.25-2.25,5.91,0,8.16l18.99,18.98L4.86,50.99c-2.25,2.25-2.25,5.91,0,8.16s5.91,2.25,8.16,0l18.98-18.99,18.99,18.98c2.25,2.25,5.91,2.25,8.16,0s2.25-5.91,0-8.16l-18.99-18.98,18.98-18.99Z" />
      </g>
    </svg>
  );
}

export default XMarkIcon;
