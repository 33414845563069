import { MouseEvent } from "react";
import styled from "styled-components";

interface Options {
  labelBefore: boolean;
}

interface Props {
  className?: string;
  id: string;
  label: string;
  show: boolean;
  onToggle: (e: MouseEvent) => void;
  options?: Options;
}

function Toggle({ className, id, label, show, onToggle, options }: Props) {
  return (
    <StyledToggle className={className}>
      {options?.labelBefore && <label>{label}</label>}
      <Input onClick={onToggle} show={show}>
        <input id={id} type="checkbox" />
        <i className={`fa-solid fa-circle-${show ? "check" : "xmark"}`} />
      </Input>
      {!options?.labelBefore && <label>{label}</label>}
    </StyledToggle>
  );
}

export default Toggle;

interface ToggleProps {
  show?: boolean;
}

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 24px;
  }
`;

const Input = styled.div<ToggleProps>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    appearance: none;
    outline: none;
    width: 50px;
    height: 24px;
    background-color: ${({ show }) =>
      show ? "var(--color-dark-green)" : "rgba(180, 204, 188, 0.5)"};
    border-radius: 50px;
    transition: all 0.2s ease-out;
    cursor: pointer;

    @media (min-width: 1400px) and (min-height: 800px) {
      width: 75px;
      height: 36px;
      background-color: ${({ show }) =>
        show ? "var(--color-dark-green)" : "rgba(180, 204, 188, 0.5)"};
      border-radius: 75px;
    }
  }

  i {
    position: absolute;
    top: 3px;
    left: ${({ show }) => (show ? "29px" : "3px")};
    color: ${({ show }) => (show ? "white" : "var(--color-dark-green)")};
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s ease-out;

    @media (min-width: 1400px) and (min-height: 800px) {
      top: 6px;
      font-size: 24px;
      left: ${({ show }) => (show ? "44px" : "6px")};
    }
  }
`;
