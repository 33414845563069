interface Props {
  className?: string;
}

function DonateIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Donate">
        <path d="m22.64,59.14c-5.63,0-11.26.01-16.88,0-3-.01-4.19-1.2-4.21-4.19-.02-1.95-.04-3.9,0-5.84.06-2.35,1.3-3.8,3.61-3.6,2.79.25,4.85-.8,6.76-2.61,3.38-3.19,7.37-4.54,12.05-4.33,4.54.2,9.09.04,13.64.05,3.02,0,4.66,1.29,4.63,3.61-.03,2.23-1.62,3.46-4.52,3.48-2.24.01-4.49-.13-6.7.08-.75.07-1.43.98-2.14,1.51.69.47,1.37,1.32,2.08,1.35,3.6.14,7.22.13,10.82.02.86-.03,1.81-.47,2.53-.98,3.53-2.51,6.98-5.12,10.48-7.67,2.7-1.97,5.22-1.79,6.83.45,1.55,2.17,1.03,4.65-1.51,6.55-4.28,3.19-8.59,6.34-12.92,9.46-2.53,1.82-5.37,2.71-8.51,2.69-5.34-.03-10.68,0-16.02,0Zm12.97-49.96c-1.01-.04-2.02-.04-3.03.02-3.02.19-5.63,1.3-7.53,3.7-.71.89-1.17,1.97-1.91,3.25,2.52-.42,4.48-.95,6.45-1.03,1.88-.08,3.9-.54,5.69.78-.84.42-1.68.6-2.53.59-3.04-.03-6.09-.13-8.85,1.46-4.41,2.55-6.84,6.33-6.67,11.51.02.68.95,1.32,1.46,1.98.53-.65,1.18-1.24,1.56-1.97.24-.46-.06-1.18.12-1.7.62-1.73,1.35-3.43,2.23-5.61.6.94.77,1.17.91,1.42,3.36,6.32,10.23,8.06,16.12,4,3.75-2.59,5.84-6.31,6.74-10.71.78-3.81.62-7.55-1.39-12.01-2.21,4.52-5.77,4.47-9.38,4.32Z" />
      </g>
    </svg>
  );
}

export default DonateIcon;
