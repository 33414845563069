import { useEffect, useState } from "react";
import { useGetTransportsQuery } from "store/api";
import { MeasurementProductData, Selectable } from "types";

export function useLatestProducts(count = 5) {
  const [result, setResult] = useState<Selectable<MeasurementProductData>[]>(
    []
  );
  const { data: transports } = useGetTransportsQuery();

  useEffect(() => {
    if (!transports) return;

    transports.forEach((transport) => {
      if (result.length >= count) return;

      transport.measurementResult.forEach((mr) => {
        if (result.length >= count) return;
        if (result.find((product) => product.id === mr.product!.id)) return;

        result.push(mr.product!);
      });
    });

    setResult([...result]);
  }, [transports]);

  return result;
}
