import { Category } from "@prisma/client";

export enum PackageType {
  PACKAGE = "PACKAGE",
  PALLET = "PALLET",
}

export enum Level {
  TOP = "TOP",
  MIDDLE = "MIDDLE",
  BOTTOM = "BOTTOM",
  PACKAGE = "PACKAGE",
}

export type MeasurementInput = {
  bestBeforeDate: Date;
  temperature: number;
};

export type Calculation = {
  dayDiff: number;
  rescuedDays: number;
  newBfdAfterDeviation: Date;
  percentageDayDiff: number;
  savedDayDiff: number;
  savedRescuedDays: number;
  newSavedBfd: Date;
  percentageSavedDayDiff: number;
};

// This is sent from the client, included in MeasurementPayload
export type MeasurementData = {
  productId: string;
  level: Level;
  input: MeasurementInput;
  volumeInKg: number;
  calculation: Calculation;
};

type Deviation = {
  date: Date;
  duration: number;
};

// This is sent from the client
export type MeasurementPayload = {
  userId: string;
  companyId: string;
  isPallet: boolean;
  deviation: Deviation;
  data: Omit<MeasurementData, "calculation">[];
};

type GeneralMeasurementCalculation = {
  co2EmissionInKg: number;
};

export type MeasurementProductData = {
  id: string;
  name: string;
  packaging: string;
  category: Category;
  packageTemp: number;
  isFrozen: boolean;
};

export type MeasurementResult = {
  id?: string;
  productId: string;
  volumeInKg: number;
  generalCalculation: GeneralMeasurementCalculation;
  product?: MeasurementProductData;
  data: Omit<MeasurementData, "productId" | "volumeInKg">[];
  donationId?: string;
};

export type Transport = {
  id: string;
  name: string;
  type: PackageType;
  isFavorite: boolean;
  isSavedBfd: boolean;
  userId: string;
  companyId: string;
  deviation: Deviation;
  createdDate: Date;
  isArchived?: Boolean;
  measurementResult: MeasurementResult[];
};
