import styled from "styled-components";
import { useTranslation } from "translate";

interface Props {
  checked: boolean;
  onChange(): void;
  count: number;
  label: string;
}

function CheckboxCategory({ checked, onChange, label, count }: Props) {
  const t = useTranslation();

  return (
    <Category>
      <Checkbox
        id={label}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <Label checked={checked} htmlFor={label}>
        {t(label)}
      </Label>
      <Text checked={checked}>({count})</Text>
    </Category>
  );
}

export default CheckboxCategory;

const Category = styled.div`
  display: grid;
  grid-template-columns: 16px 10fr 1fr;
  gap: 8px;
  align-items: center;
  padding: 4px 0;
`;

interface CheckedProps {
  checked: boolean;
}

const Text = styled.div<CheckedProps>`
  font-size: 12px;
  text-align: right;
  font-weight: ${({ checked }) => (checked ? "bold" : "normal")};
`;

const Label = styled.label<CheckedProps>`
  font-size: 12px;
  font-weight: ${({ checked }) => (checked ? "bold" : "normal")};
  cursor: pointer;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-dark-green);
  border-radius: 2px;
  cursor: pointer;
`;
