import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface SearchParam {
  [key: string]: string;
}

export function useSearchParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<SearchParam>({});

  useEffect(() => {
    const dirtySearchParams = location.search.slice(1).split("&");
    const searchParams = dirtySearchParams.reduce(
      (paramsObject, searchParam) => {
        const [key, value] = searchParam.split("=");
        paramsObject[key] = value;
        return paramsObject;
      },
      {} as SearchParam
    );

    setSearchParams(searchParams);
  }, [location]);

  return searchParams;
}
