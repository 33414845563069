import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSelectedFilter } from "store/selectedFilter";
import { Filter as FilterType } from "types";

interface Props {
  onSelect: (filter: FilterType) => void;
  filters: FilterType[];
}

function Filter({ onSelect, filters }: Props) {
  const selectedFilter = useSelector(getSelectedFilter);

  return (
    <Filters>
      {filters.map((filter) => (
        <StyledFilter
          key={filter.label}
          active={selectedFilter.label === filter.label}
          onClick={() => onSelect(filter)}
        >
          {filter.label}
        </StyledFilter>
      ))}
    </Filters>
  );
}

export default Filter;

interface FilterProps {
  active?: boolean;
}

const Filters = styled.ul`
  display: inline-flex;
  border: 2px solid var(--color-dark-green);
  border-radius: 8px;
  margin-bottom: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border: 3px solid var(--color-dark-green);
    border-radius: 12px;
  }
`;

const StyledFilter = styled.li<FilterProps>`
  text-align: center;
  width: 56px;
  list-style-type: none;
  padding: 4px 8px;
  border-right: 2px solid var(--color-dark-green);
  cursor: pointer;
  font-weight: bold;
  background-color: ${({ active }) =>
    active ? "var(--color-dark-green)" : "transparent"};
  color: ${({ active }) => (active ? "white" : "var(--color-black)")};

  @media (min-width: 1400px) and (min-height: 800px) {
    border-right: 3px solid var(--color-dark-green);
    width: 69px;
    list-style-type: none;
    padding: 6px 12px;
  }

  &:last-of-type {
    border-right: none;
  }
`;
