import styled from "styled-components";
import { Transport } from "types";
import PalletResultCard from "./PalletResultCard";

interface Props {
  transport: Transport;
}

function PalletResultList({ transport }: Props) {
  return (
    <List>
      <Headers>
        <div style={{ justifySelf: "start" }}>Produkt</div>
        <div>Lav</div>
        <div>Nytt BFD</div>
        <div>BFD minskning</div>
        <div>Du räddar</div>
        <div>kg CO2e</div>
        <div></div>
      </Headers>

      {transport.measurementResult.map((mr) => (
        <PalletResultCard
          key={mr.id}
          transport={transport}
          measurementResult={mr}
        />
      ))}
    </List>
  );
}

export default PalletResultList;

const List = styled.div`
  margin-bottom: 104px;
`;

const Headers = styled.div`
  display: grid;
  grid-template-columns: 25fr 3.13fr repeat(4, 15.63fr) 9.38fr;
  justify-items: center;
  padding: 8px;
  gap: 16px;
`;
