interface Props {
  className?: string;
}

function TemperatureIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Icons:_Temperature"
      data-name="Icons: Temperature"
      viewBox="0 0 250 250"
    >
      <path d="m162.17,93.3c0,9.75.04,19.51-.03,29.26-.02,2.46.71,4.48,2.37,6.26,5.36,5.75,9.8,12.1,12.56,19.54,2.76,7.48,4.01,15.19,3.45,23.12-.64,9.07-3.01,17.68-7.68,25.55-5.8,9.78-13.89,17.08-24.2,21.86-7.45,3.45-15.37,5.25-23.51,5.25-8.75,0-17.24-1.94-25.13-5.9-13.09-6.58-22.28-16.79-27.2-30.59-3.38-9.49-4.36-19.28-2.59-29.31,2-11.37,7.24-21.01,15.1-29.32,1.81-1.91,2.56-4.07,2.55-6.73-.07-19.39-.09-38.78,0-58.17.04-7.68,1.65-15.03,6.02-21.52,5.96-8.87,14.15-14.35,24.75-16.19,19.75-3.41,39.37,9.17,42.81,30.05.43,2.61.68,5.28.71,7.93.08,9.63.03,19.27.03,28.91Zm-12.31-.11c0-9.75.04-19.5-.01-29.26-.04-8.4-3.17-15.34-9.84-20.65-7.69-6.13-19.43-6.84-27.73-1.57-8.09,5.15-12.09,12.59-12.13,22.09-.08,19.56-.02,39.13-.03,58.69,0,3.71-.56,7.37-2.5,10.55-1.43,2.35-3.2,4.53-5.06,6.57-7.48,8.23-11.04,18.02-10.97,28.96.07,11.18,3.72,21.16,11.61,29.41,11.96,12.49,26.4,16.25,43,12.41,8.76-2.03,15.75-6.95,21.68-13.61,7.28-8.18,10.53-17.88,10.5-28.6-.03-11.35-3.93-21.39-11.99-29.61-4.63-4.73-6.66-10.4-6.58-17.01.11-9.45.03-18.91.03-28.37Z" />
      <path d="m131.11,112.29c0,9.93.06,19.85-.05,29.78-.02,1.85.54,2.63,2.28,3.24,9.75,3.45,16.24,12.05,16.34,22.23.1,10.12-4.41,18.01-13.38,22.71-8.79,4.61-17.66,3.89-25.74-1.95-11.28-8.15-13.59-23.57-5.33-34.7,2.98-4.02,6.92-6.92,11.76-8.41,1.47-.45,1.95-1.18,1.95-2.76-.06-19.68-.04-39.35-.04-59.03,0-.77,0-1.54.07-2.3.33-3.53,2.63-5.69,6.03-5.69,3.4,0,5.87,2.15,6,5.69.19,5.02.1,10.04.11,15.06.01,5.38,0,10.75,0,16.13Zm6.34,56.15c0-7.3-5.24-12.53-12.5-12.47-7.25.05-12.37,5.17-12.4,12.39-.03,7.33,5.09,12.52,12.41,12.56,7.27.05,12.49-5.18,12.49-12.48Z" />
    </svg>
  );
}

export default TemperatureIcon;
