import { MouseEvent } from "react";

interface Props {
  className?: string;
  onClick?: (e: MouseEvent<SVGSVGElement>) => void;
}

function DonateIcon({ className, onClick }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Miscellaneous"
      data-name="Icons: Miscellaneous"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
      onClick={onClick}
    >
      <g id="Check">
        <path d="m32,62.42c16.8,0,30.42-13.62,30.42-30.42S48.8,1.58,32,1.58,1.58,15.2,1.58,32s13.62,30.42,30.42,30.42Zm13.43-36.01l-15.21,15.21c-1.12,1.12-2.92,1.12-4.03,0l-7.61-7.61c-1.12-1.12-1.12-2.92,0-4.03s2.92-1.12,4.03,0l5.59,5.59,13.19-13.2c1.12-1.12,2.92-1.12,4.03,0s1.12,2.92,0,4.03h.01Z" />
      </g>
    </svg>
  );
}

export default DonateIcon;
