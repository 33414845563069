import { format } from "date-fns";
import svSE from "date-fns/locale/sv";
import { Page, Step } from "types";

export function enumToArray(enumToConvert: Object): number[] {
  return Object.values(enumToConvert).filter((key) => typeof key === "number");
}

export function getSteps(): Step[] {
  return [
    { page: Page.PRODUCT_SELECTION, text: "Välj produkt", link: "/products" },
    { page: Page.PRODUCT_FORM, text: "Ange avvikelse", link: "/measurement" },
    { page: Page.RESULT, text: "Resultat", link: "/result" },
  ];
}

export function toFloat(number: string) {
  return parseFloat(number.replace(",", "."));
}

export function toDate(dateString: string): Date {
  const [year, month, date] = dateString.split("-").map((d) => +d);
  const dateObj = new Date();
  dateObj.setFullYear(year, month - 1, date);
  return dateObj;
}

export function formatDate(date: Date, formatString = "d MMM yyyy"): string {
  return format(new Date(date), formatString, {
    locale: svSE,
  }).replace(".", "");
}

export function getMonthName(monthIndex: number, capitalize = true): string {
  const monthName = format(new Date(2023, monthIndex), "LLLL", {
    locale: svSE,
  }).replace(".", "");

  return capitalize
    ? monthName[0].toUpperCase() + monthName.substring(1).toLowerCase()
    : monthName;
}

export function formatTime(date: Date): string {
  return format(new Date(date), "HH:mm");
}
