import { ChangeEvent } from "react";

interface Props {
  name: string;
  label: string;
  value: string;
  error: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function NumberInput({
  name,
  label,
  value,
  error,
  min,
  max,
  step,
  onChange,
}: Props) {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
      {error && <p>{error}</p>}
    </div>
  );
}

export default NumberInput;
