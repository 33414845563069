import { MouseEvent, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  show: boolean;
  onToggle: (e: MouseEvent) => void;
  children: ReactNode;
}

function InfoCircle({ show, onToggle, children }: Props) {
  return (
    <Container>
      <InfoIcon
        className="fa-solid fa-circle-info"
        onMouseEnter={onToggle}
        onMouseLeave={onToggle}
      />
      {show && <InfoBox>{children}</InfoBox>}
    </Container>
  );
}

export default InfoCircle;

const Container = styled.span`
  position: relative;
`;

const InfoIcon = styled.i`
  margin-left: 8px;
  cursor: pointer;
  transform: scale(1);

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-left: 12px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const InfoBox = styled.div`
  text-align: right;
  position: absolute;
  padding: 8px 16px;
  background-color: var(--color-light-green);
  left: 32px;
  top: -40px;
  color: var(--color-dark-green);
  z-index: 150;
  width: 304px;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 24px;
    left: 48px;
    top: -60px;
    width: 456px;
    border-radius: 12px;
  }
`;
