import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getSteps } from "utils";
import { Step } from "types";

const steps = getSteps();

function Stepbar() {
  const [currentStep, setCurrentStep] = useState<Step>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentStep = steps.find((step) =>
      location.pathname.startsWith(step.link)
    );

    if (!currentStep) return;

    setCurrentStep(currentStep);
  }, [location]);

  if (!currentStep) return null;

  return (
    <NavBar>
      {steps.map((step, stepIndex) => {
        const isCurrentStep = step.page === currentStep.page;
        const isPreviousStep = step.page < currentStep.page;

        return (
          <NavItemContainer
            key={stepIndex}
            onClick={isPreviousStep ? () => navigate("/products") : undefined}
            isPreviousStep={isPreviousStep}
          >
            <NavItem
              isCurrentStep={isCurrentStep}
              isPreviousStep={isPreviousStep}
            >
              {stepIndex + 1}. {step.text}
            </NavItem>
            <NavLine
              isCurrentStep={isCurrentStep}
              isPreviousStep={isPreviousStep}
            />
          </NavItemContainer>
        );
      })}
    </NavBar>
  );
}

const NavBar = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-bottom: 16px;
  gap: 64px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: 32px;
  }
`;

interface NavItemProps {
  isPreviousStep?: boolean;
  isCurrentStep?: boolean;
}

const NavItemContainer = styled.div<NavItemProps>`
  display: grid;
  justify-items: center;
  width: 100%;
  cursor: ${({ isPreviousStep }) => (isPreviousStep ? "pointer" : "default")};
`;

const NavItem = styled.div<NavItemProps>`
  font-size: 20px;
  font-weight: ${({ isCurrentStep }) => (isCurrentStep ? "bold" : "normal")};
  color: ${({ isCurrentStep, isPreviousStep }) =>
    isCurrentStep || isPreviousStep
      ? "var(--color-dark-green)"
      : "var(--color-light-grey)"};

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 30px;
  }
`;

const NavLine = styled.div<NavItemProps>`
  margin-top: 4px;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ isCurrentStep, isPreviousStep }) =>
    isCurrentStep
      ? "var(--color-dark-green)"
      : isPreviousStep
      ? "var(--color-light-green)"
      : "var(--color-light-grey)"};
`;

export default Stepbar;
