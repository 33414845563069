import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeasurementResult } from "types";
import { RootState } from "store";

const initialState: MeasurementResult[] = [];

const slice = createSlice({
  name: "productsForDonation",
  initialState,
  reducers: {
    removeProductForDonation(
      productsForDonation,
      action: PayloadAction<MeasurementResult>
    ) {
      const index = productsForDonation.findIndex(
        (mr) => mr.id === action.payload.id
      );
      productsForDonation.splice(index, 1);
    },
    submitAllProductsForDonation(productsForDonation, action) {
      return action.payload;
    },
    reset() {
      return [];
    },
  },
});

// Selectors
export const getProductsForDonation = (state: RootState) =>
  state.productsForDonation;

export const { removeProductForDonation, submitAllProductsForDonation, reset } =
  slice.actions;

export default slice.reducer;
