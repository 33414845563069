import styled from "styled-components";
import { Notification as NotificationType } from "types";
import Notification from "./Notification";

interface Props {
  notifications?: NotificationType[];
}

function Notifications({ notifications = [] }: Props) {
  return (
    <Container>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </Container>
  );
}

export default Notifications;

const Container = styled.div`
  display: grid;
  gap: 16px;
`;
