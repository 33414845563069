import { configureStore } from "@reduxjs/toolkit";
import selectedProducts from "store/selectedProducts";
import selectedFilter from "store/selectedFilter";
import productsForDonation from "store/productsForDonation";

import api from "store/api";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    selectedProducts,
    selectedFilter,
    productsForDonation,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    api.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
