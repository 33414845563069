import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Transport } from "types";
import { Favorite } from "components";
import { formatDate, formatTime } from "utils";
import { useGetTransportsQuery, useUpdateTransportMutation } from "store/api";

function HistoryTable() {
  const { data: transports = [] } = useGetTransportsQuery();
  const [updateTransport, { isLoading: isUpdatingTransport }] =
    useUpdateTransportMutation();

  const location = useLocation();
  const isHistoryPage = location.pathname === "/history";

  function handleFavor(transport: Transport) {
    if (isUpdatingTransport) return;
    updateTransport({ ...transport, isFavorite: !transport.isFavorite });
  }

  if (transports.length === 0)
    return <h2>Du har inte gjort några mätningar än!</h2>;

  return (
    <Table>
      <thead>
        <TableHeader>
          <th>Namn</th>
          <th>Datum</th>
          <th className="center">Klockslag</th>
          <th className="center">Produktantal</th>
          <th />
        </TableHeader>
      </thead>
      <tbody>
        {transports.map((transport) => (
          <TableRow key={transport.id} isHistoryPage={isHistoryPage}>
            <td className="name">
              <TransportName to={`${location.pathname}/${transport.id}`}>
                {transport.name}
              </TransportName>
            </td>
            <td>{formatDate(transport.createdDate)}</td>
            <td className="center">{formatTime(transport.createdDate)}</td>
            <td className="center">{transport.measurementResult.length}</td>
            <td className="center">
              <Favorite
                isFavorite={transport.isFavorite}
                onFavor={() => handleFavor(transport)}
              />
            </td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

export default HistoryTable;

const Table = styled.table`
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 100%;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  .name {
    display: flex;
    align-items: center;

    input {
      width: 88px;
      margin-right: 8px;

      @media (min-width: 1400px) and (min-height: 800px) {
        width: 132px;
        margin-right: 12px;
      }
    }
  }

  .center {
    justify-self: center;
  }
`;
interface isHistoryPage {
  isHistoryPage?: boolean;
}

const Row = styled.tr<isHistoryPage>`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr repeat(3, 2fr) 1fr;
  gap: 16px;
  align-items: center;
  justify-items: start;
  padding: 16px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    grid-template-columns: 3fr repeat(3, 2fr) 1fr;
    gap: 24px;
    padding: 24px 0;
  }
`;

const TableHeader = styled(Row)`
  padding: 8px 0 0 0;
  width: 100%;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 0 0 0;
  }
`;

const TableRow = styled(Row)`
  border-radius: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 6px;
  }

  &:hover {
    background-color: var(--color-light-grey);
  }
`;

const TransportName = styled(Link)`
  color: var(--color-dark-green);
  margin-right: 8px;
  font-weight: bold;
  width: 88px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-right: 12px;
    width: 132px;
  }
`;
