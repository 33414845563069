interface Props {
  className?: string;
}

function BreadIcon({ className }: Props) {
  return (
    <svg
      version="1.1"
      id="Lager_1"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 1920 1080"
      className={className}
      strokeWidth={1}
      stroke="#000000"
    >
      <path
        className="st0"
        d="M313.5,707.6c0-10.6,18.5-10.6,18.5,0C332.5,718.1,313.5,718.1,313.5,707.6z M304.7,766.7
	c4.9,0,8.8-3.5,8.8-7.8c0-10.6-18.5-10.6-18.5,0C295,760.4,295.8,766.7,304.7,766.7z M420.8,629.2c4.9,0,8.8-3.5,8.8-7.8
	c0-10.6-18.5-10.6-18.5,0C411.1,622.6,412,629.2,420.8,629.2z M407.1,682.9c4.9,0,8.8-3.5,8.8-7.8c0-10.6-18.5-10.6-18.5,0
	C397.4,676.6,398.3,682.9,407.1,682.9z M505.6,587.3c4.9,0,8.8-3.5,8.8-7.8c0-10.6-18.5-10.6-18.5,0
	C495.9,581.1,496.8,587.3,505.6,587.3z M619.6,509.8c4.9,0,8.8-3.5,8.8-7.8c0-10.6-18.5-10.6-18.5,0
	C609.8,503.1,610.7,509.8,619.6,509.8z M228.7,816c4.9,0,8.8-3.5,8.8-7.8c0-10.6-18.5-10.6-18.5,0C219,809.4,219.9,816,228.7,816z
	 M967.1,790.6c-6.6,27.8-61.4,72.1-427.9,166.4c-11.5,2-282.2,74-401.9,74c-36.7,0-52.6-6.7-60.9-14.5c-6.6-6.3-9.7-13.7-9.3-21.9
	c-4-69.7,142.6-244.3,256.6-345C447.3,540,717.2,360.2,746.3,443.2c9.3,27-14.1,89.3-283.5,329.7c-5.3,4.7-27.8,24.3-37.5,32.9
	c16.8-5.1,37.5-11,59.6-16.4C650.5,747.1,987.4,704.8,967.1,790.6z M200.9,885.7c0.9,2,0.4,3.5,0,5.5
	c55.2-26.2,120.6-51.7,184.2-72.4c81.3-69.3,436.8-381,323.7-381c-28.7,0-79.5,21.5-136.9,54c-8.4,21.5-8.8,45-0.9,66.6
	c1.8,5.1-2.6,10.2-8.4,10.2c-3.5,0-7.1-2-8.4-5.5c-6.6-18.8-7.9-38.8-4-58.3c-24.7,14.9-50.3,31.3-76,48.9
	c-14.6,26.6-14.6,59.9,0,89.3c2.6,5.1-1.8,11-7.9,11c-3.5,0-6.6-1.6-7.9-4.7c-12.4-25.1-15.5-52.5-9.3-77.5
	c-24.3,17.2-47.7,35.6-70.2,53.6c-15.9,29.4-15,68.9,1.8,100.6c2.6,5.1-1.8,11.4-7.9,11.4c-3.5,0-6.6-1.6-7.9-4.3
	c-14.1-27-18.5-57.6-12.4-85.4c-34.4,29.4-67.6,61.9-68,63c-11.5,30.2-9.7,66.2,5.3,96.7c2.6,5.1-1.8,11-7.9,11
	c-3.5,0-6.6-2-7.9-4.7C261.8,789,257.4,761.2,261,735c-22.5,23.5-44.6,47.8-64.9,72.1C189.4,832.5,191.6,860.3,200.9,885.7z
	 M93.6,955.4c22.1-19.2,56.5-39.5,97.2-59.5c-3.1-0.4-5.7-2.3-6.6-5.1c-7.1-18.8-10.2-39.2-9.7-59.1
	C137.3,878.3,107.7,922.1,93.6,955.4z M944.6,774.1c-28.3-23.1-130.3-18-168.7-15.7c-12.4,17.2-21.2,36.8-25.6,56.8
	c-0.9,3.9-4.4,6.3-8.8,6.3c-5.7,0-9.7-4.3-8.8-9.4c4-18,11-36,21.2-52.5c-42.8,3.5-88.8,9.4-134.7,17.2
	c-19,22.3-31.8,50.5-36.7,80.3c-0.4,3.9-4.4,6.7-8.8,6.7c-5.3,0-9.7-4.3-8.8-9c4.4-26.6,14.6-52.1,29.6-74
	C538.3,791,492.8,801.5,450,814.1c-18.5,23.5-30.9,53.6-34.9,85.4c-0.4,4.3-4.9,7.4-9.7,7c-4.9-0.4-8.4-4.3-7.9-8.6
	c3.5-27,12.8-53.3,26.5-75.6c-46.4,14.1-94.1,30.9-138.7,48.9c-19,20.4-32.2,45.8-38,71.7c-0.9,4.3-5.7,7-10.2,6.3
	c-4.9-0.8-7.9-4.7-7.1-9c4-19.6,12.4-38.8,23.4-56C163.4,922.9,92.7,964,83.9,992.2c-20.3,67,374.1-32.1,448.7-50.5
	C608.1,922.5,1002,821.5,944.6,774.1z M316.2,892.4c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0
	C326.3,899,325.4,892.4,316.2,892.4z M197.4,926.8c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0
	C207.1,933.1,206.2,926.8,197.4,926.8z M364.3,861.4c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0
	C374,867.7,373.1,861.4,364.3,861.4z M471.6,828.6c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0
	C481.3,834.8,480.4,828.6,471.6,828.6z M523.7,854c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0C533.4,860.7,533,854,523.7,854z
	 M650,800.4c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0C660.2,806.6,659.3,800.4,650,800.4z M822.3,774.5
	c-4.9,0-8.8,3.5-8.8,7.8c0,10.6,18.5,10.6,18.5,0C832,781.2,831.1,774.5,822.3,774.5z"
      />
      <path
        className="st0"
        d="M864.3,574.1c0,10.6-18.9,10.6-18.9,0C845.4,563.5,864.3,563.5,864.3,574.1z M888.6,598.1c-4.9,0-9,3.5-9,7.9
	c0,10.6,18.9,10.6,18.9,0C898.4,604.4,897.5,598.1,888.6,598.1z M1826,239.9c0,1.6,3.1-16.9-46.3,269c-0.4,3.5-3.6,5.9-7.6,6.7
	c-352.9,48.7-336.7,47.9-342.6,44.8c-1.8-1.2-192.9-120.6-203.2-127.2c-2.2-1.2-3.6-3.5-3.6-5.9c-26.5-320.8-26.1-302.8-23.8-305.9
	c16.2-22,54-42.4,101.2-58.1c0.9-0.4,1.3-0.8,2.2-0.8c59.3-19.2,125.4-29.5,197.8-27.1c2.2-0.4,3.6,0,4.9,0
	C1691.5,42.8,1810.7,118.2,1826,239.9z M1759.4,139.8c16.6,28.3,21.6,58.5,21.1,63.2l-0.4,4.3c8.5,3.9,16.6,8.6,24.7,13.7
	C1796.3,188.1,1780.6,161.4,1759.4,139.8z M1662.3,80.5c51.7,47.1,62.5,98.2,64.3,108.4c12.1,3.1,24.3,6.7,35.5,11
	c-6.7-48.3-37.3-82.5-62.5-102.9C1687.5,90.3,1674.9,84.8,1662.3,80.5z M1572.8,58.1c46.8,24,86.8,65.2,98.9,121.7
	c12.1,1.2,23.8,2.7,35.5,4.7c-4.9-20-24.3-75.4-89.9-117.8C1602.1,63.2,1587.2,60.1,1572.8,58.1z M1489.2,50.6
	c44.1,19.6,111.9,60.5,123.2,128.4c13.5-0.8,27.4-0.8,40.9-0.4c-18-73-86.3-116.2-149.3-127.6C1498.7,50.6,1493.7,50.6,1489.2,50.6z
	 M1412.8,54.2c48.6,12.2,114.6,44.8,144.3,131.2c12.1-2,24.7-3.9,37.3-5.1c-15.3-86.4-138-125.7-148.4-129.2
	C1434.8,51.8,1424,52.6,1412.8,54.2z M1338.2,67.9c49.9,12.2,140.3,45.6,157.8,131.9c13.9-4.7,28.3-8.2,43.2-11.8
	C1499.1,73,1388.1,62.8,1362,62C1353.9,64,1346.2,66,1338.2,67.9z M1425.8,236.8c-21.1-88.8-123.6-155.9-210-107.6l24.3,293
	l185.7,116.2L1425.8,236.8L1425.8,236.8z M1440.7,223c12.1-6.3,24.7-12.2,38.2-17.3c-18.4-104.9-153.3-125.7-173.5-128.4
	c-19.3,6.7-36.9,13.7-51.3,21.6C1339,85.6,1417.7,149.2,1440.7,223z M1479.8,223c-13,5.1-23.8,10.6-36,17.3v304.7l28.8-3.9
	L1479.8,223z M1540.9,204.2c-15.7,3.5-29.2,7.5-43.2,12.2l-7.2,322.4l36.4-5.1L1540.9,204.2z M1576.4,526.6l18.9-331
	c-11.7,1.2-22,2.4-36,5.1l-13.9,330.7L1576.4,526.6z M1625.9,519.9l28.8-325.9c-15.7-0.8-28.3-0.4-41.4,0.4l-18.9,329.9
	L1625.9,519.9z M1672.6,513.3l35.1-313c-11.2-2.4-21.6-3.9-35.5-5.1l-28.3,322L1672.6,513.3z M1724.8,506.2l36-289.8
	c-11.7-5.1-23.4-8.6-35.5-11.8l-34.2,306.3L1724.8,506.2z M1807.5,243.9c-8.5-7.1-17.5-13-29.7-19.2l-34.6,279.2l19.8-2.7
	L1807.5,243.9z M1268,144.9c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0C1277.9,151.2,1277,144.9,1268,144.9z M1259.5,203.8
	c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0C1269.4,210.1,1268.5,203.8,1259.5,203.8z M1347.6,214.4c-4.9,0-9,3.5-9,7.9
	c0,10.6,18.9,10.6,18.9,0C1357.5,220.7,1356.6,214.4,1347.6,214.4z M1315.2,271c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0
	C1325.6,277.2,1324.7,271,1315.2,271z M1370.1,332.6c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0
	C1380,339.3,1379.1,332.6,1370.1,332.6z M1369.6,583.9c0,2.4,0-8.2,0,38.5c0,3.5-3.1,7.1-7.2,7.9L1117,677.4
	c-62.9,20-97.1,30.6-96.7,30.6c-4,1.2-24.3,2-32.8,2c-83.2,0-214-38.5-234.2-104.9c-6.3-20.8,1.3-42,15.3-55.4
	c3.1-3.1-3.6-1.2,108.8-18.1c-8.1-18.1-11.2-35.7-8.1-52.6c4.9-28.3,27.4-45.2,37.3-45.2c1.8,0-22-0.8,285,9
	c4.9,0-8.1-9,174.9,135.9C1368.7,579.6,1369.6,582,1369.6,583.9z M1007.2,693.5l-4.5-22.8C844.9,664.4,791,602,773.9,572.2
	c-4.5,9.4-5.4,18.8-2.7,28.7c8.5,27.5,45.4,54.6,98.9,73C916.4,689.2,965.4,696.6,1007.2,693.5z M1025.2,649.5
	c-54.9-22.8-111-56.5-139.4-103.7l-98,14.9c11.2,23.2,58,89.1,220.8,94.2L1025.2,649.5z M1085.9,670.3c-12.6-3.5-25.2-7.5-37.3-11.8
	l-28.3,9.4l4.5,22L1085.9,670.3z M1105.7,633.4c-177.6-56.5-205.5-138.6-208.2-174.8c-4.9,5.9-8.5,13.4-9.9,22.4
	c-5.4,30.6,13.5,67.9,50.4,99.4c39.6,33.8,98.9,61.7,167.7,79.3V633.4z M1341.7,579.6L1188,458.3l-272.9-8.6
	c0,10.2,1.8,31,17.1,55.8c20.2,32.6,68.3,78.9,182.5,114.7L1341.7,579.6z M1351.6,593.8l-228.4,40.4v25.5l228.4-44V593.8z
	 M1167.8,483c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0C1177.6,489.3,1176.7,483,1167.8,483z M1099.9,509.7c-4.9,0-9,3.5-9,7.9
	c0,10.6,18.9,10.6,18.9,0C1110.2,516,1109.3,509.7,1099.9,509.7z M1242.8,562c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0
	C1252.7,568.2,1251.8,562,1242.8,562z M1188,535.6c-4.9,0-9,3.5-9,7.9c0,10.6,18.9,10.6,18.9,0C1197.9,542.3,1197,535.6,1188,535.6z
	"
      />
      <path
        className="st0"
        d="M915.7,106C878,46.9,768,23.4,690.3,23c-13.7,0.4-23.2,4.1-33.9,10.1c-13.3,7.1-21.9,10.8-51.5,1.9
	c-7.7-2.2-15.9-4.9-25.3-4.1c-7.7-0.4-79.9,15.7-93.6,20.9c-25.3,10.1-26.2,28.8-51.1,38.1c-26.2,9.7-42.1-3-61,6
	c-31.4,15-62.3,35.1-61,34.4c-21,13.5-34.8,48.6-55,60.6c-21.5,13.1-38.7,11.6-49.8,22.8C64.9,357.3,48.6,539,209.2,539
	C317.9,539,488,460.1,585,413c129.3-62.4,215.6-112.9,271-158.9C923.9,197.6,943.7,149.4,915.7,106z M588.5,46.2
	c3.4,0.7,6.9,1.5,10.7,3c52.4,15.3,59.7-4.5,81.2-9.7c-11.2,75.2-55.8,163-93.2,151.4C556.7,181.2,593.6,111.6,588.5,46.2z
	 M441.6,103.4c25.3-9.3,29.2-23.6,42.9-33.3c21,72.9-18.5,209-57.1,191.5c-24.1-11.2-14.2-105.4-37.4-154.1
	C402.1,107.1,418,112.4,441.6,103.4z M237.1,216.3c46-13.5,50.3-33.3,73-61.7c6,162.7-25.8,188.1-39.9,191.5
	c-36.5,8.6-27.5-87.5-44.7-125.6C228.6,218.9,232.4,217.8,237.1,216.3z M844,243.2c-53.7,44.5-140.9,95.7-267.6,156.7
	c-126.3,61-222.5,98.3-294.2,114.4c-82.5,18.3-133.6,9.7-156.8-26.9c-36.5-57.6-0.4-163,85.9-254.6c13.3,41.9,4.3,141.3,62.7,127.9
	c41.7-9.7,58.8-81.9,52-221c15.5-9.7,31.4-18.7,47.7-26.9c24.1,51.6,7.7,144.3,45.1,161.9c65.3,30.3,105.2-135,80.7-211.3
	c23.6-6.7,47.7-12.3,71.3-16.1c5.6,68.8-39.9,142.1,10.3,157.8c11.6,3.7,23.6,1.9,35.6-4.5c39.9-22.1,72.2-99.5,80.7-162.3
	c96.6,3.4,175.7,32.5,202.7,74.8C924.3,149.8,905.4,192.4,844,243.2z M219.5,432.8c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4
	c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C211.8,428,217,429.1,219.5,432.8z M189,349.8c2.1,3.7,0.9,8.2-3.4,10.1
	l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C181.7,345,186.9,346.1,189,349.8z M372,331.9
	c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C364.7,326.6,369.9,328.1,372,331.9z
	 M558,244.7c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4
	C550.7,239.9,555.8,241,558,244.7z M771.9,189.8c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3
	c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C764.6,184.9,769.7,186,771.9,189.8z M743.1,118.4c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4
	c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C735.8,113.5,740.9,115,743.1,118.4z M835.9,101.2
	c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4C828.6,96.3,833.7,97.4,835.9,101.2z
	 M321.8,415.6c2.1,3.7,0.9,8.2-3.4,10.1l-1.3,0.4c-4.3,1.9-9.4,0.7-11.6-3c-2.1-3.7-0.9-8.2,3.4-10.1l1.3-0.4
	C314,410.8,319.2,412.3,321.8,415.6z"
      />
      <path
        className="st0"
        d="M1655.7,917.1c9.6-33.9-5.5-73.1-36.5-94.2c-11.9-38.3-54.7-76.7-99-89c-27.4-47.1-126.8-42.7-116.8-42.7
	c-57.9,0-101.7,16-116.8,42.7c-44.3,12.4-87.1,51.1-99,89c-30.6,21.2-45.6,60.3-36.5,94.2c-4.6,12.8-26.9,81.1,8.7,92.6
	c28.3,9.2,58.8-29.5,73-53.1c24.6-9.2,38.3-22.4,52.9-38.3c20.5-3.2,42-11.6,60.7-23.2c32.8,10.8,79.8,10.8,113.1,0
	c18.7,11.6,40.1,20,60.7,23.2c14.6,16,28.7,29.1,52.9,38.3c14.1,23.6,44.7,62.3,73,53.1C1682.6,998.6,1660.2,930.3,1655.7,917.1z
	 M1159.8,927.1c17.8,14.8,40.1,26,60.2,29.9C1174.8,1024.9,1133.3,1014.5,1159.8,927.1z M1228.2,947.9c-21.4-2.4-47-15.2-65.7-32.7
	c-7.8-28.8,4.1-62.3,29.2-81.5c27.4,36.7,53.8,62.7,83.5,81.5C1261.5,929.1,1249.2,939.9,1228.2,947.9z M1284.3,908.7
	c-30.1-18.4-57.5-45.1-85.3-83.5c10.5-33.1,48.4-67.9,87.1-80.3c6.8,41.9,28.7,91.4,53.4,143
	C1321.7,898.3,1302.6,905.5,1284.3,908.7z M1456.3,885.6c-30.6,10-75.7,10-105.8,0c-25.5-53.1-48.4-104.2-53.8-146.5
	c28.3-50.3,185.7-50.3,214,0C1504.7,781.4,1481.9,832.5,1456.3,885.6z M1467.7,888c24.6-51.5,46.5-100.6,53.4-143
	c38.8,12.4,76.2,47.1,87.1,80.3c-27.8,38.3-55.2,65.1-85.3,83.5C1504.2,905.5,1485.1,898.3,1467.7,888z M1532.5,915.1
	c29.2-18.8,56.1-44.7,83.5-81.5c24.6,19.2,37,53.1,29.2,81.5c-18.7,17.6-44.3,30.3-65.7,32.7
	C1557.6,939.9,1545.7,929.1,1532.5,915.1z M1586.8,957c20.1-4,42.4-14.8,60.2-29.9C1673.5,1014.1,1632.4,1025.3,1586.8,957z
	 M1349.1,756.2c0,6.8-12.3,6.8-12.3,0C1336.8,749.4,1349.1,749.4,1349.1,756.2z M1403.4,738.2c-8.7,0-8.7-10,0-10
	c3.2,0,5.9,2.4,5.9,5.2C1409.3,735.8,1406.6,738.2,1403.4,738.2z M1396.6,781c0,6.8-12.3,6.8-12.3,0
	C1384.2,774.2,1396.6,774.2,1396.6,781z M1436.2,762.2c0,6.8-12.3,6.8-12.3,0C1423.9,755.4,1436.2,755.4,1436.2,762.2z M1486,756.2
	c0,6.8-12.3,6.8-12.3,0C1473.6,749.4,1486,749.4,1486,756.2z M1459.5,792.1c0,6.8-12.3,6.8-12.3,0
	C1447.2,785.3,1459.5,785.3,1459.5,792.1z M1364.6,814.1c0,6.8-12.3,6.8-12.3,0C1352.3,807.3,1364.6,807.3,1364.6,814.1z
	 M1416.6,826.1c0,6.8-12.3,6.8-12.3,0C1404.8,819.3,1416.6,819.3,1416.6,826.1z M1279.8,843.2c0,6.8-12.3,6.8-12.3,0
	C1267.4,836.1,1279.8,836.5,1279.8,843.2z M1187.6,870c-8.7,0-8.7-10,0-10c3.2,0,5.9,2.4,5.9,5.2
	C1193.5,867.6,1190.8,870,1187.6,870z M1542.1,787.7c0,6.8-12.3,6.8-12.3,0C1530.2,781,1542.1,781,1542.1,787.7z M1556.7,836.9
	c0,6.8-12.3,6.8-12.3,0C1544.4,830.1,1556.7,830.1,1556.7,836.9z M1620.5,876.8c0,6.8-12.3,6.8-12.3,0
	C1608.2,870,1620.5,870,1620.5,876.8z M1267,787.7c0-6.8,12.3-6.8,12.3,0C1279.3,794.5,1267,794.5,1267,787.7z M1248.7,815.3
	c0,6.8-12.3,6.8-12.3,0C1236.9,808.5,1248.7,808.5,1248.7,815.3z M1203.1,894c0,6.8-12.3,6.8-12.3,0
	C1190.8,887.2,1203.1,887.2,1203.1,894z"
      />
    </svg>
  );
}

export default BreadIcon;
