import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props {
  title?: string;
  children?: ReactNode;
  width?: string;
}
function NavigateBackHeader({ title, children, width }: Props) {
  const navigate = useNavigate();

  return (
    <div>
      <NavBar>
        <NavItemContainer width={width}>
          <NavItem>
            <Header>
              <i
                onClick={(e) => {
                  window.history.back();
                }}
                className="fa-solid fa-chevron-left"
              />
              {children ? children : title}
            </Header>
          </NavItem>
          <NavLine />
        </NavItemContainer>
      </NavBar>
    </div>
  );
}

export default NavigateBackHeader;

const NavBar = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 16px;
`;

interface NavItemContainerProps {
  width?: string;
}

const NavItemContainer = styled.div<NavItemContainerProps>`
  justify-items: center;
  width: ${({ width }) => (width ? width : "296px")};

  @media (min-width: 1400px) and (min-height: 800px) {
    width: ${({ width }) => (width ? +width * 1.5 : "440px")};
  }
`;

const NavItem = styled.div`
  font-weight: bold;
  color: black;
`;

const Header = styled.h1`
  text-align: center;
  margin: 0px;
  margin-bottom: 4px;

  i {
    font-size: 24px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

const NavLine = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background-color: var(--color-dark-green);
`;
