import { Pie } from "react-chartjs-2";
import styled from "styled-components";

interface Props {
  chilledCount: number;
  frozenCount: number;
}

function CategoryTypeChart({ chilledCount, frozenCount }: Props) {
  const categoryTypeData = {
    labels: ["Kylt", "Fryst"],
    datasets: [
      {
        data: [chilledCount, frozenCount],
        backgroundColor: ["#2e6053", "#2e605360"],
      },
    ],
  };

  return (
    <Container>
      <Pie
        data={categoryTypeData}
        options={{ plugins: { legend: { display: false } } }}
      />
    </Container>
  );
}

export default CategoryTypeChart;

const Container = styled.div`
  @media (min-width: 1400px) and (min-height: 800px) {
    width: 420px;
    height: 420px;
  }
`;
