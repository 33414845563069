import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGenerateDonationPdfMutation,
  useGetTransportQuery,
  useSaveDonationMutation,
} from "store/api";
import {
  getProductsForDonation,
  reset,
  removeProductForDonation,
} from "store/productsForDonation";
import styled from "styled-components";
import { Button } from "styledComponents";
import { Donation } from "types";
import Delete from "./common/Delete";
import NavigateBackHeader from "./common/NavigateBackHeader";

interface FormData {
  address: string;
  note: string;
  contactDetails: string;
  product: {
    [measurementResultId: string]: {
      volume: string;
      articleNumber: string;
      parcelQuantity: string;
    };
  };
}

function DonationForm() {
  const { transportId } = useParams();
  const { data: transport } = useGetTransportQuery(transportId!);
  const productsForDonation = useSelector(getProductsForDonation);
  const [generateDonationPdf, { isLoading: isGeneratingDonationPdf }] =
    useGenerateDonationPdfMutation();
  const [saveDonation, { isLoading: isSavingDonation }] =
    useSaveDonationMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filteredProducts = productsForDonation.filter((p) => !p.donationId);

  async function onSubmit(data: FormData) {
    if (!transport) return;
    const donation: Donation = {
      note: data.note,
      address: data.address,
      contactDetails: data.contactDetails,
      products: filteredProducts.map((p) => ({
        ...p,
        volume: +data.product[p.id!].volume,
        articleNumber: data.product[p.id!].articleNumber,
        parcelQuantity: +data.product[p.id!].parcelQuantity,
        type: transport.type,
      })),
      duration: transport.deviation.duration,
      measurementResultIds: filteredProducts.map((p) => p.id!),
      isSavedBfd: transport.isSavedBfd,
      type: transport.type,
      donationType: "donation",
    };

    try {
      Promise.all([
        await generateDonationPdf(donation).unwrap(),
        await saveDonation(donation).unwrap(),
      ]);
      dispatch(reset());
      navigate(`/donation/new?status=done&id=${transportId}`);
    } catch (error: any) {
      toast.error(error.data);
    }
  }

  function onSubmitError() {
    toast.error("Fyll i alla fält för att gå vidare!");
  }

  return (
    <Container>
      <NavigateBackHeader title="Donera" />

      <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <Field
          text="Ange var godset befinner sig (adress, postnr och ort)"
          error={!!errors.address}
        >
          <CommonInput {...register("address", { required: true })} />
        </Field>
        <Field text="Kontaktuppgifter (vid frågor samt för att synka donationen)">
          <CommonInput {...register("contactDetails")} />
        </Field>
        <Field style={{ height: 192 }} text="Övriga upplysningar">
          <CommonInput style={{ height: 164 }} {...register("note")} />
        </Field>
        <Table>
          <thead>
            <TableHeader>
              <th>Produkt</th>
              <th>Förpackning</th>
              <th>Tillstånd</th>
              <th>Artikelnummer</th>
              <th>Kolliantal</th>
              <th>Vikt (kg)</th>
            </TableHeader>
          </thead>
          <tbody>
            {filteredProducts.map((p) => (
              <TableRow key={p.id}>
                <td>{p.product?.name}</td>
                <td>{p.product?.packaging}</td>
                <td>{p.product?.isFrozen ? "Fryst" : "Kylt"}</td>
                <td>
                  <Input
                    error={!!errors.product?.[p.id as string]?.articleNumber}
                    {...register(`product.${p.id}.articleNumber`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <Input
                    error={!!errors.product?.[p.id as string]?.parcelQuantity}
                    {...register(`product.${p.id}.parcelQuantity`, {
                      required: true,
                    })}
                  />
                </td>
                <td>
                  <Input
                    error={!!errors.product?.[p.id as string]?.volume}
                    {...register(`product.${p.id}.volume`, {
                      required: true,
                    })}
                  />
                </td>

                <td>
                  <Remove
                    onDelete={() => dispatch(removeProductForDonation(p))}
                  />
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <StyledButton
          disabled={
            isGeneratingDonationPdf ||
            isSavingDonation ||
            filteredProducts.length < 1
          }
        >
          Donera <span className="spinner" />
        </StyledButton>
      </Form>
    </Container>
  );
}

export default DonationForm;

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

interface InnerInputLabel {
  text?: string;
}

const StyledButton = styled(Button)`
  margin-top: 24px;
  align-self: end;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-top: 32px;
  }
`;

const Field = styled.div<InnerInputLabel & ErrorProps>`
  display: flex;
  position: relative;
  height: 56px;
  border: ${({ error }) =>
    error ? "2px solid red" : "2px solid var(--color-dark-green)"};
  border-radius: 8px;
  padding: 24px 16px 8px 4px;
  margin-bottom: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 84px;
    border: ${({ error }) =>
      error ? "3px solid red" : "3px solid var(--color-dark-green)"};
    border-radius: 12px;
    padding: 24px 24px 24px 3px;
    margin-bottom: 24px;

    ::after {
      top: 3px;
      left: 6px;
    }
  }

  ::after {
    position: absolute;
    content: "${({ text }) => text}";
    top: 2px;
    left: 4px;
    color: var(--color-medium-grey);
  }
`;

const CommonInput = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  outline: none;

  @media (min-width: 1400px) and (min-height: 800px) {
  }
`;

const Table = styled.table`
  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  padding: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  .name {
    display: flex;
    align-items: center;
  }

  .center {
    justify-self: center;
  }
`;

const Row = styled.tr`
  display: grid;
  grid-template-columns: 4fr repeat(5, 2fr) 0.5fr;
  gap: 24px;
  align-items: center;
  justify-items: start;
  padding: 16px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 72px;
    padding: 24px 0;
  }
`;

const TableHeader = styled(Row)`
  padding: 8px 0 0 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 0 0 0;
  }
`;

interface ErrorProps {
  error?: boolean;
}

const Input = styled.input<ErrorProps>`
  outline: none;
  border: none;
  padding: 24px 8px 16px;
  height: 32px;
  border-radius: 4px;
  width: 96px;
  border-bottom: ${({ error }) =>
    error ? "2px solid red" : "2px solid var(--color-dark-green)"};

  &:focus {
    border-color: var(--color-light-green);
  }
`;

const TableRow = styled(Row)`
  border-radius: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border-radius: 6px;
  }

  &:hover {
    background-color: var(--color-light-grey);
  }
`;

const Remove = styled(Delete)`
  font-size: 18px;
  margin-top: 18px;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 24px;
    margin-top: 24px;
  }
`;
