import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "styledComponents";

interface FallbackProps {
  retry(): void;
}

function AppFallbackPage({ retry }: FallbackProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/products");
    retry();
  };

  return (
    <StyledPageContainer>
      <ConfirmPageContainer>
        <Circle className="fa-sharp fa-solid fa-circle-xmark" />
        <Heading>Ett fel har uppstått!</Heading>
        <Text>
          Tryck på knappen nedan för att omdirigeras till startsidan. <br /> Var
          vänlig kontakta SFW gällande problemet.
        </Text>
        <ToStartPageButton onClick={handleClick}>
          Tillbaka till startsida
        </ToStartPageButton>
      </ConfirmPageContainer>
    </StyledPageContainer>
  );
}

export default AppFallbackPage;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 80px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 32px 120px;
  }
`;

const ConfirmPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 72px;
  width: 800px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 108px;
    width: 1200px;
  }
`;

const Heading = styled.h1`
  color: #bb442f;
`;

const Text = styled.h2`
  color: #bb442f;
  font-weight: normal;
  text-align: center;
  margin: 0;
`;

const Circle = styled.i`
  font-size: 136px;
  color: #bb442f;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 204px;
  }
`;

const ToStartPageButton = styled(Button)`
  margin: 32px;
  width: auto;
  padding: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 72px;
  }
`;
