import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  useGetTransportQuery,
  useUpdateTransportMutation,
  useGenerateReportPdfMutation,
} from "store/api";
import { SaveBfdToggle, DownloadPdf } from "components";
import { Button } from "styledComponents";
import PackageResultList from "./PackageResultList";
import { PackageType, Report, Transport } from "types";
import PalletResultList from "./PalletResultList";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { submitAllProductsForDonation } from "store/productsForDonation";
import { formatDate } from "utils";

interface Props {
  donationTransportId?: string;
}

function ResultsPage({ donationTransportId }: Props) {
  const navigate = useNavigate();
  const { transportId } = useParams();
  const location = useLocation();
  const { data: transport, refetch } = useGetTransportQuery(
    donationTransportId || transportId!
  );
  const [generateReportPdf, { isLoading: isGeneratingReportPdf }] =
    useGenerateReportPdfMutation();
  const [updateTransport] = useUpdateTransportMutation();
  const [saveBfd, setSaveBfd] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!transport) return;
    setSaveBfd(transport.isSavedBfd);

    //TODO: Do the update of transports with invalidTags in redux API instead
    //Re-prefetching
    refetch();
  }, [transport]);

  useEffect(() => {
    location.pathname.includes("result") &&
      toast.info(
        "Du kan rädda ännu fler bäst-före dagar. Tryck på knappen nedan."
      );
  }, []);

  async function handleSavedBfdToggle() {
    if (!transport || donationTransportId) return;

    setSaveBfd(!saveBfd);

    updateTransport({ ...transport, isSavedBfd: !saveBfd });
  }

  function donateProducts(transport: Transport) {
    dispatch(submitAllProductsForDonation(transport.measurementResult));
    navigate(`/donation/new/${transportId}/form`);
  }

  async function requestReport(transport: Transport) {
    if (!transport) return;

    const report: Report = {
      transport: transport,
    };

    try {
      await generateReportPdf(report).unwrap();
      navigate(`/ReportConfirmPage`);
    } catch (error) {
      toast.error("Det gick inte att begära rapport. Kontakta SFW.");
    }
  }

  if (!transport) return null;

  return (
    <Container>
      {transport.type === PackageType.PACKAGE ? (
        <PackageResultList transport={transport} />
      ) : (
        <PalletResultList transport={transport} />
      )}

      <Footer>
        <CommonActionMenuContainer>
          {!donationTransportId && (
            <StyledButton
              variant="secondary"
              onClick={() => requestReport(transport)}
              disabled={isGeneratingReportPdf}
            >
              Rapport <span className="spinner" />
            </StyledButton>
          )}
        </CommonActionMenuContainer>
        <CommonActionMenuContainer>
          <SaveBfdToggle
            disabled={!location.pathname.includes("result")}
            onToggle={() => handleSavedBfdToggle()}
            show={saveBfd}
          />
        </CommonActionMenuContainer>
        <CommonActionMenuContainer>
          {donationTransportId ? (
            <StyledButton
              variant="secondary"
              onClick={() => donateProducts(transport)}
            >
              Gå till formuläret
            </StyledButton>
          ) : (
            <StyledPDFDownloadLink
              document={
                <DownloadPdf
                  download={{
                    transport: transport,
                  }}
                />
              }
              fileName={`SFWresultat ${formatDate(new Date())}.pdf`}
            >
              {({ loading, error }) => {
                if (error) {
                  return <div>{error.message}</div>;
                }

                return (
                  <StyledButton variant="secondary">
                    {loading ? "Laddar ner..." : "Ladda ned PDF"}
                  </StyledButton>
                );
              }}
            </StyledPDFDownloadLink>
          )}
        </CommonActionMenuContainer>
      </Footer>
    </Container>
  );
}

export default ResultsPage;

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 124px;
  height: 32px;

  font-size: 15px;
`;

const StyledPDFDownloadLink = styled(PDFDownloadLink)`
  text-decoration: none;
  color: var(--color-dark-green);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 -2px 8px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  padding: 0 6vw;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 11.3vh;
  z-index: 100;
`;

const CommonActionMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
