import { MouseEvent, useState } from "react";
import styled from "styled-components";
import InfoCircle from "./common/InfoCircle";

interface Props {
  disabled: boolean;
  show: boolean;
  onToggle: (e: MouseEvent) => void;
}

function SaveBfdToggle({ show, disabled, onToggle }: Props) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Container>
      <ToggleContainer>
        <label>
          {show
            ? "Fler bäst-före dagar har räddats"
            : "Rädda fler bäst-före dagar"}
        </label>
        <Toggle
          show={show}
          disabled={disabled}
          onClick={disabled ? undefined : onToggle}
        >
          <input type="checkbox" />
          <i className={`fa-solid fa-circle-${show ? "check" : "xmark"}`} />
        </Toggle>
      </ToggleContainer>
      <InfoCircle
        show={showInfo}
        onToggle={() => setShowInfo((showInfo) => !showInfo)}
      >
        <Text>
          Genom att skyndsamt få in produkten i den märkta
          förvaringstemperaturen enligt förpackning minskar påverkan på
          hållbarhetstiden.
        </Text>
      </InfoCircle>
    </Container>
  );
}

export default SaveBfdToggle;

interface ToggleProps {
  disabled?: boolean;
  show?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 6px;
  }
`;

const Text = styled.p`
  width: 34ch;
  text-align: left;
`;

const Toggle = styled.div<ToggleProps>`
  input {
    appearance: none;
    outline: none;
    width: 64px;
    height: 25px;
    background-color: ${({ show }) =>
      show ? "var(--color-light-green)" : "var(--color-light-red)"};
    border-radius: 50px;
    transition: all 0.2s ease-out;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

    @media (min-width: 1400px) and (min-height: 800px) {
      width: 120px;
      height: 48px;
      border-radius: 75px;
    }
  }

  i {
    position: absolute;
    font-size: 21px;
    top: 2px;
    left: ${({ show }) => (show ? "40px" : "3px")};
    color: ${({ show }) =>
      show ? "var(--color-dark-green)" : "var(--color-dark-red)"};
    border-radius: 50%;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

    @media (min-width: 1400px) and (min-height: 800px) {
      font-size: 40px;
      top: 4px;
      left: ${({ show }) => (show ? "75px" : "6px")};
    }
  }
`;

const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 6px;
  }

  div {
    position: relative;
  }

  label {
    font-weight: bold;
  }
`;
