import styled, { css } from "styled-components";

export const Arrow = styled.i`
  font-size: 32px;
  color: white;
  cursor: pointer;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 48px;
  }
`;

export const PaginationButton = styled.button`
  border: none;
  background: none;
  background-color: var(--color-dark-green);
  padding: 4px 24px;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 6px 36px;
    border-radius: 12px;
  }
`;

interface ButtonProps {
  disabled?: boolean;
  variant?: "primary" | "secondary";
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: var(--color-light-green);
  color: var(--color-dark-green);
  cursor: pointer;
  font-weight: bold;
  transform: scale(1);

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 192px;
    height: 72px;
    padding: 24px;
    border-radius: 12px;
  }

  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  transition: opacity, transform 0.3s, 0.15s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      border: 2px solid var(--color-dark-green);
      background-color: transparent;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      justify-content: space-evenly;

      .spinner {
        width: 16px;
        height: 16px;
        border: 2px solid var(--color-dark-green);
        border-bottom-color: white;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        @media (min-width: 1400px) and (min-height: 800px) {
          width: 24px;
          height: 24px;
          border-width: 3px;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    `}
`;
