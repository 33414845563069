import { Component, ErrorInfo, ReactNode } from "react";

interface State {
  hasError: boolean;
}

interface Props {
  children: ReactNode;
  fallback(retry: () => void): JSX.Element;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log("errorInfo", errorInfo);
    console.log("error", error);
  }

  retry = () => this.setState({ hasError: false });

  render() {
    const { fallback } = this.props;

    if (this.state.hasError) return fallback ? fallback(this.retry) : null;

    return this.props.children;
  }
}

export default ErrorBoundary;
