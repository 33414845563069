import styled, { css } from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import {
  sub,
  differenceInCalendarMonths,
  differenceInCalendarDays,
  eachMonthOfInterval,
  startOfDay,
  subMonths,
  startOfMonth,
  subDays,
  eachDayOfInterval,
} from "date-fns";
import { useGetTransportsQuery, useGetDonationsQuery } from "store/api";
import { formatDate } from "utils";
import {
  Transport,
  CategoryCount,
  Filter as FilterType,
  Donation,
  DbDonation,
} from "types";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedFilter, selectFilter, filters } from "store/selectedFilter";
import CategoryTypeChart from "components/CategoryTypeChart";
import { CategoryChart, NavigateBackHeader } from "components";
import Filter from "components/common/Filter";
import MesaurementChart from "components/MeasurementChart";
import { DonateIcon, SearchIcon } from "icons";
import SfwLogoIcon from "icons/SfwLogoIcon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface CategoryTypeCount {
  all: number;
  frozen: number;
  chilled: number;
}

export interface Analytics {
  categoryType: CategoryTypeCount;
  categories: CategoryCount;
  count: {
    products: number[];
    measurements: number[];
  };
}

function AnalyticsPage() {
  const { data: transports = [] } = useGetTransportsQuery();
  const { data: donations = [] } = useGetDonationsQuery();

  const selectedFilter = useSelector(getSelectedFilter);
  const dispatch = useDispatch();

  if (!selectedFilter) return <div>Analys hämtas...</div>;

  const savedBfd = transports.reduce((totalCountSavedBfd, transport) => {
    for (const mr of transport.measurementResult)
      totalCountSavedBfd += mr.data.reduce((countSavedBfd, md) => {
        if (mr.product?.isFrozen && md.input.temperature >= -2)
          return countSavedBfd;
        if (transport.isSavedBfd)
          return countSavedBfd + md.calculation.savedRescuedDays;
        return countSavedBfd + md.calculation.rescuedDays;
      }, 0);

    return totalCountSavedBfd;
  }, 0);

  const Co2Emissions = transports.reduce(
    (totalCountCo2Emissions, transport) => {
      totalCountCo2Emissions += transport.measurementResult.reduce(
        (countCo2Emissions, mr) => {
          if (mr.generalCalculation?.co2EmissionInKg)
            return countCo2Emissions + mr.generalCalculation?.co2EmissionInKg;
          else return countCo2Emissions;
        },
        0
      );

      return Math.round(totalCountCo2Emissions);
    },
    0
  );

  const analytics: Analytics = selectedFilter.getData(transports).reduce(
    (analytics: Analytics, transport: Transport) => {
      let { count, categories, categoryType } = analytics;
      let dataPointIndex = 0;

      if (selectedFilter.interval.months)
        dataPointIndex = differenceInCalendarMonths(
          new Date(transport.createdDate),
          sub(new Date(), selectedFilter.interval)
        );

      if (selectedFilter.interval.days)
        dataPointIndex = differenceInCalendarDays(
          new Date(transport.createdDate),
          sub(new Date(), selectedFilter.interval)
        );

      count.measurements[dataPointIndex]++;

      for (const mr of transport.measurementResult) {
        if (!mr.product) continue;

        if (mr.product.isFrozen) categoryType.frozen++;
        else categoryType.chilled++;
        categoryType.all++;

        if (categories[mr.product.category.id])
          categories[mr.product.category.id].count++;
        else
          categories[mr.product.category.id] = {
            name: mr.product.category.name,
            count: 1,
          };

        count.products[dataPointIndex]++;
      }

      return {
        count,
        categories,
        categoryType,
      };
    },

    {
      count: {
        measurements: new Array(
          selectedFilter.interval.days
            ? selectedFilter.interval.days
            : selectedFilter.interval.months
        ).fill(0),
        products: new Array(
          selectedFilter.interval.days
            ? selectedFilter.interval.days
            : selectedFilter.interval.months
        ).fill(0),
      },
      categories: {},
      categoryType: { all: 0, chilled: 0, frozen: 0 },
    } as Analytics
  );

  let date;
  let previousTimeUnit: Date;
  let start: Date;
  let timeInterval: Date[] = [];
  let format: string;
  let totalDonatedVolume = 0;

  donations.forEach((donation) => {
    if (donation.donationProduct) {
      donation.donationProduct.forEach((dp) => {
        totalDonatedVolume += dp.volume;
      });
    }
    if (donation.detachedDonation) {
      donation.detachedDonation.forEach((detachedDonation) => {
        totalDonatedVolume += detachedDonation.volume;
      });
    }
  });

  if (selectedFilter.interval.months) {
    date = startOfMonth(new Date());

    previousTimeUnit = subMonths(startOfMonth(date), 1);

    start = subMonths(date, selectedFilter.interval.months);

    timeInterval = eachMonthOfInterval({
      start: start,
      end: previousTimeUnit,
    });

    format = "MMMM";
  } else if (selectedFilter.interval.days) {
    date = startOfDay(new Date());

    previousTimeUnit = subDays(date, 1);

    start = subDays(date, selectedFilter.interval.days!);

    timeInterval = eachDayOfInterval({
      start: start,
      end: previousTimeUnit,
    });

    format = "EEE d MMM";
  }

  const timeUnitNames = timeInterval.map((m) => formatDate(m, format));

  const mostDeviations = Math.max(...analytics.count.measurements);
  const mostDeviationsIndex =
    analytics.count.measurements.indexOf(mostDeviations);
  const monstDeviationMonth = timeUnitNames[mostDeviationsIndex];

  const leastDeviations = Math.min(...analytics.count.measurements);
  const leastDeviationsMonthIndex =
    analytics.count.measurements.indexOf(leastDeviations);
  const leastDeviationsMonth = timeUnitNames[leastDeviationsMonthIndex];

  return (
    <div>
      <NavigateBackHeader title="Data och Analys" />
      <CardContainer>
        <Card>
          <CardIcon>
            <StyledSfwLogoIcon />
          </CardIcon>
          <CardHeading>Räddade bäst-före dagar</CardHeading>
          <CardData>{savedBfd} dagar</CardData>
        </Card>
        <Card>
          <CardIcon>
            <StyledCo2Text>CO2e</StyledCo2Text>
          </CardIcon>
          <CardHeading>Minskade utsläpp CO2e</CardHeading>
          <CardData>{Co2Emissions.toLocaleString()} kg</CardData>
        </Card>
        <Card>
          <CardIcon>
            <StyledDonateIcon />
          </CardIcon>
          <CardHeading>Antal kg donerad mat</CardHeading>
          <CardData>{totalDonatedVolume} kg</CardData>
        </Card>
      </CardContainer>
      <Container>
        <SubContainer>
          <Filter
            onSelect={(filter: FilterType) => dispatch(selectFilter(filter))}
            filters={filters}
          />
          <MesaurementChart
            measurements={analytics.count.measurements}
            products={analytics.count.products}
          />
          {!(selectedFilter.interval.days === 0) && (
            <TextWithLine position="right">
              <img src="line.svg" alt="line" />
              <Text>
                <p>
                  Flest antal avvikelser <br />
                  <strong>
                    {monstDeviationMonth} - {mostDeviations} st
                  </strong>
                </p>
                <p>
                  Minst antal avvikelser <br />
                  <strong>
                    {leastDeviationsMonth} - {leastDeviations} st
                  </strong>
                </p>
              </Text>
            </TextWithLine>
          )}
        </SubContainer>
        <SubContainer>
          <CategoryChart categories={analytics.categories} />
        </SubContainer>
        <Spacer />
        <SubContainer isCategoryChart>
          <TextWithLine position="left">
            <Text>
              <p>
                Flest avvikelser på
                <br />
                <strong>
                  {analytics.categoryType.chilled >
                  analytics.categoryType.frozen
                    ? "Kylda "
                    : "Frysta "}
                  produkter
                </strong>
              </p>
            </Text>
            <img src="line.svg" alt="line" />
          </TextWithLine>
          <CategoryTypeChart
            chilledCount={analytics.categoryType.chilled}
            frozenCount={analytics.categoryType.frozen}
          />
        </SubContainer>
      </Container>
    </div>
  );
}

export default AnalyticsPage;

interface ChartType {
  isCategoryChart?: boolean;
}
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 60px;
  }
`;

const HeaderText = styled.h1`
  display: flex;
  width: 86vw;
  margin-bottom: 24px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: 32px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  margin-top: 24px;
  justify-content: center;
  gap: 32px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: 48px;
    gap: 48px;
  }
`;

const Card = styled.span`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 40px 168px;
  border: 3px solid var(--color-dark-green);
  border-radius: 16px;
  height: 80px;
  width: 224px;
  row-gap: 8px;
  padding: 8px 0 16px 8px;

  @media (min-width: 1400px) and (min-height: 800px) {
    grid-template-columns: 60px 252px;
    border-radius: 24px;
    height: 120px;
    width: 336px;
    row-gap: 12px;
    padding: 12px 0 24px 12px;
  }
`;

const CardIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(--color-light-green);
  border-radius: 50%;
  grid-row: 1 / span 2;
  align-self: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 72px;
    height: 72px;
  }
`;

const StyledSfwLogoIcon = styled(SfwLogoIcon)`
  width: 32px;
  height: 32px;
  justify-content: center;
  fill: var(--color-dark-green);

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 48px;
    height: 48px;
  }
`;

const StyledCo2Text = styled.span`
  color: var(--color-dark-green);
  font-weight: bolder;
`;

const StyledDonateIcon = styled(DonateIcon)`
  width: 24px;
  height: 24px;
  justify-content: center;
  fill: var(--color-dark-green);

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 32px;
    height: 32px;
  }
`;

const CardHeading = styled.p`
  font-weight: bold;
  font-size: small;
  grid-row: 1;
  text-align: center;
  align-self: flex-end;
  padding-left: 8px;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding-left: 12px;
    font-size: 20px;
  }
`;

const CardData = styled.span`
  color: var(--color-dark-green);
  font-size: 24px;
  font-weight: bold;
  text-align: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 36px;
  }
`;

interface TextProps {
  position: "right" | "left";
}

const SubContainer = styled.div<ChartType>`
  position: relative;
  width: ${({ isCategoryChart }) => (isCategoryChart ? "280px" : "560px")};
  height: ${({ isCategoryChart }) => (isCategoryChart ? "280px" : "auto")};

  @media (min-width: 1400px) and (min-height: 800px) {
    width: auto;
  }
`;

const TextWithLine = styled.div<TextProps>`
  position: absolute;
  display: flex;
  gap: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 24px;
  }

  & > img {
    width: 120px;

    @media (min-width: 1400px) and (min-height: 800px) {
      width: 180px;
    }
  }

  ${({ position }) =>
    position === "left"
      ? css`
          bottom: 160px;
          left: -320px;

          @media (min-width: 1400px) and (min-height: 800px) {
            bottom: 136px;
            left: -456px;
          }

          & > img {
            transform: rotateY(180deg);
          }
        `
      : css`
          top: 80px;
          right: -320px;

          @media (min-width: 1400px) and (min-height: 800px) {
            top: 120px;
            right: -480px;
          }
        `}
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 24px;
  }

  strong {
    font-size: 24px;

    @media (min-width: 1400px) and (min-height: 800px) {
      font-size: 36px;
    }
  }
`;

const Spacer = styled.div`
  margin: 8px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 12px 0;
  }
`;
