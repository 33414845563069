import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import HistoryTable from "components/HistoryTable";
import ResultsPage from "./ResultsPage";
import { useSearchParams } from "hooks/useSearchParams";
import DonationConfirmPage from "./DonationConfirmPage";
import DonationLandingPage from "./DonationLandingPage";
import { NavigateBackHeader } from "components";

function DonationPage() {
  const { transportId } = useParams();
  const [showInfo, setShowInfo] = useState(false);
  const { stage, status } = useSearchParams();

  if (status === "done") return <DonationConfirmPage />;

  return (
    <>
      <HelpContainer>
        <QuestionMark
          onMouseOver={() => setShowInfo(true)}
          onMouseOut={() => setShowInfo(false)}
        >
          <i className="fa-solid fa-question" />
        </QuestionMark>
        <span>Instruktioner</span>
        <InstructionList showInfo={showInfo}>
          <Instruction>1. Välj en mätning</Instruction>
          <Instruction>2. Välj produkter</Instruction>
          <Instruction>3. Ange volym att donera</Instruction>
          <Instruction>4. Stadsmissionen återkommer</Instruction>
          <Instruction>5. Överenskommelse om godstransport</Instruction>
          <p>
            Direktkontakt med Stadsmissionen
            <br /> Jens Jonsson 070-280 77 90
          </p>
        </InstructionList>
      </HelpContainer>
      <Container>
        {stage === "donation-history" ? (
          <DonationLandingPage />
        ) : stage === "measurement-history" ? (
          <>
            <NavigateBackHeader
              width="480px"
              title="Välj avvikelse att donera"
            />
            <HistoryTable />
          </>
        ) : (
          transportId && (
            <>
              <NavigateBackHeader
                width="480px"
                title="Välj produkter att donera"
              />
              <ResultsPage donationTransportId={transportId} />
            </>
          )
        )}
      </Container>
    </>
  );
}

export default DonationPage;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface InstructionListProps {
  showInfo: boolean;
}

const HelpContainer = styled.div`
  display: flex;
  position: absolute;
  right: 24px;
  top: 8px;
  align-items: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    right: 32px;
    top: 12px;
  }
`;

const QuestionMark = styled.div`
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: white;
  background-color: var(--color-dark-green);
  margin-right: 8px;
  cursor: help;
  transform: scale(1);

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 32px;
    width: 32px;
    margin-right: 12px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const InstructionList = styled.ol<InstructionListProps>`
  position: absolute;
  z-index: 60;
  top: 24px;
  right: 0;
  color: white;
  overflow: hidden;
  width: 280px;
  background-color: var(--color-dark-green);
  border-radius: 16px;
  list-style: none;
  height: ${({ showInfo }) => (showInfo ? "280px" : 0)};
  padding: ${({ showInfo }) => (showInfo ? "16px" : 0)};

  @media (min-width: 1400px) and (min-height: 800px) {
    top: 32px;
    width: 420px;
    border-radius: 24px;
    height: ${({ showInfo }) => (showInfo ? "344px" : 0)};
    padding: ${({ showInfo }) => (showInfo ? "24px" : 0)};

    p {
      margin-top: 24px;
    }
  }

  p {
    margin-top: 16px;
  }

  i {
    color: white;
  }
`;

const Instruction = styled.li`
  padding: 8px 0;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 0;
  }
`;
