interface Props {
  className?: string;
}

function CalendarIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      id="Icons:_Calendar"
      data-name="Icons: Calendar"
      viewBox="0 0 250 250"
    >
      <path d="m45.47,215.92V56.83h36.89v-22.75h17.01v22.66h51.22v-22.64h17v22.69h36.93v159.13H45.47Zm62.5-76.69v22.72h34.1v-22.72h-34.1Zm0-36.95v19.88h34.12v-19.88h-34.12Zm0,76.78v19.86h34.11v-19.86h-34.11Zm-45.51-17.11h28.39v-22.71h-28.39v22.71Zm125.06.02v-22.72h-28.38v22.72h28.38Zm-96.66-39.79v-19.89h-28.38v19.89h28.38Zm68.28-.02h28.41v-19.87h-28.41v19.87Zm-68.29,76.78v-19.84h-28.37v19.84h28.37Zm68.29-19.87v19.86h28.4v-19.86h-28.4Z" />
    </svg>
  );
}

export default CalendarIcon;
