import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCurrentUser, logout } from "services/auth";
import { useGetNotificationQuery } from "store/api";
import styled from "styled-components";
import Icon from "./Icon";

interface Props {
  show: boolean;
  toggle: () => void;
}

function SidebarMenu({ show, toggle }: Props) {
  const user = getCurrentUser();
  const { data: notifications } = useGetNotificationQuery();
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    if (!notifications) return;

    setUnreadNotifications(notifications.unread.length);
  }, [notifications]);

  function onLogout() {
    logout();
    window.location.href = "/login";
  }

  return (
    <Sidebar show={show}>
      <MenuIconClose onClick={toggle}>
        <CloseIcon name="XMark" />
      </MenuIconClose>
      <LogoContainer>
        <Logo name="SfwLogo" />
        <p>Sustainable Food Waste</p>
      </LogoContainer>

      <MenuItemsContainer>
        <Line></Line>
        <MenuItem to="/products" onClick={toggle}>
          <FontAwesomeIcon className="fa-solid fa-magnifying-glass" />
          <ItemName>Välj produkt</ItemName>
        </MenuItem>
        <Line></Line>
        <MenuItem to="/analytics" onClick={toggle}>
          <MenuItemIcon name="Analytics" />
          <ItemName>Analys</ItemName>
        </MenuItem>
        <MenuItem to="/history" onClick={toggle}>
          <MenuItemIcon name="History" />
          <ItemName>Historik</ItemName>
        </MenuItem>
        <MenuItem
          external
          href="https://forms.office.com/e/RnEBpQyrXE"
          onClick={toggle}
        >
          <FontAwesomeIcon className="fa-solid fa-money-check-dollar" />
          <ItemName>Vidareförsäljning</ItemName>
        </MenuItem>
        <MenuItem to="/donation?stage=donation-history" onClick={toggle}>
          <MenuItemIcon name="Donate" />
          <ItemName>Donationsportal</ItemName>
        </MenuItem>
        <MenuItem to="/notifications" onClick={toggle}>
          <MenuItemIconWithBadge>
            <MenuItemIcon name="Notifications" />
            {unreadNotifications > 0 && <Badge>{unreadNotifications}</Badge>}
          </MenuItemIconWithBadge>
          <ItemName>Notiser</ItemName>
        </MenuItem>
        <MenuItem to="/feedback" onClick={toggle}>
          <FontAwesomeIcon className="fa-regular fa-comment" />
          <ItemName>Feedback</ItemName>
        </MenuItem>
        <MenuItem
          external
          href="https://sustainablefoodwaste.se/hjalpcenter"
          onClick={toggle}
        >
          <FontAwesomeIcon className="fa-regular fa-circle-question" />
          <ItemName>Hjälpcenter</ItemName>
        </MenuItem>
      </MenuItemsContainer>
      <LogoutContainer>
        <ItemName>Inloggad som</ItemName>
        <ItemName username>{user?.name}</ItemName>
        <Logout onClick={() => onLogout()}>
          <MenuItemIcon name="Logout" />
          <ItemName>Logga ut</ItemName>
        </Logout>
      </LogoutContainer>
    </Sidebar>
  );
}

export default SidebarMenu;

interface SideBar {
  show: boolean;
}

const Sidebar = styled.div<SideBar>`
  background-color: var(--color-dark-green);
  z-index: 200;
  width: 216px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${({ show }) => (show ? 0 : "-280px")};
  transition: left 0.6s;

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 420px;
    left: ${({ show }) => (show ? 0 : "-420px")};
  }
`;

const StyledIcon = styled(Icon)`
  fill: white;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }
`;

const MenuIconClose = styled.button`
  position: absolute;
  right: 0;
  margin: 14px 8px;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 12px;
  }
`;

const CloseIcon = styled(StyledIcon)`
  width: 18px;
  height: 18px;

  &:hover {
    transform: scale(1.2);
  }
`;

const LogoContainer = styled.div`
  display: grid;
  margin-top: 8px;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding-left: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-top: 12px;
    font-size: 16px;
    padding-left: 24px;
  }
`;

const Logo = styled(StyledIcon)`
  width: 80px;
  height: 32px;
  margin-bottom: 6px;

  &:hover {
    transform: scale(1);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 120px;
    height: 48px;
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  list-style: none;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 32px 0;
  }
`;

const Line = styled.div`
  display: flex;
  border: 1px solid white;
  border-radius: 8px;
  width: 90%;
  align-self: center;

  :last-of-type {
    margin-bottom: 16px;
  }
`;

const MenuItemIcon = styled(StyledIcon)`
  margin-right: 8px;
  width: 24px;
  height: 24px;

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 32px;
    height: 32px;
  }
`;

const MenuItem = styled(({ external, ...MenuItemProps }) => {
  if (external) {
    return <a {...MenuItemProps} target="_blank" rel="noopener noreferrer" />;
  }
  return <NavLink {...MenuItemProps} />;
})`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;

  svg,
  i,
  span {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 24px 96px;
    border-radius: 12px;
  }

  &:hover {
    background-color: var(--color-light-green);

    svg,
    i,
    span {
      color: var(--color-dark-green);
      fill: var(--color-dark-green);
    }

    svg,
    i {
      transform: scale(1.2);
    }
  }
`;

const ItemName = styled.span<ItemNameProps>`
  color: white;
  font-weight: ${({ username }) => (username ? "bold" : "normal")};
  font-size: ${({ username }) => (username ? "20px" : "14px")};

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: ${({ username }) => (username ? "30px" : "24px")};
  }
`;

const FontAwesomeIcon = styled.i`
  color: white;
  font-size: 20px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  padding: 2px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 30px;
    width: 32px;
    height: 32px;
  }
`;

const MenuItemIconWithBadge = styled.div`
  position: relative;
`;

const Badge = styled.span`
  position: absolute;
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  top: -7px;
  left: 15px;
  color: white;
  font-size: 8px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #bb442f;
  padding: 4px;
`;

interface ItemNameProps {
  username?: boolean;
}

const LogoutContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: end;
  margin: 16px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin: 24px;
  }
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
  border-radius: 8px;
  background-color: var(--color-light-green);
  padding: 8px;
  transition: transform 0.2s ease-in-out;

  span {
    color: var(--color-dark-green);
  }

  svg {
    fill: var(--color-dark-green);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px;
    margin-top: 12px;
  }

  &:hover {
    transform: scale(1.1);
  }
`;
