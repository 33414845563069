import { ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import App from "App";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "styles/variables.css";
import "styles/normalize.css";
import ErrorBoundary from "components/common/ErrorBoundary";
import AppFallbackPage from "pages/AppFallbackPage";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary fallback={(retry) => <AppFallbackPage retry={retry} />}>
        <ToastContainer theme="colored" autoClose={3000} pauseOnHover={false} />
        <App />
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>
);
