import { MouseEvent, useState } from "react";
import styled from "styled-components";
import { Button } from "styledComponents";

interface Props {
  onDelete: (e: MouseEvent) => void;
  className?: string;
}

function Delete({ onDelete, className }: Props) {
  return (
    <DeleteIcon
      onClick={onDelete}
      className={`fa-solid fa-trash-can ${className}`}
    />
  );
}

export default Delete;

const DeleteIcon = styled.i`
  cursor: pointer;
  color: var(--color-dark-red);

  &:hover {
    transform: scale(1.15);
  }
`;
