import styled from "styled-components";
import { Guid } from "guid-typescript";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button } from "styledComponents";
import { Toggle } from "components";
import { useState } from "react";
import { login } from "services/auth";
import {
  useLoginMutation,
  useSaveLoginInformationMutation,
  useUpdateLoginInformationMutation,
} from "store/api";
import { useNavigate } from "react-router-dom";
import { addYears } from "date-fns";

interface FormData {
  username: string;
  password: string;
}

function LoginPage() {
  const { register, handleSubmit } = useForm<FormData>();
  const [rememberCredentials, setRememberCredentials] = useState(false);
  const [authenticate] = useLoginMutation();
  const [saveLoginInformation] = useSaveLoginInformationMutation();
  const [updateLoginInformation] = useUpdateLoginInformationMutation();
  const navigate = useNavigate();

  function setCookies() {
    const excitingCookie = document.cookie.split("=")[1];
    const userAgent = navigator.userAgent;

    if (excitingCookie) {
      try {
        updateLoginInformation({ cookie: excitingCookie, userAgent }).unwrap();
      } catch (error) {
        console.log("något gick fel med cookies");
      }
    } else {
      const newCookie = Guid.create().toString();
      const expiryDateCookie = addYears(new Date(), 2);
      document.cookie = `_login=${newCookie};expires=${expiryDateCookie}`;
      try {
        saveLoginInformation({ cookie: newCookie, userAgent }).unwrap();
      } catch (error) {
        console.log("något gick fel med cookies");
      }
    }
  }

  async function onSubmit(data: FormData) {
    const { username, password } = data;
    try {
      const jwt = await authenticate({ username, password }).unwrap();
      login(jwt);
      setCookies();
      navigate("/products");
    } catch (error) {
      toast.error("Felaktigt användarnamn eller lösenord");
    }
  }

  return (
    <Container>
      <LogoContainer>
        <SfwLogo src="logo.svg" alt="Logo" />
        <Waves>
          <img src="waves.svg" alt="waves" />
        </Waves>
      </LogoContainer>
      <LoginContainer>
        <LoginText>Logga in</LoginText>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
          <FormControl>
            <Label htmlFor="username">Användarnamn</Label>
            <Input id="username" {...register("username")} />
          </FormControl>
          <FormControl>
            <Label htmlFor="password">Lösenord</Label>
            <Input id="password" type="password" {...register("password")} />
          </FormControl>
          <StyledToggle
            id="rememberCredentials"
            label="Kom ihåg mina inloggningsuppgifter"
            onToggle={() => setRememberCredentials(!rememberCredentials)}
            show={rememberCredentials}
          />
          <StyledButton>Logga in</StyledButton>
        </Form>
      </LoginContainer>
    </Container>
  );
}

export default LoginPage;

const Container = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100vh;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  margin-top: 120px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-top: 180px;
  }
`;

const SfwLogo = styled.img`
  width: 344px;

  @media (min-width: 1400px) and (min-height: 800px) {
    width: 516px;
  }
`;

const Waves = styled.div`
  width: 100%;
  position: absolute;
  background-color: var(--color-dark-green);
  bottom: 0;
  left: 0;
  height: 120px;

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 180px;
  }

  img {
    height: 80px;
    width: 100%;

    @media (min-width: 1400px) and (min-height: 800px) {
      height: 120px;
    }
  }
`;

const LoginContainer = styled.div`
  background-color: var(--color-dark-green);
  padding: 48px 120px;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 72px 180px;
  }
`;

const LoginText = styled.h1`
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 48px;

  @media (min-width: 1400px) and (min-height: 800px) {
    font-size: 48px;
    margin-bottom: 72px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: 32px;
  }
`;

const Label = styled.label`
  color: white;
  margin-bottom: 4px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: 6px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  padding: 8px;
  border-radius: 8px;
  outline: none;

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px;
    border-radius: 12px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 80px;
  background-color: white;
  color: var(--color-dark-green);
  align-self: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-top: 120px;
  }
`;

const StyledToggle = styled(Toggle)`
  margin-top: 16px;
  color: white;
  min-width: 304px;

  @media (min-width: 1400px) and (min-height: 800px) {
    min-width: 456px;
  }

  input {
    background-color: white;
  }

  i {
    color: ${({ show }) =>
      show ? "var(--color-dark-green)" : "var(--color-dark-red)"};
  }
`;
