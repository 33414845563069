import styled from "styled-components";
import { NavigateBackHeader, HistoryTable } from "components";

function HistoryTablePage() {
  return (
    <Container>
      <div>
        <NavigateBackHeader title="Historik" />
      </div>
      <HistoryTable />
    </Container>
  );
}

export default HistoryTablePage;

const Container = styled.div`
  width: 100%;
  padding: 0 16px;
`;
