import { Bar, Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getSelectedFilter } from "store/selectedFilter";
import styled from "styled-components";
import { ChartData } from "types";

interface Props {
  measurements: number[];
  products: number[];
}

function MesaurementChart({ measurements, products }: Props) {
  const selectedFilter = useSelector(getSelectedFilter);

  const data: ChartData = {
    labels: selectedFilter.getLabels(),
    datasets: [
      {
        label: "Antal mätningar",
        data: measurements,
        backgroundColor: "#2e6053",
      },
      {
        label: "Antal produkter",
        data: products,
        backgroundColor: "#789f94",
      },
    ],
  };

  switch (selectedFilter.chartType) {
    case "line":
      return (
        <Container>
          <Line
            data={data}
            options={{
              scales: {
                x: {
                  grid: { display: false },
                  ticks: {
                    callback: (value, index) =>
                      index % 5 === 0 || index === data.labels.length - 1
                        ? data.labels[index]
                        : null,
                  },
                },
                y: {
                  grid: { drawTicks: false },
                  border: { display: false },
                  beginAtZero: true,
                  ticks: {
                    padding: 16,
                    autoSkip: false,
                    maxTicksLimit: 9,
                    callback: function (value) {
                      if (Math.floor(+value) === value) {
                        return value;
                      }
                    },
                  },
                },
              },
            }}
          />
        </Container>
      );
    case "vertical_bar":
      return (
        <Container>
          <Bar
            data={data}
            options={{
              scales: {
                y: {
                  grid: { drawTicks: false },
                  border: { display: false },
                  beginAtZero: true,
                  ticks: {
                    padding: 16,
                    autoSkip: false,
                    maxTicksLimit: 9,
                    callback: function (value) {
                      if (Math.floor(+value) === value) {
                        return value;
                      }
                    },
                  },
                },
                x: {
                  grid: { display: false },
                  ticks: { autoSkip: false },
                },
              },
            }}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Bar
            data={data}
            options={{
              scales: {
                y: {
                  grid: { drawTicks: false },
                  border: { display: false },
                  beginAtZero: true,
                  ticks: {
                    padding: 16,
                    autoSkip: false,
                    maxTicksLimit: 9,
                    callback: function (value) {
                      if (Math.floor(+value) === value) {
                        return value;
                      }
                    },
                  },
                },
                x: {
                  grid: { display: false },
                  ticks: { autoSkip: false },
                },
              },
            }}
          />
        </Container>
      );
  }
}

export default MesaurementChart;

const Container = styled.div`
  @media (min-width: 1400px) and (min-height: 800px) {
    width: 840px;
    height: 420px;
  }
`;
