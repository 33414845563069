interface Translations {
  [key: string]: string;
}

export function useTranslation() {
  return function translate(key: string) {
    return translations[key] || key;
  };
}

const translations: Translations = {
  TOP: "Övre",
  MIDDLE: "Mellersta",
  BOTTOM: "Nedre",
  all: "Alla",
  chilled: "Kylda",
  frozen: "Frysta",
  Poultry: "Fågel",
  Pork: "Fläsk",
  Beef: "Nöt",
  Mutton: "Lamm",
  Deer: "Vilt",
  Dairy: "Mejeri",
  Fish: "Fisk",
  Plant: "Vegoprodukter",
  Sandwich: "Färdigmat",
  Seafood: "Skaldjur",
  Veggies: "Grönsaker",
  Bread: "Bröd",
};
