import { ExclamationCircle } from "components";

function ExclamationInfo() {
  return (
    <ExclamationCircle>
      Vid -2 °C påbörjas den mikrobiella tillväxten. Denna avvikelse kräver
      därmed en närmare bedömning. Vänligen kontakta oss på SFW via
      hello@sustainablefoodwaste.se
    </ExclamationCircle>
  );
}

export default ExclamationInfo;
