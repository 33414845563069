interface Props {
  className?: string;
}

function HistoryIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="History">
        <path d="m10.93,10.93l-3.96-3.96c-1.76-1.76-4.77-.51-4.77,1.97v12.81c0,1.55,1.25,2.79,2.79,2.79h12.81c2.49,0,3.74-3.01,1.98-4.77l-3.58-3.58c4.04-4.04,9.63-6.54,15.79-6.54,12.34,0,22.35,10.01,22.35,22.35s-10.01,22.35-22.35,22.35c-4.75,0-9.15-1.48-12.77-4-1.69-1.18-4-.77-5.19.92s-.77,4,.92,5.19c4.84,3.36,10.72,5.34,17.04,5.34,16.46,0,29.8-13.34,29.8-29.8S48.46,2.2,32,2.2c-8.23,0-15.68,3.34-21.07,8.73Zm21.07,6.17c-1.55,0-2.79,1.25-2.79,2.79v12.1c0,.74.29,1.45.81,1.98l8.38,8.38c1.09,1.09,2.86,1.09,3.95,0s1.09-2.86,0-3.95l-7.57-7.57v-10.95c0-1.55-1.25-2.79-2.79-2.79h.01Z" />
      </g>
    </svg>
  );
}

export default HistoryIcon;
