import { ReactNode, useState } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

function ExclamationCircle({ children }: Props) {
  const [show, setShow] = useState(false);

  return (
    <Container>
      <InfoIcon
        className="fa-solid fa-circle-exclamation"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      {show && <InfoBox>{children}</InfoBox>}
    </Container>
  );
}

export default ExclamationCircle;

const Container = styled.div`
  position: relative;
`;

const InfoIcon = styled.i`
  margin: 4px 8px 8px 8px;
  cursor: pointer;
  transform: scale(1);
  color: #bb442f;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-left: 12px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const InfoBox = styled.div`
  position: absolute;
  text-align: center;
  padding: 8px 16px;
  background-color: var(--color-light-green);
  color: var(--color-dark-green);
  width: 392px;
  bottom: -50%;
  left: 32px;
  z-index: 50;
  border-radius: 8px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  box-shadow: 0 -3px 12px 1.5px rgba(0, 0, 0, 0.15);

  @media (min-width: 1400px) and (min-height: 800px) {
    padding: 12px 24px;
    right: 60px;
    top: -104px;
    border-radius: 12px;
  }
`;
