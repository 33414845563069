interface Props {
  className?: string;
}

function SearchIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Miscellaneous"
      data-name="Icons: Miscellaneous"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Search">
        <path d="m61.94,56.66c3.5,3.5-1.8,8.8-5.3,5.3m4.89-5.75c-4.86-4.86-9.71-9.72-14.59-14.56-.48-.47-.55-.79-.15-1.36,2.3-3.23,3.62-6.86,4.04-10.77.79-7.28-1.09-13.78-6.01-19.27C39.19,3.96,32.05,1.35,23.73,2.19c-5.48.55-10.26,2.85-14.23,6.72-4.13,4.03-6.55,8.93-7.25,14.64-.68,5.57.47,10.8,3.34,15.62,2.09,3.51,4.93,6.31,8.46,8.38,3.47,2.04,7.21,3.17,11.25,3.38,3.38.18,6.66-.25,9.78-1.49,2.08-.82,4.04-1.94,6.11-2.96,5.08,5.09,10.29,10.29,15.46,15.47,1.8-1.8,3.52-3.52,5.3-5.3-.13-.15-.26-.31-.41-.45ZM24.6,9.67c9.94-1.12,18.1,5.96,18.85,15.39.76,9.67-6.02,17.31-14.94,18.29-10.13,1.11-18.38-6.24-18.91-16.1-.26-9.07,5.9-16.55,15-17.58Z" />
      </g>
    </svg>
  );
}

export default SearchIcon;
