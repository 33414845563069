import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  add,
  isBefore,
  isToday,
  isWithinInterval,
  startOfMonth,
  sub,
  startOfDay,
  endOfDay,
} from "date-fns";
// @ts-ignore
import { RootState } from "store";
import { ChartType, Transport } from "types";
// @ts-ignore
import { formatDate } from "utils";

class LabelInput {
  constructor(public step: Duration, public formatString = "MMM") {}
}

export class Filter {
  constructor(
    private _chartType: ChartType,
    private _label: string,
    public interval: Duration,
    private _filterLabels: string[] | LabelInput,
    public filterFunction?: (date: Date) => boolean
  ) {}

  get chartType() {
    return this._chartType;
  }

  get label() {
    return this._label;
  }

  getData(transports: Transport[]): Transport[] {
    if (this.filterFunction)
      return transports.filter((t) =>
        this.filterFunction?.(new Date(t.createdDate))
      );

    const start = sub(new Date(), this.interval);
    const end = this.interval.days
      ? startOfDay(sub(new Date(), { days: 1 }))
      : new Date();

    return transports.filter((t) =>
      isWithinInterval(new Date(t.createdDate), {
        start: this.interval.months ? startOfMonth(start) : startOfDay(start),
        end: this.interval.months ? startOfMonth(end) : endOfDay(end),
      })
    );
  }

  getLabels(): string[] {
    if (this._filterLabels instanceof LabelInput) {
      const days = [];
      const startDate = startOfDay(new Date());

      for (
        let date = sub(startDate, this.interval);
        isBefore(date, startDate);
        date = add(date, this._filterLabels.step)
      )
        days.push(formatDate(date, this._filterLabels.formatString));

      return days;
    }

    return this._filterLabels;
  }
}

export const filters: Filter[] = [
  new Filter("horizontal_bar", "idag", { days: 0 }, ["idag"], isToday),
  new Filter(
    "vertical_bar",
    "7d",
    { days: 7 },
    new LabelInput({ days: 1 }, "ccc d MMM")
  ),
  new Filter("line", "30d", { days: 30 }, new LabelInput({ days: 1 }, "d MMM")),
  new Filter(
    "horizontal_bar",
    "3m",
    { months: 3 },
    new LabelInput({ months: 1 })
  ),
  new Filter(
    "vertical_bar",
    "12m",
    { months: 12 },
    new LabelInput({ months: 1 })
  ),
];

const initialState: Filter = filters[filters.length - 1];

const slice = createSlice({
  name: "selectedFilter",
  initialState,
  reducers: {
    selectFilter(selectedFilter, action: PayloadAction<Filter>) {
      return action.payload;
    },
  },
});

export const getSelectedFilter = (state: RootState) => state.selectedFilter;

export const { selectFilter } = slice.actions;
export default slice.reducer;
