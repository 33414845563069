import React, { ReactNode } from "react";
import {
  CowIcon,
  FishIcon,
  MooseIcon,
  DairyIcon,
  SheepIcon,
  PlantIcon,
  PigIcon,
  SandwichIcon,
  SeafoodIcon,
  VegetablesIcon,
  ChickenIcon,
  HomeIcon,
  AnalyticsIcon,
  DonateIcon,
  DropletIcon,
  FrostIcon,
  HistoryIcon,
  LogoutIcon,
  NotificationsIcon,
  PlusIcon,
  SearchIcon,
  XMarkIcon,
  CheckIcon,
  BreadIcon,
  CalendarIcon,
  HeartCircleIcon,
  LeafIcon,
  MagnifyingMinusIcon,
  TemperatureIcon,
  SfwLogoIcon,
} from "../icons";

const icons: Record<string, JSX.Element> = {
  /* Categories */
  Beef: <CowIcon />,
  Dairy: <DairyIcon />,
  Deer: <MooseIcon />,
  Fish: <FishIcon />,
  Mutton: <SheepIcon />,
  Plant: <PlantIcon />,
  Pork: <PigIcon />,
  Poultry: <ChickenIcon />,
  Sandwich: <SandwichIcon />,
  Seafood: <SeafoodIcon />,
  Veggies: <VegetablesIcon />,
  Bread: <BreadIcon />,

  /* Menu */
  Home: <HomeIcon />,
  Analytics: <AnalyticsIcon />,
  History: <HistoryIcon />,
  Donate: <DonateIcon />,
  Notifications: <NotificationsIcon />,
  Logout: <LogoutIcon />,
  SfwLogo: <SfwLogoIcon />,

  /* Miscellaneous */
  Droplet: <DropletIcon />,
  Plus: <PlusIcon />,
  Frost: <FrostIcon />,
  Search: <SearchIcon />,
  XMark: <XMarkIcon />,
  Check: <CheckIcon />,
  Calendar: <CalendarIcon />,
  HeartCircle: <HeartCircleIcon />,
  Leaf: <LeafIcon />,
  MagnifyingMinus: <MagnifyingMinusIcon />,
  Temperature: <TemperatureIcon />,
};

interface Props {
  name: string;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  children?: ReactNode;
}

function Icon({ name, className, onClick }: Props) {
  return React.cloneElement(icons[name], {
    className,
    onClick,
  });
}

export default Icon;
