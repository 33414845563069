import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { formatDate } from "utils";
import { ResultsPage } from "pages";
import { Transport } from "types";
import { useGetTransportQuery, useUpdateTransportMutation } from "store/api";

interface NameForm {
  name: string;
}

function HistoryPage() {
  const { transportId } = useParams();
  const [updateTransport] = useUpdateTransportMutation();
  const { data: transport } = useGetTransportQuery(transportId!);
  const [editModeActive, setEditModeActive] = useState(false);
  const { register, handleSubmit, watch } = useForm<NameForm>();

  const formRef = useRef<HTMLFormElement>(null);

  function onSubmit(data: NameForm) {
    if (!transport) return;

    const newTransport: Transport = { ...transport, name: data.name };
    updateTransport(newTransport);

    setEditModeActive(false);
  }

  if (!transport || transport.id !== transportId)
    return <h1>Mätning hämtas...</h1>;

  const transportName = watch("name") || transport.name;

  return (
    <div style={{ width: "100%" }}>
      <Container>
        <Date showCommonActionMenu={transport.measurementResult.length > 1}>
          {formatDate(transport.createdDate)}
        </Date>
        {editModeActive ? (
          <EditContainer ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <EditNameInput {...register("name", { value: transportName })} />
            <EditIcon
              className="fa-solid fa-check"
              onClick={() => formRef.current?.requestSubmit()}
            />
          </EditContainer>
        ) : (
          <NavBar>
            <Header>
              <EditContainer onSubmit={handleSubmit(onSubmit)}>
                <i
                  onClick={(e) => {
                    window.history.back();
                  }}
                  className="fa-solid fa-chevron-left"
                ></i>
                <EditNameLabel>{transportName}</EditNameLabel>{" "}
                <EditIcon
                  className="fa-solid fa-pen"
                  onClick={() => setEditModeActive(true)}
                />
              </EditContainer>
            </Header>
            <NavLine />
          </NavBar>
        )}
      </Container>
      <ResultsPage />
    </div>
  );
}

export default HistoryPage;

interface ProductProps {
  showCommonActionMenu?: boolean;
}

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const Date = styled.p<ProductProps>`
  margin-bottom: ${({ showCommonActionMenu }) =>
    showCommonActionMenu ? 0 : "16px"};
  text-align: center;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-bottom: ${({ showCommonActionMenu }) =>
      showCommonActionMenu ? 0 : "24px"};
  }
`;

const EditContainer = styled.form`
  height: 40px;
  font-size: 32px;
  font-weight: bold;

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 60px;
    font-size: 48px;
  }
`;

const EditIcon = styled.i`
  margin-left: 16px;
  cursor: pointer;
  color: var(--color-dark-green);
  font-size: 22px;

  @media (min-width: 1400px) and (min-height: 800px) {
    margin-left: 12px;
    font-size: 33px;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const EditNameInput = styled.input``;

const EditNameLabel = styled.span`
  text-align: center;
`;

const Header = styled.h1`
  text-align: center;
  margin: 0px;
  width: 22vw;

  i {
    font-size: 24px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

const NavBar = styled.div`
  margin-bottom: 16px;
`;

const NavLine = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background-color: var(--color-dark-green);
`;
