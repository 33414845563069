import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Product, Category as CategoryType } from "types";
import { Searchbar, ErrorBoundary, ProductCard, Icon } from "components";
import { Button } from "styledComponents";
import { useLatestProducts } from "hooks/useLatestProducts";
import {
  useGetNotificationQuery,
  useGetProductsQuery,
  useGetTransportsQuery,
} from "store/api";
import {
  getSelectedProducts,
  isProductSelected,
  selectProduct,
  unselectProduct,
} from "store/selectedProducts";
import CategoryFilter from "components/CategoryFilter";

enum Category {
  CHILLED = "CHILLED",
  FROZEN = "FROZEN",
  CHOOSEN = "CHOOSEN",
  LATEST = "LATEST",
}

function ProductSelectionPage() {
  // Prefetching
  const { refetch: refetchTransports } = useGetTransportsQuery();
  const { refetch: refetechNotifications } = useGetNotificationQuery();

  useEffect(() => {
    refetchTransports();
    refetechNotifications();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState(Category.LATEST);
  const { data: products = [] } = useGetProductsQuery();
  const latestProducts = useLatestProducts();
  const selectedProducts = useSelector(getSelectedProducts);
  const isSelected = useSelector(isProductSelected);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function toggleAddProduct(product: Product) {
    if (isSelected(product)) dispatch(unselectProduct(product));
    else dispatch(selectProduct(product));
  }

  function changeCategory(category: Category) {
    setSelectedCategory(category);
    setSearchQuery("");
  }

  function handleCategoryChange(selectedCategories: CategoryType[]) {
    if (
      selectedCategory !== Category.FROZEN &&
      selectedCategory !== Category.CHILLED
    )
      setSelectedCategory(Category.CHILLED);

    setSelectedCategories(selectedCategories);
  }

  let filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  switch (selectedCategory) {
    case Category.CHOOSEN:
      filteredProducts = selectedProducts;
      break;
    case Category.LATEST:
      filteredProducts = latestProducts;
      break;
    case Category.CHILLED:
      filteredProducts = filteredProducts.filter(
        (p) =>
          !p.isFrozen &&
          (selectedCategories.length === 0 ||
            selectedCategories.map((c) => c.id).includes(p.category.id))
      );
      break;
    case Category.FROZEN:
      filteredProducts = filteredProducts.filter(
        (p) =>
          p.isFrozen &&
          (selectedCategories.length === 0 ||
            selectedCategories.map((c) => c.id).includes(p.category.id))
      );
      break;
  }

  function handleSearch(value: string) {
    if (
      selectedCategory !== Category.FROZEN &&
      selectedCategory !== Category.CHILLED
    )
      setSelectedCategory(Category.CHILLED);

    setSearchQuery(value);
  }

  return (
    <Container>
      <div></div>
      <div>
        <div style={{ padding: "0 16px" }}>
          <SearchbarBackground>
            <SearchbarContainer>
              <CategoryFilter
                selectedCategories={selectedCategories}
                onChange={handleCategoryChange}
              />
              <Searchbar searchQuery={searchQuery} onSearch={handleSearch} />
            </SearchbarContainer>
          </SearchbarBackground>
          <CategoryMenu>
            <CategoryMenuItem
              isSelected={selectedCategory === Category.LATEST}
              onClick={() => changeCategory(Category.LATEST)}
            >
              <IconContainer>
                <IconText>Senaste val</IconText>
              </IconContainer>
            </CategoryMenuItem>
            <CategoryMenuItem
              isSelected={selectedCategory === Category.CHILLED}
              onClick={() => changeCategory(Category.CHILLED)}
            >
              <IconContainer>
                <CategoryIcon
                  name="Droplet"
                  isSelected={selectedCategory === Category.CHILLED}
                />
                <IconText>Kylt (+°C)</IconText>
              </IconContainer>
            </CategoryMenuItem>
            <CategoryMenuItem
              isSelected={selectedCategory === Category.FROZEN}
              onClick={() => changeCategory(Category.FROZEN)}
            >
              <IconContainer>
                <CategoryIcon
                  name="Frost"
                  isSelected={selectedCategory === Category.FROZEN}
                />
                <IconText>Fryst (-°C)</IconText>
              </IconContainer>
            </CategoryMenuItem>
            <CategoryMenuItem
              isSelected={selectedCategory === Category.CHOOSEN}
              onClick={() => changeCategory(Category.CHOOSEN)}
            >
              <IconContainer>
                {selectedProducts.length}{" "}
                {`vald${selectedProducts.length !== 1 ? "a" : ""} produkter`}
              </IconContainer>
            </CategoryMenuItem>
          </CategoryMenu>
        </div>
        <ProductCards>
          {filteredProducts.map((product) => {
            const selected = isSelected(product);
            return (
              <ErrorBoundary
                key={product.id}
                fallback={() => (
                  <ProductCard
                    hasError
                    key={product.id}
                    product={{ ...product, id: `error-${product.id}` }}
                    isSelected={selected}
                    onAddProduct={toggleAddProduct}
                  />
                )}
              >
                <ProductCard
                  key={product.id}
                  product={product}
                  isSelected={selected}
                  onAddProduct={toggleAddProduct}
                />
              </ErrorBoundary>
            );
          })}
        </ProductCards>
      </div>

      <Footer>
        <CommonActionMenuContainer />
        <CommonActionMenuContainer />
        <StyledButton
          disabled={selectedProducts.length === 0}
          onClick={() => navigate("/measurement")}
        >
          NÄSTA
        </StyledButton>
      </Footer>
    </Container>
  );
}

export default ProductSelectionPage;

interface SelectedProps {
  isSelected?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  border: 2px solid var(--color-dark-green);
  border-radius: 8px;
`;

const SearchbarBackground = styled.div`
  display: grid;
  height: 80px;
  background-color: var(--color-light-green);
  place-items: center;
  border-radius: 8px;
  padding: 0 16px;
`;

const ProductCards = styled.ul<SelectedProps>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  height: 328px;
  overflow-y: auto;
  padding: 0 6px 0 16px;

  ::-webkit-scrollbar {
    display: block;
    border-radius: 8px;
    background-color: var(--color-light-grey);
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-green);
    border-radius: 8px;
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 596px;
  }
`;

const CategoryMenu = styled.div<SelectedProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 2px solid var(--color-dark-green);
  border-radius: 8px;
  margin: 16px 0;
`;

const CategoryMenuItem = styled.span<SelectedProps>`
  color: ${({ isSelected }) =>
    isSelected ? "white" : "var(--color-dark-green)"};
  background-color: ${({ isSelected }) =>
    isSelected ? "var(--color-dark-green)" : "transparent"};
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
  border-left: solid;

  &:first-of-type {
    border-left: none;
  }
`;

const StyledButton = styled(Button)`
  width: 124px;
  height: 32px;
`;

const CategoryIcon = styled(Icon)<SelectedProps>`
  fill: ${({ isSelected }) =>
    isSelected ? "white" : "var(--color-dark-green)"};
  height: 18px;
  width: 18px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const IconText = styled.p``;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 -2px 8px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  padding: 0 6vw;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 11.3vh;
  z-index: 100;
`;

const CommonActionMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
