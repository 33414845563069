import { FeedbackConfirmPage } from "pages";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSendFeedbackMutation } from "store/api";
import styled from "styled-components";
import { Button } from "styledComponents";
import NavigateBackHeader from "./common/NavigateBackHeader";

interface FormData {
  message: string;
}

function FeedbackForm() {
  const [isDoneFeedbackSending, setIsDoneFeedbackSending] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [sendFeedback, { isLoading: isSendingFeedback }] =
    useSendFeedbackMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  async function onSubmit(data: FormData) {
    try {
      await sendFeedback(data).unwrap();
      setIsDoneFeedbackSending(true);
    } catch (error) {
      toast.error("Något gick fel. Kontakta SFW.");
    }
  }

  function onSubmitError() {
    toast.error("Meddelande är obligatorisk!");
  }

  if (isDoneFeedbackSending) return <FeedbackConfirmPage />;

  return (
    <>
      <NavigateBackHeader title="Ge oss feedback" />
      <HelpContainer>
        <QuestionMark
          onMouseOver={() => setShowInfo(true)}
          onMouseOut={() => setShowInfo(false)}
        >
          <i className="fa-solid fa-question" />
        </QuestionMark>
        <span>Information</span>
        <InstructionList showInfo={showInfo}>
          <Instruction>
            Här kan du lämna feedback för att vi ska kunna förbättra appen för
            er!
          </Instruction>
        </InstructionList>
      </HelpContainer>
      <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <MessageField text="Skriv din feedback här" error={!!errors.message}>
          <MessageInput {...register("message", { required: true })} />
        </MessageField>
        <StyledButton disabled={isSendingFeedback}>
          Skicka <span className="spinner" />
        </StyledButton>
      </Form>
    </>
  );
}

export default FeedbackForm;

interface InstructionListProps {
  showInfo: boolean;
}

const HelpContainer = styled.div`
  display: flex;
  position: absolute;
  right: 24px;
  top: 8px;
  align-items: center;
`;

const QuestionMark = styled.div`
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: white;
  background-color: var(--color-dark-green);
  margin-right: 8px;
  cursor: help;
  transform: scale(1);

  &:hover {
    transform: scale(1.2);
  }
`;

const InstructionList = styled.ol<InstructionListProps>`
  position: absolute;
  z-index: 60;
  top: 24px;
  right: 0;
  color: white;
  overflow: hidden;
  width: 288px;
  background-color: var(--color-dark-green);
  border-radius: 16px;
  list-style: none;
  height: ${({ showInfo }) => (showInfo ? "96px" : 0)};
  padding: ${({ showInfo }) => (showInfo ? "16px" : 0)};

  p {
    margin-top: 16px;
  }

  i {
    color: white;
  }
`;

const Instruction = styled.li`
  padding: 8px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface InnerInputLabel {
  text?: string;
}

interface ErrorProps {
  error?: boolean;
}

const StyledButton = styled(Button)`
  margin-top: 24px;
  align-self: end;
`;

const Field = styled.div<InnerInputLabel & ErrorProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  padding: 16px 16px 16px 2px;
  margin-bottom: 16px;

  ::after {
    position: absolute;
    content: "${({ text }) => text}";
    top: 2px;
    left: 4px;
    color: var(--color-medium-grey);
  }
`;

const MessageField = styled(Field)<ErrorProps>`
  height: 50vh;
  margin-bottom: 8px;
  border: ${({ error }) =>
    error ? "3px solid red" : "3px solid var(--color-dark-green)"};
`;

const MessageInput = styled.textarea<ErrorProps>`
  margin-top: 8px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
`;
