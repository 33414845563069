interface Props {
  className?: string;
}

function LogoutIcon({ className }: Props) {
  return (
    <svg
      width={16}
      height={16}
      id="Icons:_Menu"
      data-name="Icons: Menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <g id="Logout">
        <path d="m20.94,13.57c2.04,0,3.69-1.65,3.69-3.69s-1.65-3.69-3.69-3.69h-7.37c-6.11,0-11.06,4.95-11.06,11.06v29.5c0,6.11,4.95,11.06,11.06,11.06h7.37c2.04,0,3.69-1.65,3.69-3.69s-1.65-3.69-3.69-3.69h-7.37c-2.04,0-3.69-1.65-3.69-3.69v-29.5c0-2.04,1.65-3.69,3.69-3.69h7.37Zm39.69,20.44c.55-.52.86-1.24.86-2s-.31-1.49-.86-2l-16.59-15.67c-.81-.76-1.98-.97-3-.53s-1.67,1.44-1.67,2.53v8.3h-14.75c-2.04,0-3.69,1.65-3.69,3.69v7.37c0,2.04,1.65,3.69,3.69,3.69h14.75v8.3c0,1.11.66,2.1,1.67,2.53s2.19.23,3-.53l16.59-15.67Z" />
      </g>
    </svg>
  );
}

export default LogoutIcon;
