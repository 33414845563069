import ExclamationInfo from "components/ExclamationInfo";
import { Icon } from "components";
import { formatDate } from "utils";
import {
  MeasurementData,
  MeasurementProductData,
  MeasurementResult,
  Transport,
} from "types";
import styled from "styled-components";
import { Button } from "styledComponents";
import { useState } from "react";

interface Props {
  transport: Transport;
  measurementResult: MeasurementResult;
}

function checkFrozenProduct(
  data: Omit<MeasurementData, "productId" | "volumeInKg">,
  product: MeasurementProductData
) {
  return product.isFrozen && data.input.temperature >= -2;
}

function formatDayText(dayCount: number) {
  return `${dayCount} ${dayCount === 1 ? "dag" : "dagar"}`;
}

function PalletResultCard({ transport, measurementResult: mr }: Props) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  if (!mr.product) return null;

  return (
    <CardContainer>
      <Card>
        <Column style={{ justifySelf: "start" }}>
          <TemperatureIcon name={mr.product.isFrozen ? "Frost" : "Droplet"} />
          <CategoryIcon name={mr.product.category.name} />
          <Product>
            <ProductName>{mr.product.name}</ProductName>
            <ProductInfo>
              Temperatur: {mr.product.isFrozen ? "Fryst" : "Kylt"}
            </ProductInfo>
            <ProductInfo>Förpackning: {mr.product.packaging}</ProductInfo>
          </Product>
        </Column>

        <Column direction="column">
          <span>Övre</span>
          <span>Mitt</span>
          <span>Nedre</span>
        </Column>

        <div>
          {mr.data.map((md) => (
            <Column key={md.level}>
              <StyledIcon
                dayDiff={
                  transport.isSavedBfd
                    ? Math.round(md.calculation.percentageSavedDayDiff * 100)
                    : Math.round(md.calculation.percentageDayDiff * 100)
                }
                name="Calendar"
              />
              <span style={{ width: "6.875vw" }}>
                {checkFrozenProduct(md, mr.product!) ? (
                  <ExclamationInfo />
                ) : transport.isSavedBfd ? (
                  formatDate(md.calculation.newSavedBfd)
                ) : (
                  formatDate(md.calculation.newBfdAfterDeviation)
                )}
              </span>
            </Column>
          ))}
        </div>

        <div>
          {mr.data.map((md) => (
            <Column key={md.level}>
              <StyledIcon
                dayDiff={
                  transport.isSavedBfd
                    ? Math.round(md.calculation.percentageSavedDayDiff * 100)
                    : Math.round(md.calculation.percentageDayDiff * 100)
                }
                name="MagnifyingMinus"
              />
              <span style={{ width: "7.5vw" }}>
                {checkFrozenProduct(md, mr.product!)
                  ? "-----"
                  : transport.isSavedBfd
                  ? formatDayText(
                      md.calculation.dayDiff - md.calculation.savedDayDiff
                    ) +
                    ` (-${Math.round(
                      md.calculation.percentageSavedDayDiff * 100
                    )}%)`
                  : formatDayText(md.calculation.dayDiff) +
                    ` (-${Math.round(
                      md.calculation.percentageDayDiff * 100
                    )}%)`}
              </span>
            </Column>
          ))}
        </div>

        <div>
          {mr.data.map((md) => (
            <Column key={md.level}>
              <StyledIcon
                dayDiff={
                  transport.isSavedBfd
                    ? Math.round(md.calculation.percentageSavedDayDiff * 100)
                    : Math.round(md.calculation.percentageDayDiff * 100)
                }
                name="HeartCircle"
              />
              <span style={{ width: 72 }}>
                {checkFrozenProduct(md, mr.product!)
                  ? "-----"
                  : transport.isSavedBfd
                  ? formatDayText(md.calculation.savedRescuedDays)
                  : formatDayText(md.calculation.rescuedDays)}
              </span>
            </Column>
          ))}
        </div>

        <div>
          {mr.data.map((md) => (
            <Column key={md.level}>
              <StyledIcon
                dayDiff={
                  transport.isSavedBfd
                    ? Math.round(md.calculation.percentageSavedDayDiff * 100)
                    : Math.round(md.calculation.percentageDayDiff * 100)
                }
                name="Leaf"
              />
              <span>
                {Math.round(mr.generalCalculation.co2EmissionInKg / 3)} kg
              </span>
            </Column>
          ))}
        </div>

        <Column>
          <StyledButton onClick={() => setShowMoreInfo(!showMoreInfo)}>
            {showMoreInfo ? "Mindre" : "Mer"} info
          </StyledButton>
        </Column>
      </Card>

      {showMoreInfo && (
        <MoreInfo>
          <Column direction="column">
            <span>Avvikelsens längd:</span>
            <span style={{ fontWeight: "bold" }}>
              {transport.deviation.duration} h
            </span>
          </Column>
          <Column direction="column">
            <span>Datum för avvikelsen:</span>
            <span style={{ fontWeight: "bold" }}>
              {formatDate(transport.deviation.date)}
            </span>
          </Column>
          <Column direction="column">
            <span>BFD enligt förpackning:</span>
            <span style={{ fontWeight: "bold" }}>
              {formatDate(mr.data[0].input.bestBeforeDate)}
            </span>
          </Column>
          <Column direction="column">
            <span>Volym:</span>
            <span style={{ fontWeight: "bold" }}>{mr.volumeInKg} kg</span>
          </Column>
          <Column direction="column">
            {mr.data.map((md) => (
              <div>
                <span>Uppmätt produkttemperatur: </span>
                <span style={{ fontWeight: "bold" }}>
                  {md.input.temperature} °C
                </span>
              </div>
            ))}
          </Column>
        </MoreInfo>
      )}
    </CardContainer>
  );
}

export default PalletResultCard;

const StyledButton = styled(Button)`
  height: 32px;
  width: 6.25vw;
  font-size: 12px;
  border-radius: 40px;
  color: var(--color-dark-grey);
  outline: none;
  background-color: var(--color-light-grey);
`;

const CardContainer = styled.div`
  position: relative;
  border: 1.5px solid var(--color-medium-grey);
  border-radius: 8px;
  margin-bottom: 8px;

  @media (min-width: 1400px) and (min-height: 800px) {
    border: 2px solid var(--color-medium-grey);
  }
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 0.3125fr repeat(4, 1.5625fr) 0.9375fr;
  justify-items: center;
  padding: 8px;
  gap: 16px;
`;

interface StyledIconProps {
  dayDiff: number;
}

const StyledIcon = styled(Icon)<StyledIconProps>`
  fill: ${({ dayDiff }) =>
    dayDiff >= 80
      ? "var(--color-dark-red)"
      : dayDiff >= 31 && dayDiff <= 79
      ? "var(--color-yellow)"
      : "var(--color-icon-green)"};
  width: 1.875vw;
  height: 24px;
`;

const MoreInfo = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 2fr) 1fr 3fr;
  background-color: var(--color-light-grey);
  padding: 16px 0;
  font-size: 14px;
  gap: 16px;
  border-radius: 0 0 8px 8px;
  border-top: 1.5px solid var(--color-medium-grey);

  @media (min-width: 1400px) and (min-height: 800px) {
    border-top: 2px solid var(--color-medium-grey);
  }
`;

interface ColumnProps {
  direction?: "row" | "column";
}

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProductName = styled.div`
  font-weight: bold;
`;

const ProductInfo = styled.div`
  font-size: 12px;
`;

const TemperatureIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  fill: var(--color-blue);
`;

const CategoryIcon = styled(Icon)`
  fill: var(--color-dark-green);
  width: 48px;
  height: 48px;
`;
