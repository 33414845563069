import { useEffect, useMemo, useRef, useState } from "react";
import { useGetCategoryQuery, useGetProductsQuery } from "store/api";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Category } from "types";
import CheckboxCategory from "./CheckboxCategory";
import { Button } from "styledComponents";

interface Props {
  selectedCategories: Category[];
  onChange(categories: Category[]): void;
}

type CategoryMap = {
  [key: string]: number;
};

function CategoryFilter({ selectedCategories, onChange }: Props) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { data: products = [] } = useGetProductsQuery();
  const { data: categories = [] } = useGetCategoryQuery();

  function handleClickOutside(event: MouseEvent): void {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener(`mousedown`, handleClickOutside);

    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, []);

  const categoryMap = useMemo(
    () =>
      products.reduce((categoryMap, product) => {
        if (categoryMap[product.category.name])
          categoryMap[product.category.name]++;
        else categoryMap[product.category.name] = 1;

        return categoryMap;
      }, {} as CategoryMap),
    [products]
  );

  function onClickCategoryFilterMenu() {
    setOpen(!open);
    if (!open) {
      toast.info("Filtrera mellan både kylt och fryst för att se din produkt");
    }
  }

  function resetCategories() {
    onChange([]);
  }

  function cancelFiltration() {
    resetCategories();
    setOpen(false);
  }

  function handleChange(category: Category) {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];

    onChange(newSelectedCategories);
  }

  return (
    <Container ref={menuRef} open={open}>
      <ButtonContainer onClick={() => onClickCategoryFilterMenu()}>
        <span>Alla kategorier</span>
        <i className={`fa-solid fa-caret-${open ? "up" : "down"}`} />
      </ButtonContainer>
      {open && (
        <CategoryContainer>
          <CheckboxCategory
            label="Alla kategorier"
            count={products.length}
            onChange={resetCategories}
            checked={selectedCategories.length === 0}
          />
          {categories.map((c) => (
            <CheckboxCategory
              key={c.id}
              label={c.name}
              count={categoryMap[c.name]}
              onChange={() => handleChange(c)}
              checked={selectedCategories.includes(c)}
            />
          ))}
          <StyledButtonContainer>
            <StyledButton onClick={cancelFiltration}>Avbryt</StyledButton>
            <StyledButton color="dark" onClick={() => setOpen(false)}>
              Se resultat
            </StyledButton>
          </StyledButtonContainer>
        </CategoryContainer>
      )}
    </Container>
  );
}

export default CategoryFilter;

interface ContainerProps {
  open?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ open }) =>
    open ? "var(--color-light-grey)" : "white"};
  border-radius: 8px 0 0 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;

const CategoryContainer = styled.div`
  position: absolute;
  background-color: white;
  z-index: 150;
  top: calc(100% + 8px);
  border-radius: 8px;
  padding: 8px 16px 0px 16px;
  width: 224px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 8px 0px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
  background-color: ${({ color }) =>
    color === "dark" ? "var(--color-dark-green)" : "#A6A6A6"};
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-size: 14px;
  padding: 0px;
`;
