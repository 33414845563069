import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "styledComponents";
import { Icon } from "components";
import { MeasurementPayload, Level, MeasurementData } from "types";
import { toDate, toFloat } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedProducts,
  resetSelectedProducts,
} from "store/selectedProducts";
import { useSaveMeasurementMutation } from "store/api";
import { getCurrentUser } from "services/auth";

type FormProduct = {
  id: string;
  level: Level;
  volume?: string;
  temperature: { [key in Level]: string };
  bestBeforeDate: string;
};

type MeasurementFormData = {
  deviationDate: string;
  deviationDuration: string;
  isPallet: boolean;
  products: FormProduct[];
};

function MeasurementPage() {
  const [saveMeasurement, { isLoading: isSavingMeasurement }] =
    useSaveMeasurementMutation();
  const selectedProducts = useSelector(getSelectedProducts);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<MeasurementFormData>({
    shouldUnregister: true,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getCurrentUser();
  const isPallet = watch("isPallet");

  async function onSubmit(formData: MeasurementFormData) {
    const data = mapToDbModel(formData);

    const transport = await saveMeasurement(data).unwrap();
    dispatch(resetSelectedProducts());

    navigate(`/result/${transport.id}`);
  }

  function navigateToPreviousPage(e: React.MouseEvent) {
    e.preventDefault();
    window.history.back();
  }

  function onSubmitError() {
    toast.error("Fyll i alla fält för att gå vidare!");
  }

  function mapToDbModel(formData: MeasurementFormData): MeasurementPayload {
    if (!user) throw new Error("No user was found");

    const { deviationDate, deviationDuration } = formData;

    let payloadData = [];

    for (const formProduct of formData.products) {
      payloadData.push(
        ...Object.entries(formProduct.temperature).map(
          ([level, temperature]) => ({
            productId: formProduct.id,
            level: level as Level,
            volumeInKg: formProduct.volume && toFloat(formProduct.volume),

            input: {
              bestBeforeDate: toDate(formProduct.bestBeforeDate),
              temperature: toFloat(temperature),
            },
          })
        )
      );
    }

    return {
      userId: user.id,
      companyId: user.companyId,
      isPallet: formData.isPallet,
      data: payloadData as Omit<MeasurementData, "calculation">[],
      deviation: {
        date: toDate(deviationDate),
        duration: toFloat(deviationDuration),
      },
    };
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
      autoComplete="off"
    >
      <div>
        <CommonMeasurementCard>
          <ProductText>
            <ProductName>Avvikelseinformation</ProductName>
          </ProductText>
          <StyledFormControl name="deviationDuration">
            <StyledInput
              error={!!errors.deviationDuration}
              {...register("deviationDuration", { required: true, min: 0 })}
            />
          </StyledFormControl>
          <StyledFormControl name="deviationDate">
            <StyledInput
              error={!!errors.deviationDate}
              type="date"
              {...register("deviationDate", {
                required: true,
              })}
              onKeyDown={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
            />
          </StyledFormControl>
        </CommonMeasurementCard>
        <Controller
          name="isPallet"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <PalletCheckbox>
              <label htmlFor="togglePallet">Utökad temperaturmätning</label>
              <input
                id="togglePallet"
                type="checkbox"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            </PalletCheckbox>
          )}
        />

        <MeasurementCards>
          {selectedProducts.map((product, i) => (
            <MeasurementCard key={product.id}>
              <TemperatureIcon name={product.isFrozen ? "Frost" : "Droplet"} />
              <ProductText>
                <CategoryIcon name={product.category.name} />
                <StyledProduct>
                  <ProductName>{product.name}</ProductName>
                  <ProductDescription>
                    Temperatur: {product.isFrozen ? "Fryst" : "Kylt"}
                  </ProductDescription>
                  <ProductDescription>
                    Förpackning: {product.packaging}
                  </ProductDescription>
                </StyledProduct>
              </ProductText>
              <StyledFormControl name="bestBeforeDate">
                <StyledInput
                  error={!!errors.products?.[i]?.bestBeforeDate}
                  type="date"
                  {...register(`products.${i}.bestBeforeDate`, {
                    required: true,
                  })}
                  onKeyDown={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </StyledFormControl>
              <StyledFormControl name="volume">
                <StyledInput
                  {...register(`products.${i}.volume`, {
                    min: 0,
                    required: true,
                  })}
                />
              </StyledFormControl>
              <input
                type="hidden"
                {...register(`products.${i}.id`, { value: product.id })}
              />
              {isPallet ? (
                <StyledFormControl name="temperature">
                  <SubInputContainer>
                    <SubInput
                      error={!!errors.products?.[i]?.temperature}
                      placeholder="Övre"
                      {...register(`products.${i}.temperature.${Level.TOP}`, {
                        min: -99,
                        max: 99,
                        required: true,
                      })}
                    />
                    <SubInput
                      placeholder="Mellersta"
                      {...register(
                        `products.${i}.temperature.${Level.MIDDLE}`,
                        {
                          min: -99,
                          max: 99,
                          required: true,
                        }
                      )}
                    />
                    <SubInput
                      placeholder="Nedre"
                      {...register(
                        `products.${i}.temperature.${Level.BOTTOM}`,
                        {
                          min: -99,
                          max: 99,
                          required: true,
                        }
                      )}
                    />
                  </SubInputContainer>
                </StyledFormControl>
              ) : (
                <StyledFormControl name="temperature">
                  <StyledInput
                    error={!!errors.products?.[i]?.temperature}
                    {...register(`products.${i}.temperature.${Level.PACKAGE}`, {
                      min: -99,
                      max: 99,
                      required: true,
                    })}
                  />
                </StyledFormControl>
              )}
            </MeasurementCard>
          ))}
        </MeasurementCards>
      </div>
      <Footer>
        <StyledButton
          variant="secondary"
          disabled={isSavingMeasurement}
          onClick={(e) => {
            navigateToPreviousPage(e);
          }}
        >
          TILLBAKA <span className="spinner" />
        </StyledButton>
        <CommonActionMenuContainer />
        <CommonActionMenuContainer />
        <StyledButton disabled={isSavingMeasurement}>
          NÄSTA <span className="spinner" />
        </StyledButton>
      </Footer>
    </FormContainer>
  );
}

export default MeasurementPage;

interface ErrorProps {
  error?: boolean;
}

const FormContainer = styled.form`
  width: 100%;
  padding: 0 16px;
`;

const MeasurementCards = styled.ul`
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  height: 320px;
  overflow-y: scroll;

  li:last-child {
    margin-bottom: 80px;
  }

  /** Hide scrollbar for Firefox */
  scrollbar-width: none;

  /** Hide scrollbar for Chrome */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    height: 536px;

    li:last-child {
      margin-bottom: 24px;
    }
  }
`;

const MeasurementCard = styled.li`
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(2, 200px) 136px 64px; */
  grid-template-columns: 4fr 1fr 2fr 2fr;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  gap: 56px;
  border-radius: 8px;
  border: 2px solid var(--color-light-grey);
  list-style: none;

  @media (min-width: 1400px) and (min-height: 800px) {
    gap: 88px;
  }
`;

const ProductText = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-black);
`;

const StyledProduct = styled.div`
  display: grid;
  gap: 4px;
  margin: 8px;
`;

const ProductName = styled.p`
  font-weight: bold;
`;

const ProductDescription = styled.p`
  font-size: 12px;
`;

const inputs = {
  temperature: "Uppmätt produkttemperatur (°C)",
  bestBeforeDate: "Bäst-före datum enligt förpackning",
  deviationDuration: "Tidslängd för avvikelsen (h)",
  deviationDate: "Datum för avvikelsen",
  volume: "Produktens Volym (kg)",
};

interface FormControlProps {
  name: string;
  placeholder?: string;
}

const StyledFormControl = styled.div<FormControlProps>`
  position: relative;
  background-color: white;
  padding: 0px 8px;
  width: ${({ name }) =>
    name.includes("volume")
      ? "144px"
      : name.includes("bestBeforeDate")
      ? "216px"
      : name.includes("deviationDuration") || name.includes("deviationDate")
      ? "320px"
      : "208px"};
  border-radius: 8px;

  &::after {
    position: absolute;
    top: 4px;
    left: 8px;
    content: "${({ name }) => inputs[name as keyof typeof inputs]}";
    font-size: 14px;
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    width: ${({ name }) =>
      name.includes("volume")
        ? "216px"
        : name.includes("bestBeforeDate")
        ? "320px"
        : name.includes("deviationDuration") || name.includes("deviationDate")
        ? "480px"
        : "312px"};
  }
`;

const StyledInput = styled.input<ErrorProps & FormControlProps>`
  text-align: center;
  outline: none;
  border: none;
  padding: 24px 8px 4px;
  height: 56px;
  width: ${({ name }) =>
    name.includes("volume")
      ? "144px"
      : name.includes("bestBeforeDate")
      ? "216px"
      : name.includes("deviationDuration") || name.includes("deviationDate")
      ? "304px"
      : "200px"};
  border-bottom: ${({ error }) =>
    error ? "2px solid red" : "1.5px solid var(--color-dark-green)"};

  &:focus {
    border-color: var(--color-light-green);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    width: ${({ name }) =>
      name.includes("volume")
        ? "216px"
        : name.includes("bestBeforeDate")
        ? "320px"
        : name.includes("deviationDuration") || name.includes("deviationDate")
        ? "456px"
        : "296px"};
  }
`;

const SubInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;

  &::before {
    content: "/";
    position: absolute;
    color: black;
    top: 26px;
    left: 52px;
    font-size: 24px;

    @media (min-width: 1400px) and (min-height: 800px) {
      left: 80px;
    }
  }

  &::after {
    content: "/";
    position: absolute;
    color: black;
    top: 26px;
    left: 124px;
    font-size: 24px;

    @media (min-width: 1400px) and (min-height: 800px) {
      left: 187px;
    }
  }
`;

const SubInput = styled(StyledInput)`
  width: ${({ placeholder }) => (placeholder === "Övre" ? "48px" : "64px")};
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid var(--color-dark-green);
  padding: 24px 0 4px 4px;
  font-size: 14px;

  &:focus {
    border-color: var(--color-light-green);
  }

  @media (min-width: 1400px) and (min-height: 800px) {
    width: ${({ placeholder }) => (placeholder === "Övre" ? "72px" : "96px")};
  }
`;

const CommonMeasurementCard = styled(MeasurementCard)`
  height: 80px;
  grid-template-columns: 2.5fr 2fr 2fr;
  padding: 0px 16px;
  border: none;
  box-shadow: none;
  background-color: var(--color-light-green);
`;

const PalletCheckbox = styled.div`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  justify-content: end;

  label {
    cursor: pointer;
    margin-right: 14px;
    font-size: 14px;
  }

  input {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`;

const TemperatureIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  fill: var(--color-blue);
`;

const CategoryIcon = styled(Icon)`
  width: 48px;
  height: 48px;
  fill: var(--color-dark-green);

  transition: all 0.3s;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 -2px 8px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  padding: 0 6vw;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 11.3vh;
  z-index: 100;
`;

const CommonActionMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  width: 124px;
  height: 32px;
`;
